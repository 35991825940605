import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AddCouponRes;
export const AddCouponTodo = createAsyncThunk('AddCoupon', async (data) => {
    console.log("data", data)
    try {
        const res = await axios({
            method: "Post",
            url: process.env.REACT_APP_API_URL + 'coupon_discount',
            data: {
                "coupon_name":data?.coupon_name,
                "coupon_code":data?.code,
                "types_of_discount": data?.type,
                "start_date": data?.start_date,
                "end_date": data?.end_date,
                "num_of_users":data?.no_of_users,
                "discount_value": data?.discount_val,
                "user_id": localStorage.getItem('user_id')
            },
            headers: { "Content-Type": "application/json" , "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return AddCouponRes = res.data
        
    } catch (error) {
        return AddCouponRes = error.response.data
    }
})
