import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var CustomerListRes;
export const CustomerListTodo = createAsyncThunk('CustomerList', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Get",
            url: process.env.REACT_APP_API_URL + 'customer_details',
            params: {
                "customer_status": "",
                "user_name": data ? data : ''
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return CustomerListRes = res.data

    } catch (error) {
        return CustomerListRes = error.response.data
    }
})

const CustomerListSlice = createSlice({
    name: 'CustomerList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(CustomerListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(CustomerListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default CustomerListSlice.reducer; 