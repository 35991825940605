import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AddVehicleTypeRes;
export const AddVehicleTypeTodo = createAsyncThunk('AddVehicleType', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Post",
            url: process.env.REACT_APP_API_URL + 'vehicle_type',
            data: {
                "vehicle_type_name": data.type,
                "vehicle_type_status": data.status,
                "user_id": localStorage.getItem('user_id')
            },
            headers: { "Content-Type": "application/json" , "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return AddVehicleTypeRes = res.data
        
    } catch (error) {
        return AddVehicleTypeRes = error.response.data
    }
})
