import React, { useEffect, useState } from 'react'
import DataTable from '../../../component/data-table/DataTable'
import { ToastContainer } from 'react-toastify'
import ModelListAction from '../../../component/data-table/data-table-action/vehicle-managment/ModelListAction'
import { ModelListRes, ModelListTodo } from '../../../redux/slices/master_slice/vehical modal/ModalList'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../component/loader/Loader'

export default function ModelList(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const modalList = useSelector(state => state?.ModalList?.data?.data)

    const columnDefs = [
        {
            headerName: 'S.NO',
            // valueGetter: 'node.rowIndex + 1',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'MODEL',
            field: 'vehicle_model_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'BRAND',
            field: 'VehicleBrandSchema.vehicle_brand_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },

        {
            headerName: 'STATUS',
            field: 'vehicle_model_status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.vehicle_model_status === 'active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: ModelListAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]

    useEffect(() => {
        setLoading(true)
        dispatch(ModelListTodo()).then(() => list_res())
    }, [bool])

    const list_res = () => {
        if (ModelListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    return (
        <>

            {loading ?
                <diV className='dd-flex justify-content-center mt-5 pt-5'>
                    <Loader />
                </diV> :
                modalList && modalList.length ?
                    <DataTable
                        rowData={modalList}
                        columnDefs={columnDefs}
                        height={'60vh'}
                    />
                    :
                    <div className='empty_section'>
                        <span>DATA NOT FOUND</span>
                    </div>
            }

            <ToastContainer />
        </>
    )
}
