import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Loader from '../../../loader/Loader'
import Text from '../../../input_filed/Text'
import SelectStatus from '../../../input_filed/SelectStatus'
import SaveButton from '../../../buttons/SaveButton'
import { ToastContainer, toast } from 'react-toastify'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { VehicleCCSchema } from './Validation'
import { UpdateVehicleCCRes, UpdateVehicleCCTodo } from '../../../../redux/slices/master_slice/vehicle cc/UpdateVehicleCC'

export default function UpdateCCForm() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation()
    const id = state?.id

    const initialValues = {
        cc:state?.vehicle_cc_name ,
        status: state?.vehicle_cc_status
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: VehicleCCSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(UpdateVehicleCCTodo({ values,id })).then(() => update_res(action))
        }
    })

    const update_res = () => {
        if (UpdateVehicleCCRes?.success) {
            toast.success(UpdateVehicleCCRes?.message, { position: "bottom-right" });
            setLoading(false)
            setTimeout(() => {
                navigate('/cc')
            }, 1500);
        } else {
            setLoading(false)
            toast.error(UpdateVehicleCCRes?.message, { position: "bottom-right" });
        }
    }

    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4'>
                                <Text
                                    label_name='CC'
                                    placeholder=''
                                    name='cc'
                                    value={values.cc || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.cc && touched.cc ? (<span className='text-danger form_label' >{errors.cc}</span>) : null}
                                />
                            </div>

                            <div className='col-md-4'>
                                <SelectStatus
                                    closeMenu={true}
                                    label_name='Status'
                                    disabled={false}
                                    name='status'
                                    defaultValue={values.status}
                                    onChange={(e) =>
                                        setFieldValue('status', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                />
                            </div>
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Update'} />
                            </div>
                        </div>}
                </div>
            </Form>
            <ToastContainer/>
        </>
    )
}
