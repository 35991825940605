import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EnquiryListRes, EnquiryListTodo } from '../../redux/slices/vendor-enquiry/EnquiryList'
import Loader from '../../component/loader/Loader'
import DataTable from '../../component/data-table/DataTable'

export default function VendorEnquiryList() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const EnquiryList = useSelector(state => state?.VendorEnquiryList?.data?.data)

    useEffect(() => {
        setLoading(true)
        dispatch(EnquiryListTodo()).then(() => list_res())
    }, [])

    const list_res = () => {
        if (EnquiryListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    const columnDefs = [
        {
            headerName: 'S.NO',
            // valueGetter: 'node.rowIndex + 1',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'CONTACT',
            field: 'mobile',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },


        {
            headerName: 'ADDED DATE',
            field: 'added_date',
            filter: 'agTextColumnFilter',
            cellRenderer: params => new Date(params?.data?.added_date).toLocaleDateString('en-GB'),
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
       
        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        // {
        //     field: 'ACTIONS',
        //     cellRenderer: EnquiryListAction,
        //     cellRendererParams: {
        //         bool: bool,
        //         setBool: setBool,
        //         // setLoading: setLoading,
        //     },
        //     // wrapText: true,
        //     autoHeight: true,
        //     pinned: 'right',
        // }
    ]
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head mt-3'>
                            <h5 > Vendor Enquiry List</h5>
                        </div>
                        {loading ?
                            <diV className='dd-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </diV> :
                            EnquiryList && EnquiryList.length ?
                                <DataTable
                                    rowData={EnquiryList}
                                    columnDefs={columnDefs}
                                    height={'72vh'}
                                />
                                :
                                <div className='empty_section mt-3'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}
