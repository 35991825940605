import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AddCityServiceRes;
export const AddCityServiceTodo = createAsyncThunk('AddCityService', async (data) => {
    try {
        const res = await axios({
            method: "Post",
            url: process.env.REACT_APP_API_URL + 'city_service',
            data: {
                "city_id": data.city,
                "status": data.status,
                // "user_id": localStorage.getItem('user_id')
            },
            headers: { "Content-Type": "application/json" , "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return AddCityServiceRes = res.data
        
    } catch (error) {
        return AddCityServiceRes = error.response.data
    }
})
