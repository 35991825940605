import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var DeleteCityServicRes;
export const DeleteCityServicTodo = createAsyncThunk('DeleteCityServic', async (id) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Delete",
            url: process.env.REACT_APP_API_URL + `city_service/${id}`,
            headers: { "Content-Type": "application/json" , "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return DeleteCityServicRes = res.data
        
    } catch (error) {
        return DeleteCityServicRes = error.response.data
    }
})
