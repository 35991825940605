import './App.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import './assests/styles/layout/Navbar.css'
import './assests/styles/layout/Sidebar.css'
import './assests/styles/login-page/LoginPage.css'
import './assests/styles/component/Button.css'
import './assests/styles/component/DataTable.css'
import './assests/styles/component/Form.css'
import './assests/styles/component/InputFiled.css'
import './assests/styles/Pages/PageLayout.css'
import './assests/styles/Pages/dashboard.css'
import './assests/styles/Pages/DetailsPage.css'
import './assests/styles/Pages/privacy_terms.css'

import Dashboard from './pages/dashboard/Dashboard';
import AdminLogin from './pages/admin-login/AdminLogin';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoute';
import AddCategory from './pages/vehicle-managment/category/AddCategory';
import UpdateCategory from './pages/vehicle-managment/category/UpdateCategory';
import AddType from './pages/vehicle-managment/type/AddType';
import UpdateType from './pages/vehicle-managment/type/UpdateType';
import AddCC from './pages/vehicle-managment/cc/AddCC';
import UpdateCC from './pages/vehicle-managment/cc/UpdateCC';
import AddBrand from './pages/vehicle-managment/brand/AddBrand';
import UpdateBrand from './pages/vehicle-managment/brand/UpdateBrand';
import AddModel from './pages/vehicle-managment/model/AddModel';
import UpdateModel from './pages/vehicle-managment/model/UpdateModel';
import VehicleList from './pages/vehicle-managment/vehicle/VehicleList';
import AddVehicle from './pages/vehicle-managment/vehicle/AddVehicle';
import VendersList from './pages/user-management/venders/VendersList';
import UpdateVehicle from './pages/vehicle-managment/vehicle/UpdateVehicle';
import AddVendor from './pages/user-management/venders/AddVendor';
import UpdateVendor from './pages/user-management/venders/UpdateVender';
import StaffList from './pages/user-management/staff/StaffList';
import CustomerList from './pages/user-management/customers/CustomerList';
import VendorDetails from './pages/user-management/venders/VenderDetails';
import VendorVehicleDetails from './pages/user-management/venders/VendorVehicleDetails';
import AddBanner from './pages/banner/AddBanner';
import UpdateBanner from './pages/banner/UpdateBanner';
import CustomerDetails from './pages/user-management/customers/CustomerDetails';
import AddSecurityDeposit from './pages/security-deposit/AddSecurityDeposit';
import AddCoupen from './pages/coupen/AddCoupen';
import UpdateCoupen from './pages/coupen/EditCoupen';
import BookingList from './pages/booking/BookingList';
import BookingDetails from './pages/booking/BookingDetails';
import VendorHelpRequest from './pages/help-desk/VendorHelpRequest';
import CustomerHelpReq from './pages/help-desk/CustomerHelpReq';
import AboutUs from './pages/crm/about-us/AboutUs';
import PrivacyPolicy from './pages/crm/privacy-policy/PrivacyPolicy';
import PrivacyPolicyDetails from './pages/crm/privacy-policy/PrivacyPolicyDetails';
import TermAndCondition from './pages/crm/term-and-condition/TermAndCondition';
import HomePage from './pages/home-page/HomePage';
import TermConditionDetail from './pages/crm/term-and-condition/TermConditionDetail';
import CityList from './pages/vehicle-managment/city/CityList';
import ContactEnquiryList from './pages/contact-enquiry/ContactEnquiryList';
import VendorEnquiryList from './pages/vendor-enquiry/VendorEnquiryList';
import AddCityService from './pages/city-cervices/AddCityService';
import PrivacyPolicyPublic from './pages/Policy&Term/PrivacyPolicyPublic';
import AboutsUsPublic from './pages/Policy&Term/AboutsUsPublic';
import TermConditionPublic from './pages/Policy&Term/TermConditionPublic';
import HelpAndSupport from './pages/Policy&Term/HelpAndSupport';

function App() {
  return (
    <div className="App">
      {/* <Dashboard/> */}
      {/* <AdminLogin /> */}
      <Routes>
      <Route path='/' element={<HomePage />} />
        <Route path='/admin-login' element={<AdminLogin />} />
        <Route path='/about-us' element={<AboutsUsPublic />} />
        <Route path='/help-and-support' element={<HelpAndSupport />} />
        <Route path='/privacy-policy' element={<PrivacyPolicyPublic />} />
        <Route path='/terms-and-conditions' element={<TermConditionPublic />} />
        <Route element={<ProtectedRoutes />}>
        {/* <Route path='/' element={<Navigate replace to='/dashboard' />} /> */}
          <Route path='/dashboard' element={<Dashboard />} />

          <Route path='/city-list' element={<CityList />} />
          <Route path='/city-services' element={<AddCityService />} />

          <Route path='/category' element={<AddCategory />} />
          <Route path='/update-category' element={<UpdateCategory />} />

          <Route path='/vehicle-type' element={<AddType />} />
          <Route path='/update-vehicle-type' element={<UpdateType />} />

          <Route path='/cc' element={<AddCC />} />
          <Route path='/update-cc' element={<UpdateCC />} />

          <Route path='/brand' element={<AddBrand />} />
          <Route path='/update-brand' element={<UpdateBrand />} />

          <Route path='/model' element={<AddModel />} />
          <Route path='/update-model' element={<UpdateModel />} />

          <Route path='/banner' element={<AddBanner />} />
          <Route path='/update-banner' element={<UpdateBanner />} />

          <Route path='/add-vehicle' element={<AddVehicle />} />
          <Route path='/update-vehicle' element={<UpdateVehicle />} />
          <Route path='/vehicle-list' element={<VehicleList />} />

          <Route path='/add-vendor' element={<AddVendor />} />
          <Route path='/update-vendor' element={<UpdateVendor />} />
          <Route path='/vendor-list' element={<VendersList />} />
          <Route path='/vendor-details' element={<VendorDetails />} />
          <Route path='/vendor-vehicle-details' element={<VendorVehicleDetails />} />

          <Route path='/staff-list' element={<StaffList />} />

          <Route path='/customer-list' element={<CustomerList />} />
          <Route path='/customer-details' element={<CustomerDetails />} />

          <Route path='/security-deposit' element={<AddSecurityDeposit />} />

          <Route path='/coupon' element={<AddCoupen />} />
          <Route path='/update-coupon' element={<UpdateCoupen />} />

          <Route path='/booking-list' element={<BookingList />} />
          <Route path='/booking-details' element={<BookingDetails />} />

          <Route path='/vendor-help-request' element={<VendorHelpRequest />} />
          <Route path='/customer-help-request' element={<CustomerHelpReq />} />

          <Route path='/add-about-us' element={<AboutUs />} />

          <Route path='/add-privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/privacy-policy-details' element={<PrivacyPolicyDetails />} />

          <Route path='/add-term-and-condition' element={<TermAndCondition />} />
          <Route path='/term-and-condition-details' element={<TermConditionDetail />} />

          <Route path='/contact-enquiry-list' element={<ContactEnquiryList />} />
          <Route path='/vendor-enquiry-list' element={<VendorEnquiryList />} />

          
        </Route>
      </Routes>
    </div>
  );
}

export default App;
