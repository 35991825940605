import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var BookingDetailsRes;
export const BookingDetailsTodo = createAsyncThunk('BookingDetails', async (data) => {
    console.log("data", data)
    try {
        const res = await axios({
            method: "Get",
            url: process.env.REACT_APP_API_URL + `admin_booking_list/${data}`,
            params: {
                "user_id": localStorage.getItem('user_id'),
                "fk_customer_details_id":  "",
                "fk_vendor_details_id":  "",
                "from_date": "",
                "to_date":  "",
                "booking_status": "",
                "search": "",
                "recent_booking": "",
                "today_booking": "",
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return BookingDetailsRes = res.data

    } catch (error) {
        return BookingDetailsRes = error.response.data
    }
})

const BookingDetailsSlice = createSlice({
    name: 'BookingDetails',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(BookingDetailsTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(BookingDetailsTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default BookingDetailsSlice.reducer; 