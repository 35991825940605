import React from 'react'
import { Form } from 'react-bootstrap'

export default function TextArea(props) {

    const { label_name, placeholder, disabled, rows, name, value, onChange, onBlur, error } = props

    return (
        <>
            <Form.Group className="mb-3" controlId="textarea">
                <Form.Label className='form_label'>{label_name}</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={rows}
                    placeholder={placeholder}
                    disabled={disabled}
                    className='form_input'
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                {error}
            </Form.Group>
        </>
    )
}
