import React, { useEffect, useState } from 'react'
import { BsShopWindow } from 'react-icons/bs'
import { FaUserLarge } from 'react-icons/fa6'
import { MdOutlineEmojiTransportation } from 'react-icons/md'
import { RiHealthBookFill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../../component/loader/Loader'
import { DashboardRes, DashboardTodo } from '../../redux/slices/dashboard/Dashboard'
import { Table } from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import BookingGraph from './BookingGraph'
import { BookingGraphTodo } from '../../redux/slices/dashboard/BookingGraph'
import { OrderGraphTodo } from '../../redux/slices/dashboard/OrderGraph'
import OrderGraph from './OrderGraph'

export default function Dashboard() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const dashboardData = useSelector(state => state?.Dashboard?.data?.data)

    useEffect(() => {
        setLoading(true)
        dispatch(DashboardTodo()).then(() => dashboardFun())
    }, [])

    const dashboardFun = () => {
        if (DashboardRes?.success) {
            dispatch(BookingGraphTodo()).then((res) => {
                if (res?.payload?.success) {
                    dispatch(OrderGraphTodo()).then((res2) => {
                        if (res?.payload?.success) {
                            setLoading(false)
                        } else {
                            setLoading(false)
                        }
                    })
                } else {
                    setLoading(false)
                }
            })

        } else {
            setLoading(false)
        }
    }
    return (
        <>
            <div className="container-fluid">
                <div className='layout_head'>
                    <h5>Dashboard</h5>
                </div>

                {loading ?
                    <div className='d-flex justify-content-center mt-5 pt-5'>
                        <Loader />
                    </div> :
                    <div className="row">
                        <div className="col-md-3 mt-3">
                            <div className="card dashboard_card text-center  h-100" >
                                <div className='d-flex justify-content-around align-items-center '>
                                    <div className=''>
                                        <span className='dashboard_icon'>
                                            < FaUserLarge size={35} style={{ color: "#c7403d" }} />
                                        </span>
                                    </div>
                                    <div className=''>
                                        <h4>USERS</h4>
                                        <h3 className='text-center'>0</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <Link to='/vehicle-list'>  <div className="card dashboard_card text-center  h-100" >
                                <div className='d-flex justify-content-around align-items-center '>
                                    <div className=''>
                                        <span className='dashboard_icon'>
                                            < MdOutlineEmojiTransportation size={35} style={{ color: "#c7403d" }} />
                                        </span>
                                    </div>
                                    <div className=''>
                                        <h4>VEHICLES</h4>
                                        <h3 className='text-center'>{dashboardData?.vehicleCount}</h3>
                                    </div>
                                </div>
                            </div></Link>
                        </div>
                        <div className="col-md-3 mt-3">
                            <Link to='/vendor-list'>   <div className="card dashboard_card text-center  h-100" >
                                <div className='d-flex justify-content-around align-items-center '>
                                    <div className=''>
                                        <span className='dashboard_icon'>
                                            < BsShopWindow size={35} style={{ color: "#c7403d" }} />
                                        </span>
                                    </div>
                                    <div className=''>
                                        <h4>VENDORS</h4>
                                        <h3 className='text-center'>{dashboardData?.vendorCount}</h3>
                                    </div>
                                </div>
                            </div></Link>
                        </div>
                        <div className="col-md-3 mt-3">
                            <Link to='/booking-list'>  <div className="card dashboard_card text-center  h-100" >
                                <div className='d-flex justify-content-around align-items-center '>
                                    <div className=''>
                                        <span className='dashboard_icon'>
                                            < RiHealthBookFill size={35} style={{ color: "#c7403d" }} />
                                        </span>
                                    </div>
                                    <div className=''>
                                        <h4>BOOKINGS</h4>
                                        <h3 className='text-center'>{dashboardData?.total_booking}</h3>
                                    </div>
                                </div>
                            </div></Link>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card dashboard_card text-center  h-100" >
                                <div className='d-flex justify-content-around align-items-center '>
                                    <div className=''>
                                        <span className='dashboard_icon'>
                                            < RiHealthBookFill size={35} style={{ color: "#c7403d" }} />
                                        </span>
                                    </div>
                                    <div className=''>
                                        <h4>TODAY'S <br /> BOOKINGS</h4>
                                        <h3 className='text-center'>{dashboardData?.today_booking}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card dashboard_card text-center  h-100" >
                                <div className='d-flex justify-content-around align-items-center '>
                                    <div className=''>
                                        <span className='dashboard_icon'>
                                            < RiHealthBookFill size={35} style={{ color: "#c7403d" }} />
                                        </span>
                                    </div>
                                    <div className=''>
                                        <h4>PENDING <br /> BOOKINGS</h4>
                                        <h3 className='text-center'>{dashboardData?.pending_booking}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card dashboard_card text-center  h-100" >
                                <div className='d-flex justify-content-around align-items-center '>
                                    <div className=''>
                                        <span className='dashboard_icon'>
                                            < RiHealthBookFill size={35} style={{ color: "#c7403d" }} />
                                        </span>
                                    </div>
                                    <div className=''>
                                        <h4>COMPLETED <br /> BOOKINGS</h4>
                                        <h3 className='text-center'>0</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <BookingGraph />
                        </div>
                        <div className="col-md-6 mt-3">
                            <OrderGraph />
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className='layout_head'>
                                <h5>New Sign Up</h5>
                            </div>
                            <div className='boot_table mt-2'>
                                <Table bordered responsive size='sm' hover className=' ' >
                                    <thead   >
                                        <tr >
                                            <th >S.NO</th>
                                            <th> Name</th>
                                            <th> Contact No.</th>
                                            <th> City</th>
                                        </tr>
                                    </thead>
                                    {dashboardData?.new_sign_up?.length ?

                                        <tbody className=''>
                                            {dashboardData?.new_sign_up?.map((value, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{value?.vendor_name}</td>
                                                        <td>{value?.vendor_mobile_num}</td>
                                                        <td>{value?.CitySchema?.city_name}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody> :
                                        <tbody>
                                            <tr>
                                                <td className='text-center p-5' style={{ fontSize: "18px", color: '#9d9d9d', fontWeight: "500" }} colSpan={4}>New Sign Up List Empty</td>
                                            </tr>
                                        </tbody>
                                    }
                                </Table>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className='layout_head'>
                                <h5>Pending Approval</h5>
                            </div>
                            <div className='boot_table mt-2'>
                                <Table bordered responsive size='sm' hover className=' ' >
                                    <thead >
                                        <tr >
                                            <th >S.NO</th>
                                            <th> Name</th>
                                            <th> Contact No.</th>
                                            <th> City</th>
                                        </tr>
                                    </thead>
                                    {dashboardData?.pending_approval?.length ?
                                        <tbody >
                                            {dashboardData?.pending_approval?.map((value, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{value?.vendor_name}</td>
                                                        <td>{value?.vendor_mobile_num}</td>
                                                        <td>{value?.CitySchema?.city_name}</td>

                                                    </tr>
                                                )
                                            })}
                                        </tbody> :
                                        <tbody>
                                            <tr>
                                                <td className='text-center p-5' style={{ fontSize: "18px", color: '#9d9d9d', fontWeight: "500" }} colSpan={4}>Approval List Empty</td>
                                            </tr>
                                        </tbody>
                                    }



                                </Table>
                            </div>
                        </div>

                    </div>}
            </div>

        </>
    )
}
