import React from 'react'
import UpdateCouponForm from '../../component/form/coupen/UpdateCoupon'

export default function UpdateCoupen() {
  return (
    <>
        <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Update Coupon</h5>
                            <UpdateCouponForm />
                        </div>
                    
                    </div>
                </div>
            </div>
    </>
  )
}
