import React from 'react'
import UpdateBrandForm from '../../../component/form/vehicle-managment/brand/UpdateBrandForm'

export default function UpdateBrand() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Update Brand</h5>
                            <UpdateBrandForm />
                        </div>
                     
                    </div>
                </div>
            </div>
        </>
    )
}
