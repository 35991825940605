import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var UpdateCouponRes;
export const UpdateCouponTodo = createAsyncThunk('UpdateCoupon', async (data) => {
    console.log("data", data)
    try {
        const res = await axios({
            method: "PUT",
            url: process.env.REACT_APP_API_URL + `coupon_discount/${data.id}`,
            data: {
                "coupon_name":data?.coupon_name,
                "types_of_discount": data?.type,
                "start_date": data?.start_date,
                "end_date": data?.end_date,
                "num_of_users":data?.no_of_users,
                "discount_value": data?.discount_val,
                "user_id": localStorage.getItem('user_id')
            },
            headers: { "Content-Type": "application/json" , "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return UpdateCouponRes = res.data
        
    } catch (error) {
        return UpdateCouponRes = error.response.data
    }
})
