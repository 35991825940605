import React, { useState, useRef, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import Loader from '../../../loader/Loader'
import Text from '../../../input_filed/Text'
import SelectStatus from '../../../input_filed/SelectStatus'
import SaveButton from '../../../buttons/SaveButton'
import { ToastContainer, toast } from 'react-toastify'
import { useFormik } from 'formik'
import SingleSelect from '../../../input_filed/SingleSelect'
import File from '../../../input_filed/File'
import Swal from 'sweetalert2'
import Email from '../../../input_filed/Email'
import { GoogleMap, useJsApiLoader, StandaloneSearchBox, Marker } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { VenderDetailsRes, VenderDetailsTodo } from '../../../../redux/slices/user managment/venders/VendersDetails'
import { UpdateVendersRes, UpdateVendersTodo } from '../../../../redux/slices/user managment/venders/UpdateVenders'
import MultiFiles from '../../../input_filed/MultiFiles'
import { UpdateVenderSchema } from './Validation'
import vendorImage from '../../../../assests/images/profile.png'
import { RxCrossCircled } from 'react-icons/rx'
import { ShopImgDeleteRes, ShopImgDeleteTodo } from '../../../../redux/slices/user managment/venders/DeleteShopImg'
import { CityListRes, CityListTodo } from '../../../../redux/slices/user managment/venders/CityList'
import { debounce } from 'lodash'
import { Tooltip } from 'react-tooltip'

const containerStyle = {
    width: '100%',
    height: '400px'
};
export default function UpdateVendorForm() {
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const [cityLoading, setCityLoading] = useState(false)
    const vendorData = useSelector(state => state?.VendorDetails?.data?.data?.[0])
    const cityList = useSelector(state => state?.CityList?.data?.data)
    const { state } = useLocation()
    const navigate = useNavigate()
    const [bool, setBool] = useState(false)
    // const id = state.id
    // const vendorData = state

    const city_option = cityList && cityList.map(val => (
        { "value": val.id, "label": val.city_name }
    ))
    const COD_option = [
        { "value": '1', "label": "Yes" },
        { "value": '0', "label": "No" }
    ]
    const delivery_option = [
        { "value": 'pickup', "label": "Pickup" },
        { "value": 'delivery', "label": "Delivery" },
        { "value": 'both', "label": "Both" },
        { "value": '', "label": "NA" }
    ]

    const [map, setMap] = useState(null)

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
        libraries: ['places']
    })

    const onLoad = (map) => {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);

        map.setCenter(markerPosition);
        map.setZoom(14);
        setMap(map)
    }

    const onUnmount = (map) => {
        setMap(null)
    }

    const inputRef = useRef()
    const [markerPosition, setMarkerPosition] = useState(
        {
            lat: 22.720,
            lng: 75.859
        });
    // console.log("markerPosition", markerPosition)

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        setMarkerPosition({ lat: place && place.geometry && place.geometry.location && place.geometry.location.lat(), lng: place && place.geometry && place.geometry.location && place.geometry.location.lng() });
        map.setCenter({ lat: place && place.geometry && place.geometry.location && place.geometry.location.lat(), lng: place && place.geometry && place.geometry.location && place.geometry.location.lng() });
        map.setZoom(14);
        setMap(map)
        setFieldValue('latitude', place && place.geometry && place.geometry.location && place.geometry.location.lat())
        setFieldValue('longitude', place && place.geometry && place.geometry.location && place.geometry.location.lng())
    }

    const handleMarkerDragged = newPosition => {
        // console.log("EEEE", newPosition)
        // console.log("EEEE", newPosition.latLng?.lat())
        // console.log("EEEE", newPosition.latLng?.lng())
        setMarkerPosition(({ lat: newPosition.latLng?.lat(), lng: newPosition.latLng?.lng() }));
        setFieldValue('latitude', newPosition.latLng?.lat())
        setFieldValue('longitude', newPosition.latLng?.lng())
    };


    const initialValues = {
        id: vendorData?.fk_user_id,
        vendor_name: vendorData?.vendor_name,
        mobile_no: vendorData?.vendor_mobile_num,
        email: vendorData?.vendor_email,
        shop_name: vendorData?.shop_name=== 'null' ? '':vendorData?.shop_name,
        city: vendorData?.city_id,
        address: vendorData?.shop_address,
        aadhar_nu: vendorData?.adhaar_card_num,
        aadhar_img: vendorData?.adhaar_card,
        delivery_type: vendorData?.delivery_type,
        cod: vendorData?.cash_on_delivery,
        pan_nu: vendorData?.pan_card_num,
        pan_img: vendorData?.pan_card,
        shop_imgs: vendorData?.ShopImageSchemas,
        shipping_charges:  vendorData?.shipping_charges,
        prof_img:vendorData?.profile_image,
        latitude: vendorData?.latitude,
        longitude: vendorData?.longitude,
        status: vendorData?.status,
        account_name:vendorData?.account_name === 'null' ? '':vendorData?.account_name,
        account_nu:vendorData?.account_num=== 'null' ? '':vendorData?.account_num,
        bank_name:vendorData?.bank_name=== 'null' ? '':vendorData?.bank_name,
        ifsc_code:vendorData?.ifsc_code=== 'null' ? '':vendorData?.ifsc_code
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: UpdateVenderSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(UpdateVendersTodo(bodyFormData)).then(() => update_res(action))
        }
    })

    const update_res = () => {
        if (UpdateVendersRes?.success) {
            setLoading(false)
            toast.success(UpdateVendersRes?.message, { position: "bottom-right" });
            setTimeout(() => {
                navigate('/vendor-list')
            }, 1000);
        } else {
            setLoading(false)
            toast.error(UpdateVendersRes?.message, { position: "bottom-right" });
        }
    }

    let bodyFormData = new FormData();
    bodyFormData.append("vendor_name", values.vendor_name)
    bodyFormData.append("vendor_email", values.email)
    bodyFormData.append("vendor_mobile_num", values.mobile_no)
    bodyFormData.append("shop_name", values.shop_name)
    bodyFormData.append("city", '2')
    bodyFormData.append("shop_address", values.address)
    bodyFormData.append("adhaar_card_img", values.aadhar_img)
    bodyFormData.append("pan_card_img", values.pan_img)
    bodyFormData.append("profile_image", values.prof_img)

    for (var i = 0; i < values?.shop_imgs?.length; i++) {
        var file = values?.shop_imgs[i]?.file;
        bodyFormData.append('shop_image', file);
    }
    bodyFormData.append("latitude", values.latitude)
    bodyFormData.append("longitude", values.longitude)
    // bodyFormData.append("status", values.status)
    bodyFormData.append("user_id", localStorage.getItem('user_id'))
    bodyFormData.append("fk_user_id", values.id)
    bodyFormData.append("adhaar_card_num", values.aadhar_nu)
    bodyFormData.append("pan_card_num", values.pan_nu)
    bodyFormData.append("delivery_type", values.delivery_type)
    bodyFormData.append("shipping_charges", values.shipping_charges)
    bodyFormData.append("cash_on_delivery", values.cod)
    bodyFormData.append("account_name", values.account_name)
    bodyFormData.append("account_num", values.account_nu)
    bodyFormData.append("bank_name", values.bank_name)
    bodyFormData.append("ifsc_code", values.ifsc_code)



    useEffect(() => {
        setLoading(true)
        dispatch(VenderDetailsTodo(state)).then((res) => details_res(res))
    }, [bool])

    const details_res = (res) => {
        if (res?.payload?.success) {
            dispatch(CityListTodo(res?.payload?.data[0]?.CitySchema?.city_name)).then((city_res) => {
                if (city_res?.payload?.success) {
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
        }
    }

    const load_city = debounce((e) => {
        if (e) {
            setCityLoading(true)
            dispatch(CityListTodo(e)).then(() => city_res())
        }
    }, 500)
    const city_res = () => {
        if (CityListRes?.success) {
            setCityLoading(false)
        } else {
            setCityLoading(false)
        }
    }

    const handleImgDelete = (data) => {
        Swal.fire({
            title: "Shop Image Delete",
            text: "Are You Sure Want To Delete This Image ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(ShopImgDeleteTodo(data.id)).then(() => del_res())
            }
        })

    }
    const del_res = () => {
        if (ShopImgDeleteRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Image",
                'Image Deleted Successfully',
                'success'
            )
        } else {
            toast.error(ShopImgDeleteRes?.message, { position: "bottom-right" })
        }
    }


    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4 mb-1'>
                                <Text
                                    label_name='Vendor Name'
                                    placeholder=''
                                    name='vendor_name'
                                    value={values.vendor_name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.vendor_name && touched.vendor_name ? (<span className='text-danger form_label' >{errors.vendor_name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4 mb-1'>
                                <Text
                                    label_name='Mobile No.'
                                    placeholder=''
                                    maxLength={10}
                                    name='mobile_no'
                                    value={values.mobile_no || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.mobile_no && touched.mobile_no ? (<span className='text-danger form_label' >{errors.mobile_no}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4 mb-1'>
                                <Email
                                    label_name='Email'
                                    placeholder=''
                                    name='email'
                                    value={values.email || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.email && touched.email ? (<span className='text-danger form_label' >{errors.email}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4 mb-1'>
                                <Text
                                    label_name='Shop Name'
                                    placeholder=''
                                    name='shop_name'
                                    value={values.shop_name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    // error={errors.shop_name && touched.shop_name ? (<span className='text-danger form_label' >{errors.shop_name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4 mb-1'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='City'
                                    disabled={false}
                                    name='city'
                                    option={city_option ? city_option : []}
                                    defaultValue={city_option && city_option.find((option) => option.value === values.city)}
                                    onChange={(e) =>
                                        setFieldValue('city', e.value)
                                    }
                                    onInputChange={(e) => load_city(e)}
                                    onBlur={handleBlur}
                                    loading={cityLoading}
                                // error={errors.city && touched.city ? (<span className='text-danger form_label' >{errors.city}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4 mb-1'>
                                <Text
                                    label_name='Shop/User Address'
                                    placeholder=''
                                    name='address'
                                    value={values.address || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.address && touched.address ? (<span className='text-danger form_label' >{errors.address}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4 mb-1'>
                                <Text
                                    label_name='Aadhar Number'
                                    placeholder=''
                                    maxLength={12}
                                    name='aadhar_nu'
                                    value={values.aadhar_nu || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.aadhar_nu && touched.aadhar_nu ? (<span className='text-danger form_label' >{errors.aadhar_nu}</span>) : null}
                                />
                            </div>

                            <div className='col-md-4 mb-1'>
                                <Text
                                    label_name='Pan Card Number'
                                    placeholder=''
                                    maxLength={10}
                                    name='pan_nu'
                                    value={values.pan_nu || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.pan_nu && touched.pan_nu ? (<span className='text-danger form_label' >{errors.pan_nu}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4 mb-1'>
                                <MultiFiles
                                    name='shop_imgs'
                                    label_name={'Shop Images '}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        const filesArray = Array.from(e.currentTarget.files).map((file) => ({
                                            file: file,
                                            // You can add additional properties if needed, like file name, size, type, etc.
                                        }));
                                        setFieldValue('shop_imgs', filesArray)
                                        // setFieldValue("shop_imgs", e.currentTarget.files)

                                    }}
                                    error={errors.shop_imgs && touched.shop_imgs ? (<span className='text-danger form_label' >{errors.shop_imgs}</span>) : null}
                                />

                            </div>
                            <div className='col-md-4 mb-1 '>
                                <File
                                    name='aadhar_img'
                                    label_name={'Addhar Card Image '}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue("aadhar_img", e.currentTarget.files[0])}
                                // error={errors.aadhar_img && touched.aadhar_img ? (<span className='text-danger form_label' >{errors.aadhar_img}</span>) : null}
                                />

                            </div>
                            <div className='col-md-4 mb-1 '>
                                <File
                                    name='pan_img'
                                    label_name={'Pan Card Image '}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue("pan_img", e.currentTarget.files[0])}
                                // error={errors.pan_img && touched.pan_img ? (<span className='text-danger form_label' >{errors.pan_img}</span>) : null}
                                />

                            </div>
                            <div className='col-md-4 mb-1 '>
                                <File
                                    name='prof_img'
                                    label_name={'Profile Image '}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue("prof_img", e.currentTarget.files[0])}
                                    error={errors.prof_img && touched.prof_img ? (<span className='text-danger form_label' >{errors.prof_img}</span>) : null}
                                />

                            </div>
                           
                            <h5 className='text-center' style={{color:'grey'}}>BANK DETAILS</h5>
                            <div className='col-md-3 mb-1 mt-2'>
                                <Text
                                    label_name='Account Name'
                                    placeholder=''
                                    name='account_name'
                                    value={values.account_name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    // error={errors.account_name && touched.account_name ? (<span className='text-danger form_label' >{errors.account_name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3 mb-1 mt-2'>
                                <Text
                                    label_name='Account Number'
                                    placeholder=''
                                    name='account_nu'
                                    value={values.account_nu || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.account_nu && touched.account_nu ? (<span className='text-danger form_label' >{errors.account_nu}</span>) : null}
                                />
                            </div>

                            <div className='col-md-3 mb-1 mt-2'>
                                <Text
                                    label_name='IFSC Code'
                                    placeholder=''
                                    name='ifsc_code'
                                    value={values.ifsc_code || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.ifsc_code && touched.ifsc_code ? (<span className='text-danger form_label' >{errors.ifsc_code}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3 mb-1 mt-2'>
                                <Text
                                    label_name='Bank Name'
                                    placeholder=''
                                    name='bank_name'
                                    value={values.bank_name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    // error={errors.bank_name && touched.bank_name ? (<span className='text-danger form_label' >{errors.bank_name}</span>) : null}
                                />
                            </div>
                            {/* <div className='col-md-4'></div> */}
                            <div className='col-md-4'>
                                <div className='row'>
                                <div className='col-md-12 mb-1 '>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Delivery Type'
                                    option={delivery_option ? delivery_option : []}
                                    disabled={false}
                                    name='delivery_type'
                                    defaultValue={delivery_option && delivery_option.find((option) => option.value === values.delivery_type)}
                                    onChange={(e) =>
                                        setFieldValue('delivery_type', e.value)
                                    }
                                    onBlur={handleBlur}
                                    // error={errors.delivery_type && touched.delivery_type ? (<span className='text-danger form_label' >{errors.delivery_type}</span>) : null}
                                />
                            </div>
                            {values.delivery_type === 'both' || values.delivery_type === 'delivery' ? <div className='col-md-12 mb-1 '>
                                        <Text
                                            label_name='Shipping Charges (per km)'
                                            placeholder=''
                                            name='shipping_charges'
                                            value={values.shipping_charges || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.shipping_charges && touched.shipping_charges ? (<span className='text-danger form_label' >{errors.shipping_charges}</span>) : null}
                                        />
                                    </div> : ''}
                                    <div className='col-md-12 mb-1'>
                                        <SingleSelect
                                            closeMenu={true}
                                            label_name='Cash On Delivery'
                                            option={COD_option ? COD_option : []}
                                            disabled={false}
                                            name='cod'
                                            defaultValue={COD_option && COD_option.find((option) => option.value === values.cod)}

                                            onChange={(e) =>
                                                setFieldValue('cod', e.value)
                                            }
                                            onBlur={handleBlur}
                                            error={errors.cod && touched.cod ? (<span className='text-danger form_label' >{errors.cod}</span>) : null}
                                        />
                                    </div>
                                    <div className='col-md-12 mb-1'>

                                        <Text
                                            name='latitude'
                                            label_name={'Latitude'}
                                            // readOnly={'readOnly'}
                                            // value={markerPosition.lat}
                                            value={values.latitude}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.latitude && touched.latitude ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.latitude}</span>) : null}

                                    </div>
                                    <div className='col-md-12 mb-1'>
                                        <Text
                                            name='longitude'
                                            label_name={'Longitude'}
                                            // readOnly={'readOnly'}
                                            // value={markerPosition.lng}
                                            value={values.longitude}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.longitude && touched.longitude ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.longitude}</span>) : null}

                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>

                                {isLoaded && markerPosition ? (
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        // center={center}
                                        zoom={3}
                                        onLoad={onLoad}
                                        onUnmount={onUnmount}
                                        onClick={handleMarkerDragged}
                                        markerPosition={markerPosition}
                                        onMarkerDragged={handleMarkerDragged}
                                    >
                                        <Marker
                                            position={markerPosition}
                                            draggable={true}
                                            onDragEnd={event => {
                                                const lat = event.latLng.lat();
                                                const lng = event.latLng.lng();
                                                handleMarkerDragged(event);
                                            }}
                                        />
                                        <StandaloneSearchBox
                                            onLoad={ref => (inputRef.current = ref)}
                                            onPlacesChanged={handlePlaceChanged}
                                        >
                                            <input
                                                type='text'
                                                className='from-control google_search'
                                                placeholder='Search Location'
                                            />
                                        </StandaloneSearchBox>
                                        { /* Child components, such as markers, info windows, etc. */}
                                        <></>
                                    </GoogleMap>
                                ) : <></>
                                }

                            </div>
                            <div className='col-md-12 mt-2'>
                                <div className='row'>
                                    {/* <h5>Shop Images</h5>
                                    {vendorData?.ShopImageSchemas?.length ? */}
                                      {  vendorData?.ShopImageSchemas?.map(val =>
                                            <div className='col-md-2 mt-2'>
                                                <div className="post_img_section p-4" style={{ position: "relative" }}>
                                                    <span className='img_dele_ico' data-tooltip-id='del'
                                                        data-tooltip-content="Delete Image!"
                                                        data-tooltip-place="bottom" type='button' onClick={() => handleImgDelete(val)}><RxCrossCircled size={20} style={{ color: "red" }} /></span>
                                                    <img src={process.env.REACT_APP_IMAGE_URL + `${val.shop_image_url}`} className='img-fluid post_img' alt="img" />
                                                    {/* <img src={vendorImage} className='img-fluid post_img p-3' alt="img" /> */}
                                                </div>
                                                <Tooltip id='del' className='bg-danger tooltip_msg' />
                                            </div>
                                        ) 
                                    }
                                </div>
                            </div>

                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Update'} />
                            </div>
                        </div>}
                </div>
            </Form>
            <ToastContainer />
        </>
    )
}
