import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var BookingGraphRes;
export const BookingGraphTodo = createAsyncThunk('BookingGraph', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Get",
            url: process.env.REACT_APP_API_URL + 'admin_line_graph',
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return BookingGraphRes = res.data

    } catch (error) {
        return BookingGraphRes = error.response.data
    }
})

const BookingGraphSlice = createSlice({
    name: 'BookingGraph',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(BookingGraphTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(BookingGraphTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default BookingGraphSlice.reducer; 