import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Loader from '../../../loader/Loader'
import Text from '../../../input_filed/Text'
import SelectStatus from '../../../input_filed/SelectStatus'
import SaveButton from '../../../buttons/SaveButton'
import { ToastContainer, toast } from 'react-toastify'
import { useFormik } from 'formik'
import { CategorySchema, UpdateCategorySchema } from './Validation'
import { UpdateCategoryRes, UpdateCategoryTodo } from '../../../../redux/slices/master_slice/vehical_category/UpdateCategory'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import File from '../../../input_filed/File'

export default function UpdateCategoryForm() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation()
    const id = state?.id

    const initialValues = {
        category: state?.vehicle_category_name,
        image: state?.vehicle_category_image ,
        status: state?.vehicle_category_status,
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: UpdateCategorySchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(UpdateCategoryTodo(bodyFormData)).then(() => update_res(action))
        }
    })

    let bodyFormData = new FormData();
    bodyFormData.append("vehicle_category_id",id)
    bodyFormData.append("user_id", localStorage.getItem('user_id'))
    bodyFormData.append("vehicle_category_name", values.category)
    bodyFormData.append("vehicle_category_image", values.image)
    bodyFormData.append("vehicle_category_status", values.status)

    const update_res = () => {
        if (UpdateCategoryRes?.success) {
            toast.success(UpdateCategoryRes?.message, { position: "bottom-right" });
            setLoading(false)
            setTimeout(() => {
                navigate('/category')
            }, 1500);
        } else {
            setLoading(false)
            toast.error(UpdateCategoryRes?.message, { position: "bottom-right" });
        }
    }
    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Category'
                                    placeholder=''
                                    name='category'
                                    value={values.category || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.category && touched.category ? (<span className='text-danger form_label' >{errors.category}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4 mb-2 '>
                                <File
                                    name='image'
                                    label_name={'Category Image '}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue("image", e.currentTarget.files[0])}
                                    // error={errors.image && touched.image ? (<span className='text-danger form_label' >{errors.image}</span>) : null}
                                />

                            </div>
                            <div className='col-md-4'>
                                <SelectStatus
                                    closeMenu={true}
                                    label_name='Status'
                                    disabled={false}
                                    name='status'
                                    defaultValue={values.status}
                                    onChange={(e) =>
                                        setFieldValue('status', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                />
                            </div>
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Update'} />
                            </div>
                        </div>}
                </div>
            </Form>
            <ToastContainer />
        </>
    )
}
