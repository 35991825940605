import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var CMSPageDataRes;
export const CMSPageDataTodo = createAsyncThunk('CMSPageData', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'admin_cms_page',
            data: {
                "user_id": localStorage.getItem('user_id'),
                "page_type": data
            },
            headers: { "Content-Type": "application/json" }
        })
        return CMSPageDataRes = res.data

    } catch (error) {
        return CMSPageDataRes = error.response.data
    }
})


const CMSPageDataSlice = createSlice({
    name: 'CMSPageData',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(CMSPageDataTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('CMSPageData', state.data.response)
        });
        builder.addCase(CMSPageDataTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default CMSPageDataSlice.reducer; 