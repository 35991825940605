import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../../component/data-table/DataTable'
import { SecurityDepositListRes, SecurityDepositListTodo } from '../../redux/slices/security-deposit/SecurityDepositList'
import Loader from '../../component/loader/Loader'
import SecurityListAction from '../../component/data-table/data-table-action/security-deposit/SecurityListAction'

export default function SecurityDepositList(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const SecurityList = useSelector(state => state?.SecurityDeposity?.data?.data)

    useEffect(() => {
        setLoading(true)
        dispatch(SecurityDepositListTodo()).then(() => list_res())
    }, [bool])

    const list_res = () => {
        if (SecurityDepositListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    const columnDefs = [
        {
            headerName: 'S.NO',
            // valueGetter: 'node.rowIndex + 1',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'SECURITY DEPOSIT',
            field: 'security_deposit',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },


        {
            headerName: 'DEPOSIT AMOUNT',
            field: 'deposit_amount',
            filter: 'agTextColumnFilter',
            // cellRenderer: params => params.data.vehicle_brand_status === 'active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        // {
        //     field: 'ACTIONS',
        //     cellRenderer: SecurityListAction,
        //     cellRendererParams: {
        //         bool: bool,
        //         setBool: setBool,
        //         // setLoading: setLoading,
        //     },
        //     // wrapText: true,
        //     autoHeight: true,
        //     pinned: 'right',
        // }
    ]
    return (
        <>

            {loading ?
                <diV className='dd-flex justify-content-center mt-5 pt-5'>
                    <Loader />
                </diV> :
                SecurityList && SecurityList.length ?
                    <DataTable
                        rowData={SecurityList}
                        columnDefs={columnDefs}
                        height={'60vh'}
                    />
                  :
                    <div className='empty_section'>
                        <span>DATA NOT FOUND</span>
                    </div>
            } 

            <ToastContainer />
        </>
    )
}
