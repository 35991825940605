import * as Yup from "yup";

export const BannerSchema = Yup.object({

    name: Yup.string().required("Enter Banner Name"),
    type: Yup.string().required("Select Type"),
    // link: Yup.string().url('Invalid URL format').required('Link is Required'),
    banner:Yup.mixed().required("Banner Image"),
});

export const UpdateBannerSchema = Yup.object({

    name: Yup.string().required("Enter Banner Name"),
    type: Yup.string().required("Select Type"),
    // link: Yup.string().url('Invalid URL format').required('Link is Required'),
    // banner:Yup.mixed().required("Banner Image"),
});