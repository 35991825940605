import * as Yup from "yup";

export const CategorySchema = Yup.object({

    category: Yup.string().required("Enter Category Name"),
    image: Yup.mixed().required("Required Category Image"),
    status: Yup.string().required("Select Status"),
});

export const UpdateCategorySchema = Yup.object({

    category: Yup.string().required("Enter Category Name"),
    status: Yup.string().required("Select Status"),
});