import React, { useState } from 'react'
import CoupenList from './CoupenList'
import AddCoupenForm from '../../component/form/coupen/AddCoupen'

export default function AddCoupen() {
    const [bool, setBool] = useState(false)
    return (
        <>
       <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Add Coupon</h5>
                            <AddCoupenForm  bool={bool} setBool={setBool}/>
                        </div>
                        <div className='layout_head mt-3'>
                            <h5> Coupon List</h5>
                            <CoupenList  bool={bool} setBool={setBool}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
