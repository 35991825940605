import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var UpdateVehicleTypeRes;
export const UpdateVehicleTypeTodo = createAsyncThunk('UpdateVehicleType', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Put",
            url: process.env.REACT_APP_API_URL + `vehicle_type/${data.id}`,
            data: {
                "vehicle_type_name": data?.values?.type,
                "vehicle_type_status":data?.values?.status,
                "user_id": localStorage.getItem('user_id')
            },
            headers: { "Content-Type": "application/json" , "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return UpdateVehicleTypeRes = res.data
        
    } catch (error) {
        return UpdateVehicleTypeRes = error.response.data
    }
})
