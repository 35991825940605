import React, { useState } from 'react'
import CityServiceList from './CityServiceList'
import AddCityForm from '../../component/form/city-service/AddCityForm'

export default function AddCityService() {
    const [bool, setBool] = useState(false)
    return (
        <>
         <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Add City Service</h5>
                            <AddCityForm  bool={bool} setBool={setBool}/>
                        </div>
                        <div className='layout_head mt-3'>
                            <h5> City Service List</h5>
                            <CityServiceList  bool={bool} setBool={setBool}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
