import React from 'react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";


export default function CkEditor(props) {
    const customConfig = {
        toolbar: {
          items: [
            'heading', '|',
            'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|',
            'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', '|',
            'undo', 'redo'
          ]
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
        },
        // Additional configuration options go here
      };
    return (
        <>
            <label className="form-label form_label">{props.label}</label>
            <CKEditor
                editor={ClassicEditor}
                className='Ck_Editor'
                config={customConfig}
                name={props.name}
                data={props.data}
                onReady={props.onReady}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                onFocus={props.onFocus}                                                       
            />
        </>
    );
}


