import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Navbar from './layout/navbar/Navbar';

const useAuth = () => {
    let user = localStorage.getItem('user_id');
    // console.log(user)
    if (user) {
        try {
            if ((user)) {
                return user;
            }
        } catch (error) {
            console.log("log1", error)
            return false
        }

    } else {
        console.log("log2", "test")
        return false
    }
}

const ProtectedRoutes = () => {
    const auth = useAuth()
    // const userRole = localStorage.getItem('userRole');
    if (!auth) {
        return <Navigate to="/admin-login" />;
    }
    else {

        return (
            <div>
                <Navbar />
                <div id="content">
                    <Outlet />
                </div>
            </div>
        )
    }
}




export default ProtectedRoutes;
