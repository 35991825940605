import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AddSecurityDepositRes;
export const AddSecurityDepositTodo = createAsyncThunk('AddSecurityDeposit', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Post",
            url: process.env.REACT_APP_API_URL + 'security_deposit',
            data: {
                "security_deposit": data?.security_deposit,
                "deposit_amount": data?.security_deposit === 'no' ? '0' : data?.amount
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return AddSecurityDepositRes = res.data

    } catch (error) {
        return AddSecurityDepositRes = error.response.data
    }
})
