import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var ContactEnquiryListRes;
export const ContactEnquiryListTodo = createAsyncThunk('ContactEnquiryList', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Post",
            url: process.env.REACT_APP_API_URL + 'admin_get_vendor_enquiry',
            params:{
                "user_id": localStorage.getItem('user_id')
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return ContactEnquiryListRes = res.data

    } catch (error) {
        return ContactEnquiryListRes = error.response.data
    }
})

const ContactEnquiryListSlice = createSlice({
    name: 'ContactEnquiryList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(ContactEnquiryListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(ContactEnquiryListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default ContactEnquiryListSlice.reducer; 