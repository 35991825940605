import React, { useEffect, useState } from 'react'
import { CityListRes, CityListTodo } from '../../../redux/slices/user managment/venders/CityList'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../component/loader/Loader'
import DataTable from '../../../component/data-table/DataTable'
import CityListAction from '../../../component/data-table/data-table-action/vehicle-managment/CityListAction'
import { ToastContainer } from 'react-toastify'

export default function CityList() {
    const [bool, setBool] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const CityList = useSelector(state => state?.CityList?.data?.data)

    useEffect(() => {
        setLoading(true)
        dispatch(CityListTodo()).then(() => list_res())
    }, [bool])

    const list_res = () => {
        if (CityListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            // valueGetter: 'node.rowIndex + 1',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            // maxWidth: 80
        },
        {
            headerName: 'CITY',
            field: 'city_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },


        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: CityListAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head mt-3 mb-2'>
                            <h5 >City List</h5>
                        </div>
                        {loading ?
                            <diV className='dd-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </diV> :
                            CityList && CityList.length ?
                                <DataTable
                                    rowData={CityList}
                                    columnDefs={columnDefs}
                                    height={'70vh'}
                                />
                                :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    )
}
