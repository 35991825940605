import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var DeleteBannerRes;
export const DeleteBannerTodo = createAsyncThunk('DeleteBanner', async (id) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Delete",
            url: process.env.REACT_APP_API_URL + `banner_data/${id}`,
          
            headers: { "Content-Type": "application/json" , "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return DeleteBannerRes = res.data
        
    } catch (error) {
        return DeleteBannerRes = error.response.data
    }
})
