import { Tab } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { Tabs } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { VenderDetailsRes, VenderDetailsTodo } from '../../../redux/slices/user managment/venders/VendersDetails'
import Loader from '../../../component/loader/Loader'
import VendorVehicleList from './VendorVehicleList'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import custmorLogo from '../../../assests/images/profile.png'
import VendorBookingList from './VendorBookingList'


export default function VendorDetails() {
    const { state } = useLocation()
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const venderData = useSelector(state => state?.VendorDetails?.data?.data?.[0])

    useEffect(() => {
        setLoading(true)
        dispatch(VenderDetailsTodo(state)).then(() => details_res())
    }, [])

    const details_res = () => {
        if (VenderDetailsRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    return (
        <>
            <div className="container-fluid">
                {loading ?
                    <div className='d-flex justify-content-center mt-5 pt-5'>
                        <Loader />
                    </div>
                    :
                    <div className="row">
                        <div className="col-md-3">
                            <div className='layout_head mb-2'>
                                <h5> Vendor Details</h5>
                            </div>
                            <div className='details_sidebar '>
                                <PerfectScrollbar >
                                    <div className='p-3'>
                                        <div className='basic_info text-center'>
                                            <div className="workout_profile_section mb-1">
                                                <img src={venderData?.profile_image === 'null' || venderData?.profile_image === '' ? '' : process.env.REACT_APP_IMAGE_URL + `${venderData?.profile_image}`} alt="userImg" className='img-fluid  profile_img' />
                                            </div>
                                            <h6>{venderData?.vendor_name}</h6>
                                            <p>{venderData?.vendor_email}</p>
                                            <span>( {venderData?.vendor_mobile_num} )</span>
                                        </div>
                                        <hr />
                                        <div className=' mt-2'>
                                            <p>Shop Name </p>
                                            {venderData?.shop_name === '' || venderData?.shop_name === null ? '-' : <span>{venderData?.shop_name}</span>}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Delivery Type  </p>
                                            {venderData?.delivery_type === '' || venderData?.delivery_type === null ? '-' : <span>{venderData?.delivery_type}</span>}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Cash On Delivery  </p>
                                            <span>{venderData?.cash_on_delivery === '0' ? 'No' : "Yes"}</span>
                                        </div>

                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>City </p>
                                            <span>{venderData?.CitySchema?.city_name}</span>
                                        </div>
                                        <div className=' mt-2'>
                                            <p>Address </p>
                                            {venderData?.shop_address === "" || venderData?.shop_address === null ? '-' : <span>{venderData?.shop_address}</span>}
                                        </div>
                                        <div className='mt-2'>
                                            <p>Aadhar Card </p>
                                            <span>{venderData?.adhaar_card_num}</span>
                                            <div className='aadhar_img_sec'>
                                                <img src={process.env.REACT_APP_IMAGE_URL +  venderData?.adhaar_card} className='img-fluid card_img' alt="Aadhar Card Image" />
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <p>Pan Card </p>
                                            <span>{venderData?.pan_card_num}</span>
                                            <div className='aadhar_img_sec'>
                                                <img src={process.env.REACT_APP_IMAGE_URL + venderData?.pan_card} className='img-fluid card_img' alt="Pan Card Image" />
                                            </div>
                                        </div>
                                        <div className=' mt-2'>
                                            <p>Account Name </p>
                                            {venderData?.account_name === '' || venderData?.account_name === 'null' || venderData?.account_name === null ? '-' : <span>{venderData?.account_name}</span>}
                                        </div>
                                        <div className=' mt-2'>
                                            <p>Account Number </p>
                                            {venderData?.account_num === '' || venderData?.account_num === 'null' || venderData?.account_num === null ? '-' : <span>{venderData?.account_num}</span>}
                                        </div>
                                        <div className=' mt-2'>
                                            <p>Bank Name </p>
                                            {venderData?.bank_name === '' || venderData?.bank_name === 'null' || venderData?.bank_name === null ? '-' : <span>{venderData?.bank_name}</span>}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>IFSC Code </p>
                                            {venderData?.ifsc_code === '' || venderData?.ifsc_code === 'null' || venderData?.ifsc_code === null ? '-' : <span>{venderData?.ifsc_code}</span>}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Latitude </p>
                                            <span>{venderData?.latitude}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Longitude </p>
                                            <span>{venderData?.longitude}</span>
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <Tabs
                                defaultActiveKey="vehicle"
                                id="fill-tab-example"
                                className="mb-3 piils_btn"
                                fill
                            >
                                <Tab eventKey="vehicle" title={<span> Vehicle List</span>}>
                                    {/* <div className="details_section"> */}
                                    <VendorVehicleList />
                                    {/* </div> */}
                                </Tab>
                                <Tab eventKey="booking" title={<span> Booking List</span>}>
                                   <VendorBookingList/>
                                </Tab>
                                <Tab eventKey="reviews" title={<span> Reviews List</span>}>
                                    <div className="details_section">

                                    </div>
                                </Tab>
                                <Tab eventKey="gallery" title={<span> Gallery </span>}>
                                    <div className="details_section">
                                        <PerfectScrollbar >
                                            <div className='p-3'>
                                                <div className='row'>
                                                    <h5>Shop Images</h5>
                                                    {venderData?.ShopImageSchemas.map((val, index) =>
                                                        <div className="col-md-4">
                                                            <div className="p_img_section p-3">
                                                                <img src={process.env.REACT_APP_IMAGE_URL + `${val?.shop_image_url}`} className='img-fluid p_img' alt="img" />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </PerfectScrollbar>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>}
            </div>
        </>
    )
}
