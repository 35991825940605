import React, { useState } from 'react'
import AddSecurityDepositForm from '../../component/form/security-deposit/AddSecurityDeposit'
import SecurityDepositList from './SecurityDepositList'

export default function AddSecurityDeposit() {

    const [bool, setBool] = useState(false)
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5 >Add Security Deposit</h5>
                            <AddSecurityDepositForm  bool={bool} setBool={setBool}/>
                        </div>
                        <div className='layout_head mt-3'>
                            <h5 > Security Deposit List</h5>
                            <SecurityDepositList  bool={bool} setBool={setBool}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
