import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var CityServiceListRes;
export const CityServiceListTodo = createAsyncThunk('CityServiceList', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Get",
            url: process.env.REACT_APP_API_URL + 'city_service',
          
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return CityServiceListRes = res.data

    } catch (error) {
        return CityServiceListRes = error.response.data
    }
})

const CityServiceListSlice = createSlice({
    name: 'CityServiceList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(CityServiceListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(CityServiceListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default CityServiceListSlice.reducer; 