import React, { useEffect, useState } from 'react'
import DataTable from '../../../component/data-table/DataTable'
import { ToastContainer } from 'react-toastify'
import VehicleListAction from '../../../component/data-table/data-table-action/vehicle-managment/VehicleListAction'
import AddButton from '../../../component/buttons/AddButton'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { VehicleListRes, VehicleListTodo } from '../../../redux/slices/master_slice/vehicle/VehicleList'
import Loader from '../../../component/loader/Loader'

export default function VehicleList() {
    const [loading, setLoading] = useState(false)
    const [bool, setBool] = useState(false)
    const dispatch = useDispatch()
    const vehicleList = useSelector(state => state?.VehicleList?.data?.data)

    useEffect(() => {
        setLoading(true)
        dispatch(VehicleListTodo()).then(() => list_res())
    }, [bool])

    const list_res = () => {
        if (VehicleListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            // valueGetter: 'node.rowIndex + 1',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'NAME',
            field: 'vehicle_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'IMAGE',
            field: 'VehicleImages?.[0]?.file_image',
            cellRenderer: params => <div className='table_img_sec'>
                <img className=" img-fluid table_data_img" src={process.env.REACT_APP_IMAGE_URL + params?.data?.VehicleImages?.[0]?.file_image} alt="image" />
            </div>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            autoHeight: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'CATEGORY',
            field: 'VehicleCategorySchema.vehicle_category_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'TYPE',
            field: 'VehicleTypeSchema.vehicle_type_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'CC',
            field: 'VehicleCCSchema.vehicle_cc_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },

        {
            headerName: 'BRAND',
            field: 'VehicleBrandSchema.vehicle_brand_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'MODEL',
            field: 'VehicleModelSchema.vehicle_model_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'STATUS',
            field: 'vehicle_status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.vehicle_status === 'active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: VehicleListAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head d-flex justify-content-between align-items-center'>
                            <h5> Vehicle List</h5>
                            <Link to='../add-vehicle'><AddButton name={'Add Vehicle'} /></Link>
                        </div>
                        {loading ?
                            <diV className='dd-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </diV> :
                            vehicleList && vehicleList.length ?

                                <div className='mt-3'>
                                    <DataTable
                                        rowData={vehicleList}
                                        columnDefs={columnDefs}
                                        height={'70vh'}
                                    />
                                </div>
                                :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        }
                    </div>
                </div>
            </div>


            <ToastContainer />
        </>
    )
}
