import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AddBrandRes;
export const AddBrandTodo = createAsyncThunk('AddBrand', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Post",
            url: process.env.REACT_APP_API_URL + 'vehicle_brand',
            data: {
                "vehicle_brand_name": data.brand,
                "vehicle_brand_status": data.status,
                "user_id": localStorage.getItem('user_id')
            },
            headers: { "Content-Type": "application/json" , "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return AddBrandRes = res.data
        
    } catch (error) {
        return AddBrandRes = error.response.data
    }
})
