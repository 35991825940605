import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var CustomerReqListRes;
export const CustomerReqListTodo = createAsyncThunk('CustomerReqList', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Get",
            url: process.env.REACT_APP_API_URL + `user_help_request/${'0'}`,
            params:{
                'request_type':'user'
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return CustomerReqListRes = res.data

    } catch (error) {
        return CustomerReqListRes = error.response.data
    }
})

const CustomerReqListSlice = createSlice({
    name: 'CustomerReqList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(CustomerReqListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(CustomerReqListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default CustomerReqListSlice.reducer; 