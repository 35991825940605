import React,{useState} from 'react'
import { Form } from 'react-bootstrap'
import Loader from '../../../loader/Loader'
import Text from '../../../input_filed/Text'
import SelectStatus from '../../../input_filed/SelectStatus'
import SaveButton from '../../../buttons/SaveButton'
import { ToastContainer, toast } from 'react-toastify'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { VehicleCCSchema } from './Validation'
import { AddVehicleCCRes, AddVehicleCCTodo } from '../../../../redux/slices/master_slice/vehicle cc/AddVehicleCC'

export default function AddCCForm(props) {
    const [loading, setLoading] = useState(false)
    const { bool, setBool } = props
    const dispatch = useDispatch()

    const initialValues = {
        cc: '',
        status: ""
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: VehicleCCSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(AddVehicleCCTodo(values)).then(() => add_res(action))
        }
    })
    const add_res = (action) => {
        if (AddVehicleCCRes?.success) {
            toast.success(AddVehicleCCRes?.message, { position: "bottom-right" });
            setBool(!bool)
            setLoading(false)
            action.resetForm()
        } else {
            toast.error(AddVehicleCCRes?.message, { position: "bottom-right" });
            setLoading(false)
        }
    }
    return (
        <>
        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                  <div className='form_layout mt-3'>
                      {loading ?
                          <div className='d-flex justify-content-center p-5'>
                              <Loader />
                          </div> :
                          <div className='row'>
                              <div className='col-md-4'>
                                  <Text
                                      label_name='CC'
                                      placeholder=''
                                      name='cc'
                                      value={values.cc || ''}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={errors.cc && touched.cc ? (<span className='text-danger form_label' >{errors.cc}</span>) : null}
                                  />
                              </div>
  
                              <div className='col-md-4'>
                                  <SelectStatus
                                      closeMenu={true}
                                      label_name='Status'
                                      disabled={false}
                                      name='status'
                                      defaultValue={''}
                                      onChange={(e) =>
                                          setFieldValue('status', e.value)
                                      }
                                      onBlur={handleBlur}
                                      error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                  />
                              </div>
                              <div className='col-md-12 text-end mt-2'>
                                  <SaveButton name={'Save'} />
                              </div>
                          </div>}
                  </div>
              </Form>
      </>
    )
}
