import React from 'react'
import { Form } from 'react-bootstrap'


export default function DateInput(props) {
  const { label_name, placeholder, disabled, name, value, onChange,error, onBlur,min } = props
  return (
    <>
      <Form.Group className="mb-3" controlId="email">
        <Form.Label className='form_label'>{label_name}</Form.Label>
        <Form.Control
          type="date"
          placeholder={placeholder}
          disabled={disabled}
          className='form_input'
          name={name}
          min={min}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        {error}
      </Form.Group>
    </>
  )
}
