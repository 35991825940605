import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { CityServiceListRes, CityServiceListTodo } from '../../redux/slices/city-service/CityService'
import DataTable from '../../component/data-table/DataTable'
import Loader from '../../component/loader/Loader'
import CityServiceAction from '../../component/data-table/data-table-action/CityServiceAction'

export default function BrandList(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const CityService = useSelector(state => state?.CityServiceList?.data?.data)

    useEffect(() => {
        setLoading(true)
        dispatch(CityServiceListTodo()).then(() => list_res())
    }, [bool])

    const list_res = () => {
        if (CityServiceListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    const columnDefs = [
        {
            headerName: 'S.NO',
            // valueGetter: 'node.rowIndex + 1',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'CITY',
            field: 'CitySchema.city_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },


        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: CityServiceAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>

            {loading ?
                <diV className='dd-flex justify-content-center mt-5 pt-5'>
                    <Loader />
                </diV> :
                CityService && CityService.length ?
                    <DataTable
                        rowData={CityService}
                        columnDefs={columnDefs}
                        height={'60vh'}
                    />
                    :
                    <div className='empty_section'>
                        <span>DATA NOT FOUND</span>
                    </div>
            }

        </>
    )
}
