import React from 'react'
import DataTable from '../../../component/data-table/DataTable'
import { ToastContainer } from 'react-toastify'
import AddButton from '../../../component/buttons/AddButton'
import { Link } from 'react-router-dom'
import StaffListAction from '../../../component/data-table/data-table-action/user-managment/StafListAction'

export default function StaffList() {

    const staffList = [

    ]

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'NAME',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'MOBILE',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'EMAIL',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
       
        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: StaffListAction,
            cellRendererParams: {
                // bool: bool,
                // setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>
          <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head d-flex justify-content-between align-items-center'>
                            <h5> Staff List</h5>
                           {/* <Link to='../add-vendor'> */}
                            {/* <AddButton name={'Add Staff'} /> */}
                            {/* </Link>  */}
                        </div>
                        {/* {loading ?
                            <diV className='dd-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </diV> :
                            departmentList && departmentList.length ? */}

                        <div className='mt-3'>
                            <DataTable
                                rowData={staffList}
                                columnDefs={columnDefs}
                                height={'70vh'}
                            />
                        </div>
                        {/* :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        } */}
                    </div>
                </div>
            </div>


            <ToastContainer />
        </>
    )
}
