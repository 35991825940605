import React from 'react'
import UpdateVendorForm from '../../../component/form/user-managment/vendors/UpdateVendorForm'

export default function UpdateVendor() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Update Vendor </h5>
                            <UpdateVendorForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
