import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var OrderGraphRes;
export const OrderGraphTodo = createAsyncThunk('OrderGraph', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Get",
            url: process.env.REACT_APP_API_URL + 'admin_bar_graph',
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return OrderGraphRes = res.data

    } catch (error) {
        return OrderGraphRes = error.response.data
    }
})

const OrderGraphSlice = createSlice({
    name: 'OrderGraph',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(OrderGraphTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(OrderGraphTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default OrderGraphSlice.reducer; 