import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { CustomerStatusRes, CustomerStatusTodo } from '../../../../redux/slices/user managment/customer/CustomerStatus'
import { CustomerDeleteRes, CustomerDeleteTodo } from '../../../../redux/slices/user managment/customer/DeleteCustomer'

export default function CustomerListAction(props) {
    const { bool, setBool } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const details = () => {
        navigate('../customer-details', { state: props?.data?.fk_user_id })
    }

    const change_status_active = () => {
        // console.log(props)
        Swal.fire({
            title: "Customer Active",
            text: "Are You Sure Want To Active This Customer ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Active'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(CustomerStatusTodo({ id: props.data.fk_user_id, status: 'active' })).then(() => act_res())
            }
        })
    }

    const act_res = () => {
        if (CustomerStatusRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Customer",
                'Customer Activate  Successfully',
                'success'
            )
        } else {
            toast.error(CustomerStatusRes?.message, { position: "bottom-right" })
        }
    }

    const change_status_inc = () => {
        Swal.fire({
            title: "Customer Inactive",
            text: "Are You Sure Want To Inactive This Customer ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Inactive'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(CustomerStatusTodo({ id: props.data.fk_user_id, status: 'inactive' })).then(() => incact_res())
            }

        })
    }

    const incact_res = () => {
        if (CustomerStatusRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Customer",
                'Customer Inactive  Successfully',
                'success'
            )
        } else {
            toast.error(CustomerStatusRes?.message, { position: "bottom-right" })
        }
    }


    const del_action = () => {

        Swal.fire({
            title: "Customer Delete",
            text: "Are You Sure Want To Delete This Customer ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(CustomerDeleteTodo(props.data.fk_user_id)).then(() => del_res())
            }
        })
    }

    const del_res = () => {
        if (CustomerDeleteRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Customer",
                'Customer Deleted Successfully',
                'success'
            )
        } else {
            toast.error(CustomerDeleteRes?.message, { position: "bottom-right" })
        }
    }
    return (
        <>
            <div>
                {props.data && props.data.status === 'active' ?
                    <LuEyeOff size={20} className='eye text-secondary'
                        onClick={() => change_status_inc()}
                        data-tooltip-id='deactivate'
                        data-tooltip-content="Inactive !"
                        data-tooltip-place="bottom"
                    />
                    :
                    <LuEye size={20} className='eye text-secondary'
                        onClick={() => change_status_active()}
                        data-tooltip-id='active'
                        data-tooltip-content="Active !"
                        data-tooltip-place="bottom"
                    />
                }
                <LuBadgeInfo size={20} className='details text-primary ms-3'
                    onClick={() => details()}
                    data-tooltip-id='details'
                    data-tooltip-content="Customer Details!"
                    data-tooltip-place="bottom"
                />

                <LuTrash2 size={20} className='delete text-danger ms-3'
                    onClick={() => del_action()}
                    data-tooltip-id='del'
                    data-tooltip-content="Delete Customer!"
                    data-tooltip-place="bottom"
                />

                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
