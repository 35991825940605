import React, { useState } from 'react'
import AddCategoryForm from '../../../component/form/vehicle-managment/category/AddCategoryForm'
import CategoryList from './CategoryList'

export default function AddCategory() {
    const [bool, setBool] = useState(false)
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Add Category</h5>
                            <AddCategoryForm bool={bool} setBool={setBool}/>
                        </div>

                        <div className='layout_head mt-3'>
                            <h5> Category List</h5>
                            <CategoryList  bool={bool} setBool={setBool}/>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
