import * as Yup from "yup";

export const CouponSchema = Yup.object({

    coupon_name: Yup.string().required("Enter Coupon Name"),
    code: Yup.string().required("Enter Coupon Code"),
    type: Yup.string().required("Select Type"),
    start_date: Yup.string().required("Enter Start Date"),
    end_date: Yup.string().required("Enter End Date"),
    discount_val: Yup.string().required("Enter Discount Value"),

});