import React from 'react'
import Flatpickr from 'react-flatpickr'
import "flatpickr/dist/themes/light.css"
import { Form } from 'react-bootstrap'

export default function DatePicker(props) {

    const { label_name, placeholder, name, value, onChange, option, onBlur, error ,disabled} = props
    return (
        <>
            <Form.Group className="mb-2" controlId="date">
               {label_name === '' ? '' : <Form.Label className='form_label'>{label_name}</Form.Label>}
                <Flatpickr
                    className='form-control form_input'
                    placeholder={placeholder}
                    options={option}
                    name={name}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                {error}
            </Form.Group>
        </>
    )
}
