import React from 'react'
import UpdateBannerForm from '../../component/form/banner/UpdateBannerForm'

export default function UpdateBanner() {
    return (
        <>
         <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Update Banner</h5>
                            <UpdateBannerForm />
                        </div>
                     
                    </div>
                </div>
            </div>
        </>
    )
}
