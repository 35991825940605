import React from 'react';
import { Form } from 'react-bootstrap'
import Select from 'react-select'

const SelectStatus = (props) => {

    const { label_name, placeholder, disabled, closeMenu, name, defaultValue, onChange, onBlur, error } = props

    const status_option = [
        { "value": 'active', "label": "Active" },
        { "value": 'inactive', "label": "Inactive" }
    ]

    return (
        <>
            <Form.Group className="mb-3" controlId="">
                <Form.Label className='form_label'>{label_name}</Form.Label>
                <Select
                    closeMenuOnSelect={closeMenu}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    options={status_option}
                    className='react_select'
                    classNamePrefix="select"
                    name={name}
                    defaultValue={status_option && status_option.find((option) => option.value === defaultValue)}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                {error}
            </Form.Group>
        </>
    );
}

export default SelectStatus;
