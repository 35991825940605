import React, { useEffect, useState } from 'react'
import Loader from '../../../component/loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { VenderVehicleDetailsRes, VenderVehicleDetailsTodo } from '../../../redux/slices/user managment/venders/VendorVehicleDetails'

export default function VendorVehicleDetails() {
    const { state } = useLocation()
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const VenVehicleData = useSelector(state => state?.VendorVehicleDet?.data?.data?.[0])

    useEffect(() => {
        setLoading(true)
        dispatch(VenderVehicleDetailsTodo(state)).then(() => details_res())
    }, [])

    const details_res = () => {
        if (VenderVehicleDetailsRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    return (
        <>
            <div className="container-fluid">
                {loading ?
                    <div className='d-flex justify-content-center mt-5 pt-5'>
                        <Loader />
                    </div>
                    :
                    <div className="row">
                        <div className="col-md-3">
                            <div className='layout_head mb-2'>
                                <h5> Vendor Vehicle Details</h5>
                            </div>
                            <div className='details_sidebar'>
                                {/* <div> */}
                                <PerfectScrollbar >
                                    <div className='p-3'>
                                        <div className='basic_info text-center'>
                                            <div className="workout_profile_section">
                                                <img src={process.env.REACT_APP_IMAGE_URL + `${VenVehicleData?.VehicleMasterSchema?.VehicleImages?.[0]?.file_image}`} alt="userImg" className='img-fluid workout_img' />
                                            </div>
                                            <h6>{VenVehicleData?.VehicleMasterSchema?.vehicle_name}</h6>
                                            <p>{VenVehicleData?.registration_num}</p>
                                            <span>( {VenVehicleData?.registration_year} )</span>
                                        </div>
                                        <hr />
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Brand </p>
                                            <span>{VenVehicleData?.VehicleMasterSchema?.VehicleBrandSchema?.vehicle_brand_name}</span>
                                        </div>

                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Model</p>
                                            <span>{VenVehicleData?.VehicleMasterSchema?.VehicleModelSchema?.vehicle_model_name}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Type </p>
                                            <span>{VenVehicleData?.VehicleMasterSchema?.VehicleTypeSchema?.vehicle_type_name}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>CC </p>
                                            <span>{VenVehicleData?.VehicleMasterSchema?.VehicleCCSchema?.vehicle_cc_name}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Delivery Type </p>
                                            <span>{VenVehicleData?.vehicle_delivery_type}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Cash On Delivery  </p>
                                            <span>{VenVehicleData?.cash_on_delivery === '0' ? 'No' : "Yes"}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Status  </p>
                                            <span>{VenVehicleData?.vehicle_status}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Added Date  </p>
                                            <span>{new Date(VenVehicleData?.added_date).toLocaleDateString('en-GB')}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Vehicle DOC  </p>
                                            {VenVehicleData?.vehicle_document === null || VenVehicleData?.vehicle_document === 'null' ? '' : <a href={process.env.REACT_APP_IMAGE_URL + `${VenVehicleData?.vehicle_document}`} target='/'>   <button className='btn down_btn'>Download</button></a>}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Vehicle RC DOC  </p>
                                            <a href={process.env.REACT_APP_IMAGE_URL + `${VenVehicleData?.vehicle_rc_document}`} target='/'>   <button className='btn down_btn'>Download</button></a>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Insurance DOC  </p>
                                            {VenVehicleData?.vehicle_insurance_document === null || VenVehicleData?.vehicle_insurance_document === 'null' ? '' : <a href={process.env.REACT_APP_IMAGE_URL + `${VenVehicleData?.vehicle_insurance_document}`} target='/'>  <button className='btn down_btn'>
                                                Download </button> </a>}
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                                {/* </div> */}

                                {/*   <div className='d-flex justify-content-between align-items-center mt-2'>
                                    <p>City </p>
                                </div>
                                <div className=' mt-2'>
                                    <p>Address </p>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-9" style={{marginTop:'28px'}}>
                            <div className="details_section">
                                <PerfectScrollbar >
                                    <div className='p-3'>
                                        <h6>{VenVehicleData?.VehicleMasterSchema?.vehicle_description}</h6>
                                        <div className="row">
                                            <h5 className='mt-1' style={{ fontWeight: '600', color: 'grey' }}>Gallery</h5>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    {VenVehicleData?.VendorVehiclePhotos?.map((val, index) =>
                                                        <div className="col-md-6 mt-1">
                                                            <div className="p_img_section">
                                                                <img src={process.env.REACT_APP_IMAGE_URL + `${val?.vehicle_photos_url}`} className='img-fluid p_img' alt="img" />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-6 mt-2"  >
                                                <div className="text-center" >
                                                    {/* <iframe src={process.env.REACT_APP_IMAGE_URL + `${value.media_name}`} title="YouTube video" allowFullScreen style={{ minWidth: '450px', minHeight: '300px' }}></iframe> */}
                                                    {VenVehicleData.vehicle_video  === null ?
                                                        <h6 className='mt-5'>NO VIDEO UPLOAD</h6> :
                                                        <video width="300" height="200" controls>
                                                            <source src={process.env.REACT_APP_IMAGE_URL + `${VenVehicleData?.vehicle_video }`} style={{ borderRadius: '15px' }} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>}
                                                </div>
                                            </div>
                                            <h5 className='mt-3' style={{ fontWeight: '600', color: 'grey' }}>Pricing</h5>
                                            {
                                                VenVehicleData?.VehiclePriceSchemas?.length ?
                                                    VenVehicleData?.VehiclePriceSchemas?.map((val, index) =>
                                                        <div className="col-md-3">
                                                            <div className='price_list'>
                                                                <p> {val?.first_day} - {val?.last_day} Days</p>
                                                                <span>{val?.vehicle_price} /-</span>
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    <div>
                                                        <h6>PRICING LIST EMPTY</h6> </div>}
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                    </div>}
            </div>
        </>
    )
}
