import React, { useEffect, useState } from 'react'
import Loader from '../../../component/loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { VenderVehicleListRes, VenderVehicleListTodo } from '../../../redux/slices/user managment/venders/VendorVehicleList'
import DataTable from '../../../component/data-table/DataTable'
import { useLocation } from 'react-router-dom'
import VenderVehicleListAction from '../../../component/data-table/data-table-action/user-managment/VendorVehicleListAction'

export default function VendorVehicleList() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const vendorVehicleList = useSelector(state => state?.VendorVehicleList?.data?.data?.vehicleList)
    const { state } = useLocation()

    useEffect(() => {
        setLoading(true)
        dispatch(VenderVehicleListTodo(state)).then(() => list_res())
    }, [])

    const list_res = () => {
        if (VenderVehicleListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    const columnDefs = [
        {
            headerName: 'S.NO',
            // valueGetter: 'node.rowIndex + 1',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'VEHICLE NAME',
            field: 'VehicleMasterSchema.vehicle_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'IMAGE',
            field: 'VehicleImages?.[0]?.file_image',
            cellRenderer: params => <div className='table_img_sec'>
                <img className=" img-fluid table_data_img" src={process.env.REACT_APP_IMAGE_URL + params?.data?.VehicleMasterSchema?.VehicleImages?.[0]?.file_image} alt="image" />
            </div>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            autoHeight: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'CATEGORY',
            field: 'VehicleMasterSchema.VehicleCategorySchema.vehicle_category_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'DELEVERY TYPE',
            field: 'vehicle_delivery_type',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'REGISTRATION NO.',
            field: 'registration_num',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },


        {
            headerName: 'STATUS',
            field: 'vehicle_status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.complete_price === '1' ? <span style={{ backgroundColor: "green", padding: '1px 3px', borderRadius: '50%', color: 'green' }}>on</span> : <span style={{ backgroundColor: "red", padding: '1px 5px', borderRadius: '50%', color: 'red' }}>of</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: VenderVehicleListAction,
            cellRendererParams: {
                // bool: bool,
                // setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
            width: 150
        }
    ]

    return (
        <>
            {/* <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"> */}
            {loading ?
                <diV className='dd-flex justify-content-center mt-5 pt-5'>
                    <Loader />
                </diV> :

                vendorVehicleList && vendorVehicleList.length ?
                    <div>
                        {/* <div className='form_layout'> */}
                            <div className="row">
                                <div className="col-md-4">
                                    <div className='vehicle_count'>
                                        <p style={{color:'green'}}>Online Vehicle</p>
                                        <span>5</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='vehicle_count'>
                                        <p style={{color:'red'}}>Offline Vehicle</p>
                                        <span>5</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='vehicle_count'>
                                        <p style={{color:'orange'}}>booked Vehicle</p>
                                        <span>5</span>
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                        <div className='mt-3'>
                            <DataTable
                                rowData={vendorVehicleList}
                                columnDefs={columnDefs}
                                height={'60vh'}
                            />
                        </div>
                    </div>
                    :
                    <div className='empty_section'>
                        <span>DATA NOT FOUND</span>
                    </div>
            }
            {/* </div>
                </div>
            </div> */}
        </>
    )
}
