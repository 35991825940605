import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import Text from '../../input_filed/Text'
import SingleSelect from '../../input_filed/SingleSelect'
import SaveButton from '../../buttons/SaveButton'
import Loader from '../../loader/Loader'
import { AddSecurityDepositRes, AddSecurityDepositTodo } from '../../../redux/slices/security-deposit/AddSecurityDeposit'
import { toast } from 'react-toastify'
import { securitySchema } from './Validation'

export default function AddSecurityDepositForm(props) {
    const { bool, setBool } = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const deposit_option = [
        { "value": 'yes', "label": "Yes" },
        { "value": 'no', "label": "No" }
    ]

    const initialValues = {
        security_deposit: '',
        amount: ""
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: securitySchema,

        onSubmit: (values, action) => {
            // setLoading(true)
            console.log(values)
            dispatch(AddSecurityDepositTodo(values)).then(() => add_res(action))
        }
    })

    const add_res = (action) => {
        if (AddSecurityDepositRes?.success) {
            toast.success(AddSecurityDepositRes?.message, { position: "bottom-right" });
            setBool(!bool)
            setLoading(false)
            action.resetForm()
        } else {
            toast.error(AddSecurityDepositRes?.message, { position: "bottom-right" });
            setLoading(false)
        }
    }
    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4 '>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Security Deposit'
                                    option={deposit_option}
                                    disabled={false}
                                    name='security_deposit'
                                    defaultValue={''}
                                    onChange={(e) =>
                                        setFieldValue('security_deposit', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.security_deposit && touched.security_deposit ? (<span className='text-danger form_label' >{errors.security_deposit}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Deposit Amount'
                                    placeholder=''
                                    name='amount'
                                    value={values.security_deposit === 'yes' ? values.amount : ''}
                                    disabled={values.security_deposit === 'no' ? 'disabled' : ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.amount && touched.amount ? (<span className='text-danger form_label' >{errors.amount}</span>) : null}
                                />
                            </div>
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Save'} />
                            </div>
                        </div>}
                </div>
            </Form>
        </>
    )
}
