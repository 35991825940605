import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Loader from '../../../loader/Loader'
import Text from '../../../input_filed/Text'
import SelectStatus from '../../../input_filed/SelectStatus'
import SaveButton from '../../../buttons/SaveButton'
import { ToastContainer, toast } from 'react-toastify'
import { useFormik } from 'formik'
import { ModalSchema } from './Validation'
import { useDispatch, useSelector } from 'react-redux'
import { AddModelRes, AddModelTodo } from '../../../../redux/slices/master_slice/vehical modal/AddModal'
import { BrandListRes, BrandListTodo } from '../../../../redux/slices/master_slice/vehical_brand/BrandList'
import SingleSelect from '../../../input_filed/SingleSelect'

export default function AddModelForm(props) {
    const { bool, setBool } = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const brandList = useSelector(state => state?.BrandList?.data?.data)

    const brand_option = brandList && brandList.map(val => (
        { "value": val.id, "label": val.vehicle_brand_name }
    ))

    useEffect(() => {
        setLoading(true)
        dispatch(BrandListTodo()).then(() => brand_res())
    }, [])

    const brand_res=()=>{
        if (BrandListRes?.sucess) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    const initialValues = {
        brand:'',
        model: '',
        status: ""
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: ModalSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(AddModelTodo(values)).then(() => add_res())
            action.resetForm()
        }
    })

    const add_res = () => {
        if (AddModelRes?.success) {
            setBool(!bool)
            setLoading(false)
            toast.success(AddModelRes?.message, { position: "bottom-right" });
        } else {
            toast.error(AddModelRes?.message, { position: "bottom-right" });
            setLoading(false)
        }
    }
    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4 mb-3'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Brand'
                                    option={brand_option ? brand_option : []}
                                    disabled={false}
                                    name='brand'
                                    defaultValue={''}
                                    onChange={(e) =>
                                        setFieldValue('brand', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.brand && touched.brand ? (<span className='text-danger form_label' >{errors.brand}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Text
                                    label_name='Model'
                                    placeholder=''
                                    name='model'
                                    value={values.model || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.model && touched.model ? (<span className='text-danger form_label' >{errors.model}</span>) : null}
                                />
                            </div>

                            <div className='col-md-4'>
                                <SelectStatus
                                    closeMenu={true}
                                    label_name='Status'
                                    disabled={false}
                                    name='status'
                                    defaultValue={''}
                                    onChange={(e) =>
                                        setFieldValue('status', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                />
                            </div>
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Save'} />
                            </div>
                        </div>}
                </div>
            </Form>
        </>
    )
}
