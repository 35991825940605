import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updateCMSPageRes;
export const updateCMSPageTodo = createAsyncThunk('updateCMSPage', async (data) => {
    console.log("updatedata", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'admin_cms_page_update',
            data: {
                "user_id": localStorage.getItem('user_id'),
                "page_type": data?.page_type,
                "title": '',
                "short_description": '',
                "long_description": data?.page_description
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateCMSPageRes = res.data

    } catch (error) {
        return updateCMSPageRes = error.response.data
    }
})