import React from 'react'
import { Form } from 'react-bootstrap'

export default function File(props) {

    const { label_name, disabled, name, onChange, onBlur, error } = props

    return (
        <>
            <Form.Group className="mb-3" controlId="file">
                <Form.Label className='form_label'>{label_name}</Form.Label>
                <Form.Control
                    type="file"
                    disabled={disabled}
                    className='form_input'
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                {error}
            </Form.Group>
        </>
    )
}
