import React, { useState, useEffect } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerDetailsRes, CustomerDetailsTodo } from '../../../redux/slices/user managment/customer/CustomerDetails';
import { useLocation } from 'react-router-dom';
import Loader from '../../../component/loader/Loader';
import custmorLogo from '../../../assests/images/profile.png'
import CustomerBookingList from './CustomerBookingList';

export default function CustomerDetails() {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { state } = useLocation()
    const customerDetail = useSelector(state => state?.CustomerDetails?.data?.data?.[0])
console.log(state)
    useEffect(() => {
        setLoading(true)
        dispatch(CustomerDetailsTodo(state)).then(() => details_res())
    }, [])

    const details_res = () => {
        if (CustomerDetailsRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    return (
        <>
            <div className="container-fluid">
                {loading ?
                    <div className='d-flex justify-content-center mt-5 pt-5'>
                        <Loader />
                    </div>
                    :
                    <div className="row">
                        <div className="col-md-3">
                            <div className='layout_head mb-2'>
                                <h5> Customer Details</h5>
                            </div>
                            <div className='details_sidebar '>
                                <PerfectScrollbar >
                                    <div className='p-3'>
                                        <div className='basic_info text-center'>
                                            <div className="workout_profile_section">
                                                <img src={customerDetail?.profile_image === '' || customerDetail?.profile_image === null ? custmorLogo : process.env.REACT_APP_IMAGE_URL + `${customerDetail?.profile_image}`} alt="userImg" className='img-fluid profile_img' />
                                            </div>
                                            <h6>{customerDetail?.customer_name}</h6>
                                            <p>{customerDetail?.customer_email}</p>
                                            <span>( {customerDetail?.customer_mobile_num} )</span>
                                        </div>
                                        <hr />
                                        <div className=' mt-2'>
                                            <p>Address </p>
                                            {customerDetail?.customer_address === '' || customerDetail?.customer_address === null ? '-' : <span>{customerDetail?.customer_address}</span>}
                                        </div>
                                        {/* <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Delivery Type  </p>
                                            {customerDetail?.delivery_type === '' || customerDetail?.delivery_type === null ? '-' : <span>{customerDetail?.delivery_type}</span>}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Cash On Delivery  </p>
                                            <span>{customerDetail?.cash_on_delivery === '0' ? 'No' : "Yes"}</span>
                                        </div> */}

                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>City </p>
                                            <span>{customerDetail?.CitySchema?.city_name}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Status </p>
                                            <span>{customerDetail?.status}</span>
                                        </div>
                                        {/* <div className=' mt-2'>
                                            <p>Address </p>
                                            {customerDetail?.shop_address === "" || customerDetail?.shop_address === null ? '-' : <span>{customerDetail?.shop_address}</span>}
                                        </div> */}
                                        <div className='mt-2'>
                                            <p>Aadhar Card </p>
                                            <span>{customerDetail?.adhaar_card_num}</span>
                                            <div className='aadhar_img_sec'>
                                                <img src={process.env.REACT_APP_IMAGE_URL + customerDetail?.adhaar_card} className='img-fluid card_img' alt="Aadhar Card Image" />
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <p>Driving License Nu. </p>
                                            <span>{customerDetail?.driving_license_num}</span>
                                            <div className='aadhar_img_sec'>
                                                <img src={process.env.REACT_APP_IMAGE_URL + customerDetail?.driving_license} className='img-fluid card_img' alt="Pan Card Image" />
                                            </div>
                                        </div>

                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <Tabs
                                defaultActiveKey="booking"
                                id="fill-tab-example"
                                className="mb-3 piils_btn"
                                fill
                            >

                                <Tab eventKey="booking" title={<span> Booking List</span>}>
                                    <CustomerBookingList />
                                </Tab>
                                <Tab eventKey="reviews" title={<span> Reviews List</span>}>
                                    {/* <div className="details_section">

                                    </div> */}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>}
            </div>
        </>
    )
}
