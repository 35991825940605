import React, { useEffect, useState } from 'react'
import DataTable from '../../../component/data-table/DataTable'
import { ToastContainer } from 'react-toastify'
import TypeListAction from '../../../component/data-table/data-table-action/vehicle-managment/TypeListAction'
import { useDispatch, useSelector } from 'react-redux'
import { VehicleTypeListRes, VehicleTypeListTodo } from '../../../redux/slices/master_slice/vehicle type/VehicleTypeList'
import Loader from '../../../component/loader/Loader'

export default function TypeList(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const TypeList = useSelector(state => state?.VehicleTypeList?.data?.data)

    useEffect(() => {
        setLoading(true)
        dispatch(VehicleTypeListTodo()).then(() => list_res())
    }, [bool])

    const list_res = () => {
        if (VehicleTypeListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            // valueGetter: 'node.rowIndex + 1',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            // maxWidth: 80
        },
        {
            headerName: 'TYPE',
            field: 'vehicle_type_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },


        {
            headerName: 'STATUS',
            field: 'vehicle_type_status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.vehicle_type_status === 'active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: TypeListAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>

            {loading ?
                <diV className='dd-flex justify-content-center mt-5 pt-5'>
                    <Loader />
                </diV> :
                TypeList && TypeList.length ?
                    <DataTable
                        rowData={TypeList}
                        columnDefs={columnDefs}
                        height={'60vh'}
                    />
                    :
                    <div className='empty_section'>
                        <span>DATA NOT FOUND</span>
                    </div>
            }

            <ToastContainer />
        </>
    )
}
