import React, { useEffect, useState } from 'react'
import DataTable from '../../../component/data-table/DataTable'
import { ToastContainer } from 'react-toastify'
import AddButton from '../../../component/buttons/AddButton'
import { Link } from 'react-router-dom'
import VenderListAction from '../../../component/data-table/data-table-action/user-managment/VenderListAction'
import { VenderListRes, VenderListTodo } from '../../../redux/slices/user managment/venders/VenderList'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../component/loader/Loader'
import VendorPopup from './VendorPopup'

export default function VendorsList() {
    const [loading, setLoading] = useState(false)
    const [bool, setBool] = useState(false)
    const dispatch = useDispatch()
    const vendorList = useSelector(state => state?.VendorList?.data?.data)
    const [vData, setVData] = useState('')
    const [view, setView] = useState(false)

    useEffect(() => {
        setLoading(true)
        dispatch(VenderListTodo()).then(() => list_res())
    }, [bool])

    const list_res = () => {
        if (VenderListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            // valueGetter: 'node.rowIndex + 1',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'NAME',
            field: 'vendor_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'MOBILE',
            field: 'vendor_mobile_num',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth:125
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'EMAIL',
            field: 'vendor_email',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            wrapText: true,
            autoHeight: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'SHOP NAME',
            field: 'shop_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params?.data?.status === 'active' ? <span className='badge text-bg-success'>Active</span> :params?.data?.status === 'inactive' ? <span className='badge text-bg-danger'>Inactive</span>:params?.data?.status === 'pending' ?<span className='badge text-bg-warning'>Pending</span>:<span className='badge text-bg-info'>Review</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth:125
        },
        {
            field: 'ACTIONS',
            cellRenderer: VenderListAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                vData:vData,
                setVData:setVData,
                view:view,
                setView:setView,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
            // maxWidth:140

        }
    ]
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head d-flex justify-content-between align-items-center'>
                            <h5> Vendor List</h5>
                            <Link to='../add-vendor'><AddButton name={'Add vendor'} /></Link>
                        </div>
                        {loading ?
                            <diV className='dd-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </diV> :
                            vendorList && vendorList.length ?

                                <div className='mt-3'>
                                    <DataTable
                                        rowData={vendorList}
                                        columnDefs={columnDefs}
                                        height={'70vh'}
                                    />
                                </div>
                                :
                                <div className='empty_section mt-3'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        }
                    </div>
                </div>
            </div>


            <ToastContainer />
            <VendorPopup vData={vData} setVData={setVData} view={view} setView={setView} bool={bool} setBool={setBool}/>
        </>
    )
}
