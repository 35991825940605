import React from 'react'
import LoginForm from '../../component/form/login/LoginForm'
import Logo from '../../assests/images/renders_logo_edit.png'

export default function AdminLogin() {
    return (
        <>
            <div className='login_bg'>
                <div className="container-fluid h-100">
                    <div className="login-container row d-flex h-100 g-0 justify-content-right">
                        {/* <div className="col-sm-6 align-self-md-center my-auto">
                            <div className='side-logo'>
                                <img src={sidelogo} alt="" className='img-fluid logo-img' />
                            </div>
                        </div> */}
                        <div className="col-md-4 offset-md-8 align-self-md-center">
                            <div className='login-details'>
                                <div>
                                    <div className='finalLogo_section'>
                                        <img src={Logo} alt="" className='img-fluid finalLogo' />
                                    </div>
                                    <h5 className='mt-3'>Please Log Into Your Account... ! </h5>
                                    <div className='mt-4'>
                                        <LoginForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
