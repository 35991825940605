import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var BookingListRes;
export const BookingListTodo = createAsyncThunk('BookingList', async (data) => {
    console.log("data", data)
    try {
        const res = await axios({
            method: "Get",
            url: process.env.REACT_APP_API_URL + `admin_booking_list/${'0'}`,
            params: {
                "fk_customer_details_id": data?.customer ? data?.customer : "",
                "fk_vendor_details_id": data?.vendor ? data?.vendor : "",
                "from_date": data?.from_date ? data?.from_date : "",
                "to_date": data?.to_date ? data?.to_date : "",
                "booking_status": data?.status ? data?.status : "",
                "search": "",
                "recent_booking": "",
                "today_booking":data?.today_booking ? data?.today_booking : "",
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return BookingListRes = res.data

    } catch (error) {
        return BookingListRes = error.response.data
    }
})

const BookingListSlice = createSlice({
    name: 'BookingList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(BookingListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(BookingListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default BookingListSlice.reducer; 