import React, { useEffect, useState } from 'react'
import SaveButton from '../../../component/buttons/SaveButton';
import Loader from '../../../component/loader/Loader';
import { Form } from 'react-bootstrap';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import CkEditor from '../../../component/input_filed/CKEditer';
import { toast, ToastContainer } from 'react-toastify';
import { CMSPageDataRes, CMSPageDataTodo } from '../../../redux/slices/crm/CMSPageData';
import { updateCMSPageRes, updateCMSPageTodo } from '../../../redux/slices/crm/CMSUpdatePage';

export default function AboutUs() {

    const dispatch = useDispatch()
    const [bool, setBool] = useState(false)
    const [loading, setLoading] = useState(false)
    const aboutUsData = useSelector(state => state?.CMSPageData?.data?.data)
    // console.log(aboutUsData)

    const initialValues = {
        page_description: aboutUsData?.long_description,
        page_type: '1'
    }

    const PageSchema = Yup.object({
        page_description: Yup.string().required(" Aboutus Description is required."),
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: PageSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(updateCMSPageTodo(values)).then(() => update_res())
        }
    })

    const update_res = () => {
        if (updateCMSPageRes?.success) {
            toast.success(updateCMSPageRes?.message, { position: "bottom-right" });
            setBool(!bool)
            setLoading(false)
        } else {
            toast.error(updateCMSPageRes?.message, { position: "bottom-right" });
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(CMSPageDataTodo('1')).then(() => data_res())
    }, [bool])

    const data_res = () => {
        if (CMSPageDataRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5 >About Us</h5>
                        </div>
                        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                            <div className='form_layout mt-3'>
                                {loading ?
                                    <div className='d-flex justify-content-center p-5'>
                                        <Loader />
                                    </div> :
                                    <div className='row'>
                                        <div className="col-md-12 mb-3">
                                            <CkEditor
                                                label={'About us Description'}
                                                name={'page_description'}
                                                onReady={editor => {
                                                }}
                                                data={aboutUsData?.long_description}
                                                onChange={(event, editor) => {
                                                    setFieldValue("page_description", editor.getData());
                                                }}
                                                onBlur={(event, editor) => {
                                                }}
                                                onFocus={(event, editor) => {
                                                }}

                                            />
                                            {errors.page_description && touched.page_description ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.page_description}</span>) : null}

                                        </div>

                                        <div className='col-md-12 text-end mt-2'>
                                            <SaveButton name={'Update'} />
                                        </div>
                                    </div>}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
