import React from 'react'
import UpdateModelForm from '../../../component/form/vehicle-managment/model/UpdateModel'

export default function UpdateModel() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Update Model</h5>
                            <UpdateModelForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
