import React, { useState } from 'react'
import CClist from './CClist'
import AddCCForm from '../../../component/form/vehicle-managment/cc/AddCCForm'

export default function AddCC() {
    const [bool, setBool] = useState(false)
  return (
   <>
    <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Add CC</h5>
                            <AddCCForm bool={bool} setBool={setBool}/>
                        </div>
                        <div className='layout_head mt-3'>
                            <h5> CC List</h5>
                            <CClist bool={bool} setBool={setBool}/>
                        </div>
                    </div>
                </div>
            </div>
   </>
  )
}
