import React, { useEffect, useState } from 'react'
import DataTable from '../../../component/data-table/DataTable'
import { ToastContainer } from 'react-toastify'
import AddButton from '../../../component/buttons/AddButton'
import { Link } from 'react-router-dom'
import CustomerListAction from '../../../component/data-table/data-table-action/user-managment/CustomerListAction'
import { useDispatch, useSelector } from 'react-redux'
import { CustomerListRes, CustomerListTodo } from '../../../redux/slices/user managment/customer/CustomerList'
import Loader from '../../../component/loader/Loader'

export default function CustomerList() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [bool, setBool] = useState(false)
    const customerList = useSelector(state => state?.CustomerList?.data?.data)

    useEffect(() => {
        setLoading(true)
        dispatch(CustomerListTodo()).then(() => list_res())
    }, [bool])

    const list_res = () => {
        if (CustomerListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    const columnDefs = [
        {
            headerName: 'S.NO',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'NAME',
            field: 'customer_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'MOBILE',
            field: 'customer_mobile_num',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'EMAIL',
            field: 'customer_email',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: CustomerListAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head d-flex justify-content-between align-items-center'>
                            <h5> Customer List</h5>
                            {/* <Link to='../add-vendor'> */}
                            {/* <AddButton name={'Add Customer'} /> */}
                            {/* </Link>  */}
                        </div>
                        {loading ?
                            <diV className='dd-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </diV> :
                            customerList && customerList.length ?

                        <div className='mt-3'>
                            <DataTable
                                rowData={customerList}
                                columnDefs={columnDefs}
                                height={'70vh'}
                            />
                        </div>
                        :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        } 
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
