import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

export default function VenderVehicleListAction(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()



    const vendor_vehicle_details = () => {
        navigate('../vendor-vehicle-details', { state: props?.data?.id })
    }


    return (
        <>
            <div>
               
                <LuBadgeInfo size={20} className='details text-primary ms-3'
                    onClick={() => vendor_vehicle_details()}
                    data-tooltip-id='details'
                    data-tooltip-content="Vendor Vehicle Details!"
                    data-tooltip-place="bottom"
                />
                {/* <LuTrash2 size={20} className='delete text-danger ms-3'
                    onClick={() => del_action()}
                    data-tooltip-id='del'
                    data-tooltip-content="Delete Vender!"
                    data-tooltip-place="bottom"
                /> */}

                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
