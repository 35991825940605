import { configureStore } from "@reduxjs/toolkit";
import CategoryList from "./slices/master_slice/vehical_category/CategoryList";
import BrandList from "./slices/master_slice/vehical_brand/BrandList";
import CategoryDetails from "./slices/master_slice/vehical_category/CategoryDetails";
import VehicleTypeList from "./slices/master_slice/vehicle type/VehicleTypeList";
import VehicleCCList from "./slices/master_slice/vehicle cc/VehicleCCList";
import ModalList from "./slices/master_slice/vehical modal/ModalList";
import VehicleList from "./slices/master_slice/vehicle/VehicleList";
import VehicleDetails from "./slices/master_slice/vehicle/VehicleDetails";
import VenderList from "./slices/user managment/venders/VenderList";
import VendersDetails from "./slices/user managment/venders/VendersDetails";
import CityList from "./slices/user managment/venders/CityList";
import VendorVehicleList from "./slices/user managment/venders/VendorVehicleList";
import VendorVehicleDetails from "./slices/user managment/venders/VendorVehicleDetails";
import BannerList from "./slices/banner/BannerList";
import BannerDetails from "./slices/banner/BannerDetails";
import Dashboard from "./slices/dashboard/Dashboard";
import CustomerList from "./slices/user managment/customer/CustomerList";
import CustomerDetails from "./slices/user managment/customer/CustomerDetails";
import SecurityDepositList from "./slices/security-deposit/SecurityDepositList";
import CouponList from "./slices/coupon/CouponList";
import BookingList from "./slices/booking/BookingList";
import BookingDetails from "./slices/booking/BookingDetails";
import VendorHelpReq from "./slices/help-desk/VendorHelpReq";
import CustomerHelpReq from "./slices/help-desk/CustomerHelpReq";
import CustHelpReqDetail from "./slices/help-desk/CustHelpReqDetail";
import VendorHelpReqDetails from "./slices/help-desk/VendorHelpReqDetails";
import CMSPageData from "./slices/crm/CMSPageData";
import CMSList from "./slices/crm/CMSList";
import BookingGraph from "./slices/dashboard/BookingGraph";
import ContactEnquiryList from "./slices/contact-enquiry/ContactEnquiryList";
import EnquiryList from "./slices/vendor-enquiry/EnquiryList";
import OrderGraph from "./slices/dashboard/OrderGraph";
import CityService from './slices/city-service/CityService'



const Store = configureStore({
    reducer: {
        Dashboard : Dashboard,
        BookingGraph:BookingGraph,
        OrderGraph:OrderGraph,
        CategoryList: CategoryList,
        CategoryDetails:CategoryDetails,
        BrandList: BrandList,
        VehicleTypeList : VehicleTypeList,
        VehicleCC :VehicleCCList,
        ModalList :ModalList,
        VehicleList : VehicleList,
        VehicleDetails : VehicleDetails,
        VendorList : VenderList,
        VendorDetails : VendersDetails,
        VendorVehicleList : VendorVehicleList,
        VendorVehicleDet:VendorVehicleDetails,
        CityList :CityList,
        BannerList:BannerList,
        BannerDetails: BannerDetails,
        CustomerList : CustomerList,
        CustomerDetails : CustomerDetails,
        SecurityDeposity: SecurityDepositList,
        CouponList : CouponList,
        BookingList : BookingList,
        BookingDetails : BookingDetails,
        VendorHelpReq: VendorHelpReq,
        VendorHelpReqDetail: VendorHelpReqDetails,
        CustomerHelpReq: CustomerHelpReq,
        CustHelpReqDetail:CustHelpReqDetail,
        CMSPageData : CMSPageData,
        CMSList : CMSList,
        ContactEnquiryList : ContactEnquiryList,
        VendorEnquiryList : EnquiryList,
        CityServiceList: CityService,
      
    }
})
export default Store;