import React, { useEffect, useState } from 'react'
import { CityListRes, CityListTodo } from '../../../redux/slices/user managment/venders/CityList'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import SingleSelect from '../../input_filed/SingleSelect'
import SaveButton from '../../buttons/SaveButton'
import { AddCityServiceRes, AddCityServiceTodo } from '../../../redux/slices/city-service/AddCityService'
import { debounce } from 'lodash'
import { useFormik } from 'formik'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../loader/Loader'

export default function AddCityForm(props) {
    const { bool, setBool } = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [cityLoading, setCityLoading] = useState(false)
    const cityList = useSelector(state => state?.CityList?.data?.data)

    let city_option = cityList && cityList.map(val => (
        { "value": val.id, "label": val.city_name, 'value2': val.status }
    ))

   

    const load_city = debounce((e) => {
        if (e) {
            // console.log("city",e)
            setCityLoading(true)
            dispatch(CityListTodo(e)).then(() => city_res())
        }
      
    }, 500)
    const city_res = () => {
        if (CityListRes?.success) {
            setCityLoading(false)
        } else {
            setCityLoading(false)
        }
    }

    const initialValues = {
        city: '',
        status: ""
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        // validationSchema: BrandSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(AddCityServiceTodo(values)).then(() => add_res(action))
        }
    })

    useEffect(() => {

        // dispatch(CityListTodo()).then(() => city_res())

        city_option = []
    }, [])


    const add_res = (action) => {
        if (AddCityServiceRes?.success) {
            toast.success(AddCityServiceRes?.message, { position: "bottom-right" });
            setBool(!bool)
            setLoading(false)
            action.resetForm()
        } else {
            toast.error(AddCityServiceRes?.message, { position: "bottom-right" });
            setLoading(false)
        }
    }
    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row '>
                            <div className='col-md-4 '>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='City'
                                    option={city_option ? city_option : []}
                                    disabled={false}
                                    name='city'
                                    isClearable={"isClearable"}
                                    defaultValue={''}
                                    onChange={(e) => {
                                        setFieldValue('city', e?.value)
                                        setFieldValue('status', e?.value2)
                                    }
                                    }
                                    onInputChange={(e) => load_city(e)}
                                    onBlur={handleBlur}
                                    loading={cityLoading}
                                    error={''}
                                />
                            </div>
                            <div className='col-md-2' style={{ marginTop: '37px' }}>
                                <SaveButton name={'add'} />
                            </div>



                        </div>}
                </div>
            </Form>
            <ToastContainer />
        </>
    )
}
