import React from 'react'
import PropagateLoader from "react-spinners/PropagateLoader";

export default function Loader() {
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='d-flex justify-content-center'>
                            <PropagateLoader
                                color="#4a4c50"
                                size={25}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
