import React, { useState } from 'react'
import AddBannerForm from '../../component/form/banner/AddBannerForm'
import BannerList from './BannerList'

export default function AddBanner() {
    const [bool, setBool] = useState(false)
    return (
        <>
         <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Add Banner</h5>
                            <AddBannerForm  bool={bool} setBool={setBool}/>
                        </div>
                        <div className='layout_head mt-3'>
                            <h5> Banner List</h5>
                            <BannerList  bool={bool} setBool={setBool}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
