import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import LoginButton from '../../buttons/LoginButton'
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineMail } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { LoginSchema } from './Validation';
import { logInRes, logInTodo } from '../../../redux/slices/loginslice/Login';

export default function LoginForm() {
    const navigate = useNavigate();
    const [spiner, setSpiner] = useState(false)
    const dispatch = useDispatch()

    const initiaValues = {
        email: '',
        password: ''
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initiaValues,
        validationSchema: LoginSchema,


        onSubmit: (values, action) => {
            // console.log(values)
            setSpiner(true)
            // navigate('../dashboard')
            // localStorage.setItem('id', "1")
            dispatch(logInTodo(values)).then(() => login_res())

        }
    })

    const login_res =()=>{
        if (logInRes?.success) {
            setSpiner(false);
            // console.log(logInRes,"res")
            localStorage.setItem('user_id', logInRes?.data?.user_id)
            localStorage.setItem('user_token', logInRes?.data?.token)
            localStorage.setItem('mobile_num', logInRes?.data?.phone_num)
            navigate('../dashboard')
            
        } else {
            toast.error(logInRes && logInRes.message, { position: "bottom-right" });
            setSpiner(false);
        }
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>

                <div className="input-group  input_section" style={{ position: 'relative' }}>
                    <span className='input_icon'> <AiOutlineMail className='mb-1 me-2 ' style={{ color: "#ff2b49" }} size={20} /></span>
                    <input type="text" className="form-control form-login-input login_placeholder "
                        placeholder="Username"
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                </div>
                {errors.email && touched.email ? (<span className='text-danger ' >{errors.email}</span>) : null}
                <div className="input-group mt-3 input_section" style={{ position: 'relative' }}>
                    <span className='input_icon'> <RiLockPasswordLine style={{ color: "#ff2b49" }} className='mb-1 me-2' size={20} /></span>
                    <input type="password" className="form-control form-login-input "
                        placeholder="Password"
                        name='password'
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                </div>
                {errors.password && touched.password ? (<span className='text-danger text-start' >{errors.password}</span>) : null}

                <div className='mt-4 d-flex justify-content-center'>
                    <LoginButton name={'Login'} spiner={spiner} />
                </div>
            </Form>
            <ToastContainer />
        </>
    )
}
