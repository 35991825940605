import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AddBannerRes;
export const AddBannerTodo = createAsyncThunk('AddBanner', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Post",
            url: process.env.REACT_APP_API_URL + 'banner_data',
            data: data ,
            headers: { "Content-Type": "multipart/form-data" , "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return AddBannerRes = res.data
        
    } catch (error) {
        return AddBannerRes = error.response.data
    }
})
