import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { DeleteCategoryRes, DeleteCategoryTodo } from '../../../../redux/slices/master_slice/vehical_category/DeleteCategory'

export default function CategoryListAction(props) {
    const { bool, setBool ,setLoading} = props
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const edit_action = () => {
        navigate('../update-category', { state: props.data && props.data })
    }
    const del_action = () => {

        Swal.fire({
            title: "Category Delete",
            text: "Are You Sure Want To Delete This Category ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteCategoryTodo(props.data.id)).then(() => del_res())
            }
        })
    }

    const del_res = () => {
        if (DeleteCategoryRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Category",
                'Category Deleted Successfully',
                'success'
            )
            // setLoading(false)
        } else {
            toast.error(DeleteCategoryRes?.message, { position: "bottom-right" })
        }
    }
    return (
        <>
            <div>
                {/* {props.data && props.data.isActive ?
        <LuEyeOff size={20} className='eye text-secondary'
            onClick={() => change_status('false')}
            data-tooltip-id='deactivate'
            data-tooltip-content="De-Activate!"
            data-tooltip-place="bottom"
        />
        :
        <LuEye size={20} className='eye text-secondary'
            onClick={() => change_status('true')}
            data-tooltip-id='active'
            data-tooltip-content="Activate!"
            data-tooltip-place="bottom"
        />
    } */}

                <LuFileEdit size={20} className='edit text-success ms-3'
                    onClick={() => edit_action()}
                    data-tooltip-id='edit'
                    data-tooltip-content="Edit Category!"
                    data-tooltip-place="bottom"
                />
                {/* <LuBadgeInfo size={20} className='details text-primary ms-3'
        // onClick={() => customer_details()}
        data-tooltip-id='details'
        data-tooltip-content="Customer Details!"
        data-tooltip-place="bottom"
    />*/}
                <LuTrash2 size={20} className='delete text-danger ms-3'
                    onClick={() => del_action()}
                    data-tooltip-id='del'
                    data-tooltip-content="Delete Action!"
                    data-tooltip-place="bottom"
                />

                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
