import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var CustomerReqDetailsRes;
export const CustomerReqDetailsTodo = createAsyncThunk('CustomerReqDetails', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Get",
            url: process.env.REACT_APP_API_URL + `user_help_request/${data}`,
            params:{
                'request_type':'user'
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return CustomerReqDetailsRes = res.data

    } catch (error) {
        return CustomerReqDetailsRes = error.response.data
    }
})

const CustomerReqDetailsSlice = createSlice({
    name: 'CustomerReqDetails',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(CustomerReqDetailsTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(CustomerReqDetailsTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default CustomerReqDetailsSlice.reducer; 