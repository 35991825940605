import React, { useEffect, useState } from 'react'
import Loader from '../../../component/loader/Loader'
import { useLocation } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'

export default function PrivacyPolicyDetails() {
    const { state } = useLocation();
    const [loading, setLoading] = useState(false)
    // console.log("state", state)

    useEffect(() => {
        setLoading(true)
    }, [])

    setTimeout(() => {
        setLoading(false)
    }, 1500);
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                    <div className='layout_head'>
                            <h5 >Privacy Policy</h5>
                        </div>
                        {
                            loading ?
                                <div className='d-flex justify-content-center mt-5 pt-5'>
                                    <Loader />
                                </div> :
                                <div className='CRM_view'>
                                    <PerfectScrollbar>
                                        <div className='ps-3 pe-3'>
                                            <div dangerouslySetInnerHTML={{ __html: state.long_description }} />
                                        </div>
                                    </PerfectScrollbar>
                                </div>}
                    </div>
                </div>
            </div>
        </>
    )
}
