import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { BiLoader } from 'react-icons/bi';
import { VendorReqDetailsRes, VendorReqDetailsTodo } from '../../redux/slices/help-desk/VendorHelpReqDetails';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../component/loader/Loader';

export default function VendorReqDetails(props) {
    const { view, setView, vData } = props;
    const handleClose = () => setView(false);
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const DetailsData = useSelector(state => state?.VendorHelpReqDetail?.data?.data?.[0])
    console.log(DetailsData)

    useEffect(() => {
        setLoading(true)
        dispatch(VendorReqDetailsTodo(vData)).then(() => details_res())
    }, [vData])

    const details_res = () => {
        if (VendorReqDetailsRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    return (
        <>
            <Modal show={view} onHide={handleClose} size="md" aria-labelledby="example-modal-sizes-title-md">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-md"> Vendor Help Request Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ?
                        <div className='d-flex justify-content-center pt-3 pb-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='details'>
                                <div className='mt-1 d-flex justify-content-between align-items-center'>
                                    <p>Vendor Name</p>
                                    <span className='ms-2'>{DetailsData?.VendorDetailsSchemas?.[0]?.vendor_name}</span>
                                </div>
                                <div className='mt-1 d-flex justify-content-between align-items-center'>
                                    <p>Contact No.</p>
                                    <span className='ms-2'>{DetailsData?.VendorDetailsSchemas?.[0]?.vendor_mobile_num}</span>
                                </div>
                                <div className='mt-1 d-flex justify-content-between align-items-center'>
                                    <p>Email</p>
                                    <span className='ms-2'>{DetailsData?.VendorDetailsSchemas?.[0]?.vendor_email}</span>
                                </div>
                                <div className='mt-1 d-flex justify-content-between align-items-center'>
                                    <p>Shop Name</p>
                                    <span className='ms-2'>{DetailsData?.VendorDetailsSchemas?.[0]?.shop_name}</span>
                                </div>
                                <div className='mt-1  align-items-center'>
                                    <p>City</p>
                                    <span >{DetailsData?.VendorDetailsSchemas?.[0]?.CitySchema?.city_name}</span>
                                </div>
                                <div className='mt-1  align-items-center'>
                                    <p>Shop Address</p>
                                    <span >{DetailsData?.VendorDetailsSchemas?.[0]?.shop_address}</span>
                                </div>
                                <div className='mt-1 d-flex justify-content-between align-items-center'>
                                    <p>Request Title</p>
                                    <span className='ms-2'>{DetailsData?.request_title}</span>
                                </div>
                                <div className='mt-1  align-items-center'>
                                    <p>Details</p>
                                    <span >{DetailsData?.request_details}</span>
                                </div>
                                <div className='mt-1 d-flex justify-content-between align-items-center'>
                                    <p>Request Date</p>
                                    <span className='ms-2'>{new Date(DetailsData?.added_date).toLocaleDateString('en-GB')}</span>
                                </div>
                                <div className='mt-1 d-flex justify-content-between align-items-center'>
                                    <p>Status</p>
                                    <span className='ms-2'>{DetailsData?.status}</span>
                                </div>

                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
