import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../component/loader/Loader'
import DataTable from '../../component/data-table/DataTable'
import BannerListAction from '../../component/data-table/data-table-action/banner/BannerListAction'
import { ToastContainer } from 'react-toastify'
import { BannerListRes, BannerListTodo } from '../../redux/slices/banner/BannerList'
import { FaLink } from 'react-icons/fa'

export default function BannerList(props) {

    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const BannerList = useSelector(state => state?.BannerList?.data?.data)
    // console.log(BannerList)

    useEffect(() => {
        setLoading(true)
        dispatch(BannerListTodo()).then(() => list_res())
    }, [bool])

    const list_res = () => {
        if (BannerListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            // valueGetter: 'node.rowIndex + 1',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'NAME',
            field: 'banner_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'BANNER',
            field: 'banner_image',
            cellRenderer: params => <div className='table_img_sec'>
                <img className=" img-fluid table_data_img" src={process.env.REACT_APP_IMAGE_URL + params?.data?.banner_image} alt="image" />
            </div>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            autoHeight: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'TYPE',
            field: 'banner_type',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'LINK',
            field: 'banner_link',
            cellRenderer: params => params.value === '' ? '-': <a className='ms-2' href={params.value} target='blank'><FaLink />Link</a> 
            ,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },



        {
            headerName: 'STATUS',
            field: 'banner_status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.banner_status === 'active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: BannerListAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>
            {loading ?
                <diV className='dd-flex justify-content-center mt-5 pt-5'>
                    <Loader />
                </diV> :
                BannerList && BannerList.length ?
                    <DataTable
                        rowData={BannerList}
                        columnDefs={columnDefs}
                        height={'60vh'}
                    />
                    :
                    <div className='empty_section'>
                        <span>DATA NOT FOUND</span>
                    </div>
            }

            <ToastContainer />
        </>
    )
}
