import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import Loader from '../../../loader/Loader'
import Text from '../../../input_filed/Text'
import SelectStatus from '../../../input_filed/SelectStatus'
import SaveButton from '../../../buttons/SaveButton'
import { ToastContainer, toast } from 'react-toastify'
import { useFormik } from 'formik'
import SingleSelect from '../../../input_filed/SingleSelect'
import File from '../../../input_filed/File'
import { UpdatVehicleSchema, VehicleSchema } from './Validation'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { VehicleDetailsRes, VehicleDetailsTodo } from '../../../../redux/slices/master_slice/vehicle/VehicleDetails'
import { CategoryListRes, CategoryListTodo } from '../../../../redux/slices/master_slice/vehical_category/CategoryList'
import { VehicleTypeListTodo } from '../../../../redux/slices/master_slice/vehicle type/VehicleTypeList'
import { VehicleCCListTodo } from '../../../../redux/slices/master_slice/vehicle cc/VehicleCCList'
import { BrandListTodo } from '../../../../redux/slices/master_slice/vehical_brand/BrandList'
import { ModelListRes, ModelListTodo } from '../../../../redux/slices/master_slice/vehical modal/ModalList'
import { UpdateVehicleRes, UpdateVehicleTodo } from '../../../../redux/slices/master_slice/vehicle/UpdateVehicle'
import TextArea from '../../../input_filed/TextArea'

export default function UpdateVehicleForm() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const categoryList = useSelector(state => state?.CategoryList?.data?.data)
    const typeList = useSelector(state => state?.VehicleTypeList?.data?.data)
    const ccList = useSelector(state => state?.VehicleCC?.data?.data)
    const brandList = useSelector(state => state?.BrandList?.data?.data)
    const modelList = useSelector(state => state?.ModalList?.data?.data)
    const { state } = useLocation()
    const navigate = useNavigate()
    const modelRef = useRef()
    const id = state?.id
    const brandID = state?.vehicle_brand_id

    const category_option = categoryList && categoryList.map(val => (
        { "value": val.id, "label": val.vehicle_category_name }
    ))
    const type_option = typeList && typeList.map(val => (
        { "value": val.id, "label": val.vehicle_type_name }
    ))
    const cc_option = ccList && ccList.map(val => (
        { "value": val.id, "label": val.vehicle_cc_name }
    ))
    const brand_option = brandList && brandList.map(val => (
        { "value": val.id, "label": val.vehicle_brand_name }
    ))
    const model_option = modelList && modelList.map(val => (
        { "value": val.id, "label": val.vehicle_model_name }
    ))
  
    const initialValues = {
        category: state?.vehicle_category_id,
        type: state?.vehicle_type_id,
        cc: state?.vehicle_cc_id,
        brand: state?.vehicle_brand_id,
        model: state?.vehicle_model_id,
        vehicle_name: state?.vehicle_name,
        vehicle_img: state?.vehicle_image,
        vehicle_description:state?.vehicle_description,
        vehicle_icon: state?.vehicle_icon,
        status: state?.vehicle_status
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: UpdatVehicleSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(UpdateVehicleTodo(bodyFormData)).then(() => update_res(action))
        }
    })

    let bodyFormData = new FormData();
    bodyFormData.append("fk_vehicle_id",id)
    bodyFormData.append("user_id", localStorage.getItem('user_id'))
    bodyFormData.append("vehicle_category_id", values.category)
    bodyFormData.append("vehicle_type_id", values.type)
    bodyFormData.append("vehicle_cc_id", values.cc)
    bodyFormData.append("vehicle_brand_id", values.brand)
    bodyFormData.append("vehicle_model_id", values.model)
    bodyFormData.append("vehicle_name", values.vehicle_name)
    bodyFormData.append("vehicle_icon", values.vehicle_icon)

    for (var i = 0; i < values?.vehicle_img?.length; i++) {
        var file = values.vehicle_img[i];
        bodyFormData.append('vehicle_image', file);
    }
  
    bodyFormData.append("vehicle_description", values.vehicle_description)
    bodyFormData.append("vehicle_status", values.status)

    const update_res = () => {
        if (UpdateVehicleRes?.success) {
            setLoading(false)
            toast.success(UpdateVehicleRes?.message, { position: "bottom-right" });
            setTimeout(() => {
                navigate('/vehicle-list')
            }, 1000);
        } else {
            setLoading(false)
            toast.error(UpdateVehicleRes?.message, { position: "bottom-right" });
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(CategoryListTodo()).then(() => list_res())
        dispatch(ModelListTodo({ id:brandID, status: "active" })).then(() => model_res())
    }, [])

    const load_model = (id) => {
        if (id) {
            dispatch(ModelListTodo({ id, status: "active" })).then(() => model_res())
            modelRef && modelRef.current && modelRef.current.setValue([])
        }
    }

    const model_res = () => {
        if (ModelListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const list_res = () => {
        if (CategoryListRes?.success) {
            dispatch(VehicleTypeListTodo()).then((type_res) => {
                if (type_res?.payload?.success) {
                    dispatch(VehicleCCListTodo()).then((cc_res) => {
                        if (cc_res?.payload?.success) {
                            dispatch(BrandListTodo()).then((brand_res) => {
                                if (brand_res?.payload?.success) {
                                    setLoading(false)
                                    // load_model()
                                } else {
                                    setLoading(false)
                                }
                            })
                        } else {
                            setLoading(false)
                        }
                    })
                } else {
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
        }
    }



    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>

                            <div className='col-md-3 mb-2'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Category'
                                    disabled={false}
                                    option={category_option ? category_option : []}
                                    name='category'
                                    defaultValue={category_option && category_option.find((option) => option.value == values.category)} onChange={(e) =>
                                        setFieldValue('category', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.category && touched.category ? (<span className='text-danger form_label' >{errors.category}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3 mb-2'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Type'
                                    option={type_option ? type_option : []}
                                    disabled={false}
                                    name='type'
                                    defaultValue={type_option && type_option.find((option) => option.value == values.type)}
                                    onChange={(e) =>
                                        setFieldValue('type', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.type && touched.type ? (<span className='text-danger form_label' >{errors.type}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3 mb-2'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='CC'
                                    disabled={false}
                                    option={cc_option ? cc_option : []}
                                    name='cc'
                                    defaultValue={cc_option && cc_option.find((option) => option.value == values.cc)}
                                    onChange={(e) =>
                                        setFieldValue('cc', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.cc && touched.cc ? (<span className='text-danger form_label' >{errors.cc}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3 mb-2'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Brand'
                                    option={brand_option ? brand_option : []}
                                    disabled={false}
                                    name='brand'
                                    defaultValue={brand_option && brand_option.find((option) => option.value == values.brand)}
                                    onChange={(e) => {
                                        load_model(e.value);
                                        setFieldValue('brand', e.value)
                                        setFieldValue('model', '');
                                    }
                                    }
                                    onBlur={handleBlur}
                                    error={errors.brand && touched.brand ? (<span className='text-danger form_label' >{errors.brand}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3 mb-2'>
                                <SingleSelect
                                    closeMenu={true}
                                    option={model_option ? model_option : []}
                                    label_name='Model'
                                    reference={modelRef}
                                    disabled={false}
                                    name='model'
                                    defaultValue={model_option && model_option.find((option) => option.value === values.model)}
                                    onChange={(e) =>
                                        setFieldValue('model', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.model && touched.model ? (<span className='text-danger form_label' >{errors.model}</span>) : null}
                                />
                            </div>

                            <div className='col-md-3 mb-2'>
                                <Text
                                    label_name='Vehicle Name'
                                    placeholder=''
                                    name='vehicle_name'
                                    value={values.vehicle_name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.vehicle_name && touched.vehicle_name ? (<span className='text-danger form_label' >{errors.vehicle_name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3 mb-2 '>
                                <File
                                    name='vehicle_img'
                                    label_name={'Vehicle Image '}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue("vehicle_img", e.currentTarget.files)}
                                    // error={errors.vehicle_img && touched.vehicle_img ? (<span className='text-danger form_label' >{errors.vehicle_img}</span>) : null}
                                />

                            </div>
                            <div className='col-md-3 mb-2 '>
                                <File
                                    name='vehicle_icon'
                                    label_name={'Vehicle Icon '}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue("vehicle_icon", e.currentTarget.files[0])}
                                    // error={errors.vehicle_icon && touched.vehicle_icon ? (<span className='text-danger form_label' >{errors.vehicle_icon}</span>) : null}
                                />

                            </div>
                            <div className='col-md-3 mb-2'>
                                <SelectStatus
                                    closeMenu={true}
                                    label_name='Status'
                                    disabled={false}
                                    name='status'
                                    defaultValue={values.status}
                                    onChange={(e) =>
                                        setFieldValue('status', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.status && touched.status ? (<span className='text-danger form_label' >{errors.status}</span>) : null}
                                />
                            </div>
                            <div className='col-md-9 mb-2'>
                                <TextArea
                                    label_name='Vehicle Description'
                                    placeholder=''
                                    rows={4}
                                    name='vehicle_description'
                                    value={values.vehicle_description || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.vehicle_description && touched.vehicle_description ? (<span className='text-danger form_label' >{errors.vehicle_description}</span>) : null}
                                />
                            </div>
                         
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Update'} />
                            </div>
                        </div>}
                </div>
            </Form>
            <ToastContainer />
        </>
    )
}
