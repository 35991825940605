import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'


export default function DataTable(props) {
    const gridRef = useRef()

    const defaultColDef = useMemo(() => ({
        flex: 1,
        resizable: true,
    }), [])

    const paginationNumberFormatter = useCallback((params) => {
        return (
            params.value.toLocaleString()
        )
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();

    }, []);

    // const onGridSizeChanged = useCallback((params) => {
    //     gridRef.current.api.sizeColumnsToFit();
    // }, []);

    const onPageSizeChanged = useCallback(() => {
        var value = document.getElementById('page-size').value;
        gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);

    const onBtnExport = useCallback(() => {
        // var params = {
        //     columnKeys: ['vdepartment_name', 'vdept_manager_name', 'icount_employee', 'cstatus']
        // };
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const onSelectionChanged = useCallback((params) => {

        const selectedData = gridRef.current.api.getSelectedRows();
        props.value(props.field, selectedData)

    }, []);
    return (
        <>
            <div className="d-flex justify-content-between align-items-center mt-1">
                <div className='data-table-extensions-filter'>
                    {/* <label htmlFor="filterDataTable" className="icon"></label> */}
                    <input
                        type="text"
                        id="filter-text-box"
                        placeholder="Search"
                        onInput={onFilterTextBoxChanged}
                        className="form-control form_input"
                    />
                </div>
                <div className="d-flex align-items-center">
                    <label htmlFor="" className="form-label form_label" style={{ marginBottom: 0 }}>Page Size</label>
                    <select className="form-select form_input ms-2" onChange={onPageSizeChanged} id="page-size" style={{ width: '12vh' }}>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                    {/* <FaDownload size={25} onClick={onBtnExport} className='ms-4 me-2' style={{ cursor: 'pointer', color: '#56ab2f' }} /> */}
                </div>

            </div>
            <div className="ag-theme-alpine mt-2" style={{ height: props && props.height ? props.height : '60vh', width: '100%' }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={props.rowData ? props.rowData : ''}
                    columnDefs={props.columnDefs ? props.columnDefs : ''}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    check={props.check}
                    paginationPageSize={10}
                    paginationNumberFormatter={paginationNumberFormatter}
                    onFirstDataRendered={onFirstDataRendered}
                    // onGridSizeChanged={onGridSizeChanged}
                    rowHeight={props.rowHeight ? props.rowHeight : ''}
                    rowSelection={props.check ? props.check : ''}
                    suppressRowClickSelection={props.selection ? true : ''}
                    onSelectionChanged={props.selection ? onSelectionChanged : ''}
                />

            </div >
        </>
    )
}
