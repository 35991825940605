import React from 'react'
import AddVehicleForm from '../../../component/form/vehicle-managment/vehicle/AddVehicle'

export default function AddVehicle() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Add Vehicle </h5>
                            <AddVehicleForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
