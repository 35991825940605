import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CouponSchema } from './Validation'
import { Form } from 'react-bootstrap'
import Loader from '../../loader/Loader'
import Text from '../../input_filed/Text'
import DateInput from '../../input_filed/Date'
import SingleSelect from '../../input_filed/SingleSelect'
import SaveButton from '../../buttons/SaveButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { UpdateCouponRes, UpdateCouponTodo } from '../../../redux/slices/coupon/UpdateCoupon'
import { ToastContainer, toast } from 'react-toastify'

export default function UpdateCouponForm() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation();
    console.log(state)

    const type_option = [
        { "value": 'percent ', "label": "Percent" },
        { "value": 'cash', "label": "Cash" }
    ]

    const initialValues = {
        id: state?.id,
        coupon_name: state?.coupon_name,
        code: state?.coupon_code,
        type: state?.types_of_discount,
        start_date: state?.start_date,
        end_date: state?.end_date,
        no_of_users: state?.num_of_users ? state?.num_of_users : 0,
        discount_val: state?.discount_value,
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: CouponSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(UpdateCouponTodo(values)).then(() => update_res(action))
        }
    })

    const update_res = () => {
        if (UpdateCouponRes?.success) {
            toast.success(UpdateCouponRes?.message, { position: "bottom-right" });
            setLoading(false)
            setTimeout(() => {
                navigate('/coupon')
            }, 1500);
        } else {
            setLoading(false)
            toast.error(UpdateCouponRes?.message, { position: "bottom-right" });
        }
    }
    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-3'>
                                <Text
                                    label_name='Coupon Name'
                                    placeholder=''
                                    name='coupon_name'
                                    value={values.coupon_name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.coupon_name && touched.coupon_name ? (<span className='text-danger form_label' >{errors.coupon_name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3'>
                                <Text
                                    label_name='Code'
                                    placeholder=''
                                    name='code'
                                    disabled={'disabled'}
                                    maxLength={'6'}
                                    value={values.code || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.code && touched.code ? (<span className='text-danger form_label' >{errors.code}</span>) : null}
                                />
                            </div>

                            <div className='col-md-3'>
                                <DateInput
                                    label_name='Start Date'
                                    placeholder=''
                                    name='start_date'
                                    min={new Date().toISOString().split('T')[0]}
                                    value={values.start_date || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.start_date && touched.start_date ? (<span className='text-danger form_label' >{errors.start_date}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3'>
                                <DateInput
                                    label_name='End Date'
                                    placeholder=''
                                    name='end_date'
                                    min={values.start_date}
                                    value={values.end_date || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.end_date && touched.end_date ? (<span className='text-danger form_label' >{errors.end_date}</span>) : null}
                                />
                            </div>
                            <div className='col-md-3  mb-2'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Type'
                                    disabled={false}
                                    option={type_option ? type_option : []}
                                    name='type'
                                    defaultValue={type_option && type_option.find((option) => option.value == values.type)}
                                    onChange={(e) =>
                                        setFieldValue('type', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.type && touched.type ? (<span className='text-danger form_label' >{errors.type}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Text
                                    label_name='No of Users (0 for unlimited users)'
                                    placeholder=''
                                    name='no_of_users'
                                    value={values.no_of_users || 0}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={''}
                                />
                            </div>
                            <div className='col-md-3'>
                                <Text
                                    label_name='Discount Value'
                                    placeholder=''
                                    name='discount_val'
                                    value={values.discount_val || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.discount_val && touched.discount_val ? (<span className='text-danger form_label' >{errors.discount_val}</span>) : null}
                                />
                            </div>
                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Save'} />
                            </div>
                        </div>}
                </div>
            </Form>
            <ToastContainer/>
        </>
    )
}
