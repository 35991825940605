import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var UpdateBrandRes;
export const UpdateBrandTodo = createAsyncThunk('UpdateBrand', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Put",
            url: process.env.REACT_APP_API_URL + `vehicle_brand/${data.id}`,
            data: {
                "vehicle_brand_name": data?.values?.brand,
                "vehicle_brand_status":data?.values?.status,
                "user_id": localStorage.getItem('user_id')
            },
            headers: { "Content-Type": "application/json" , "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return UpdateBrandRes = res.data
        
    } catch (error) {
        return UpdateBrandRes = error.response.data
    }
})
