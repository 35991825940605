import React from 'react'
import { Button } from 'react-bootstrap'

export default function AddButton(props) {
    return (
        <>
            <Button type='button' onClick={props.onClick} variant="" className='save_btn'>{props.name}</Button>
        </>
    )
}
