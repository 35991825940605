import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { BookingGraphRes, BookingGraphTodo } from '../../redux/slices/dashboard/BookingGraph';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../component/loader/Loader';

export default function OrderGraph() {

  const dispatch = useDispatch()
  // const [loading, setLoading] = useState(false)
  const OrderData = useSelector(state => state?.OrderGraph?.data?.data)
  console.log(OrderData)

  // useEffect(() => {
  //   setLoading(true)
  //   dispatch(BookingGraphTodo()).then(() => dashboardFun())
  // }, [])

  // const dashboardFun = () => {
  //   if (BookingGraphRes?.success) {
  //     setLoading(false)
  //   } else {
  //     setLoading(false)
  //   }
  // }

  var series = [{
    name: 'Order',
    data: OrderData?.graph_one ? OrderData.graph_one.map(item => item.sum) : [] 
  },]

  var options = {
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    dataLabels: {
      enabled: true,
      //   formatter: function (val) {
      //     return val + "%";
      //   },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["rgb(34, 34, 34)"]
      }
    },
    xaxis: {
      categories:OrderData?.graph_one ? OrderData.graph_one.map(item => item.w_day) : [],
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        // formatter: function (val) {
        //   return val + "%";
        // }
      }
    },
    // title: {
    //   text: 'Weekly Booking',
    //   floating: true,
    //   offsetY: 330,
    //   align: 'center',
    //   style: {
    //     color: '#c7403d'
    //   }
    // }
  };
  return (
    <>
      <div>
        {/* {
          loading ?
            <div className='d-flex justify-content-center mt-5'>
              <Loader />
            </div> : */}
            <div>
              <div className='layout_head'>
                <h5>Weekly Orders</h5>
              </div>
              <div className='graph_section mt-2'>
                <ReactApexChart options={options} series={series} type="bar" height={350} width='100%' />
              </div>
            </div>
        {/* } */}

      </div>
    </>
  )
}
