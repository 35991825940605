import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { CityStatusRes, CityStatusTodo } from '../../../../redux/slices/master_slice/city/CityStatus'

export default function CityListAction(props) {
    const { bool, setBool } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const change_status_active = () => {
        // console.log(props)
        Swal.fire({
            title: "City Active",
            text: "Are You Sure Want To Active This City ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Active'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(CityStatusTodo({ id: props.data.id, city: props.data.city_name, status: 'active' })).then(() => act_res())
            }
        })
    }

    const act_res = () => {
        if (CityStatusRes?.success) {
            setBool(!bool)
            Swal.fire(
                "City",
                'City Activate  Successfully',
                'success'
            )
        } else {
            toast.error(CityStatusRes?.message, { position: "bottom-right" })
        }
    }

    const change_status_inc = () => {
        Swal.fire({
            title: "City Inactive",
            text: "Are You Sure Want To Inactive This City ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Inactive'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(CityStatusTodo({ id: props.data.id, city: props.data.city_name, status: 'inactive' })).then(() => incact_res())
            }

        })
    }

    const incact_res = () => {
        if (CityStatusRes?.success) {
            setBool(!bool)
            Swal.fire(
                "City",
                'City Inactive  Successfully',
                'success'
            )
        } else {
            toast.error(CityStatusRes?.message, { position: "bottom-right" })
        }
    }
    return (
        <>
            <div>
                {props.data && props.data.status === 'active' ?
                    <LuEyeOff size={20} className='eye text-secondary'
                        onClick={() => change_status_inc()}
                        data-tooltip-id='deactivate'
                        data-tooltip-content="Inactive !"
                        data-tooltip-place="bottom"
                    />
                    :
                    <LuEye size={20} className='eye text-secondary'
                        onClick={() => change_status_active()}
                        data-tooltip-id='active'
                        data-tooltip-content="Active !"
                        data-tooltip-place="bottom"
                    />
                }

                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
