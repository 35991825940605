import React from 'react'
import UpdateCategoryForm from '../../../component/form/vehicle-managment/category/UpdateCategoryForm'

export default function UpdateCategory() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                    <div className='layout_head'>
                            <h5>Update Category</h5>
                            <UpdateCategoryForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
