import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { MdAddTask } from 'react-icons/md'
import { toast } from 'react-toastify'
import { VenderStatusRes, VenderStatusTodo } from '../../../../redux/slices/user managment/venders/VenderStatusChange'

export default function VenderListAction(props) {
    const { bool, setBool, vData, setVData, view, setView } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const edit_action = () => {
        navigate('../update-vendor', { state: props?.data?.fk_user_id })
    }

    const vendor_details = () => {
        navigate('../vendor-details', { state: props?.data?.fk_user_id })
    }
    const change_status_active = () => {
        // console.log(props)
        Swal.fire({
            title: "Vendor Active",
            text: "Are You Sure Want To Active This Vendor ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Active'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(VenderStatusTodo({ id: props.data.fk_user_id, status: 'active' })).then(() => act_res())
            }
        })
    }

    const act_res = () => {
        if (VenderStatusRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Vendor",
                'Vendor Activate  Successfully',
                'success'
            )
        } else {
            toast.error(VenderStatusRes?.message, { position: "bottom-right" })
        }
    }

    const change_status_inc = () => {
        Swal.fire({
            title: "Vendor Inactive",
            text: "Are You Sure Want To Inactive This Vendor ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Inactive'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(VenderStatusTodo({ id: props.data.fk_user_id, status: 'inactive' })).then(() => incact_res())
            }

        })
    }

    const incact_res = () => {
        if (VenderStatusRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Vendor",
                'Vendor Inactive  Successfully',
                'success'
            )
        } else {
            toast.error(VenderStatusRes?.message, { position: "bottom-right" })
        }
    }

    const handleApprove = () => {
        setVData(props.data)
        setView(true)
    }

    return (
        <>
            <div>
                {props.data && props.data.status === 'active' || props.data && props.data.status === 'pending' ?
                    <LuEyeOff size={20} className='eye text-secondary ms-3'
                        onClick={() => change_status_inc()}
                        data-tooltip-id='deactivate'
                        data-tooltip-content="Inactive!"
                        data-tooltip-place="bottom"
                    />
                    : ""}
                {props.data && props.data.status === 'inactive' ?
                    <LuEye size={20} className='eye text-secondary ms-3'
                        onClick={() => change_status_active()}
                        data-tooltip-id='active'
                        data-tooltip-content="Active!"
                        data-tooltip-place="bottom"
                    /> : ''
                }
                {props.data && props.data.status === 'review' ?
                    <MdAddTask size={20} className='eye text-info ms-3'
                        onClick={() => handleApprove()}
                        data-tooltip-id='approve'
                        data-tooltip-content="Approval!"
                        data-tooltip-place="bottom"
                    /> : ''
                }

                <LuFileEdit size={20} className='edit text-success ms-3'
                    onClick={() => edit_action()}
                    data-tooltip-id='edit'
                    data-tooltip-content="Edit Vendor!"
                    data-tooltip-place="bottom"
                />
                <LuBadgeInfo size={20} className='details text-primary ms-3'
                    onClick={() => vendor_details()}
                    data-tooltip-id='details'
                    data-tooltip-content="Vendor Details!"
                    data-tooltip-place="bottom"
                />
                {/* <LuTrash2 size={20} className='delete text-danger ms-3'
                    onClick={() => del_action()}
                    data-tooltip-id='del'
                    data-tooltip-content="Delete Vender!"
                    data-tooltip-place="bottom"
                /> */}

                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='approve' className='bg-info tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
