import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var CustomerDetailsRes;
export const CustomerDetailsTodo = createAsyncThunk('CustomerDetails', async (id) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Get",
            url: process.env.REACT_APP_API_URL + `customer_details/${id}`,
            params: {
                "user_name":  '',
                "customer_status": "",
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return CustomerDetailsRes = res.data

    } catch (error) {
        return CustomerDetailsRes = error.response.data
    }
})

const CustomerDetailsSlice = createSlice({
    name: 'CustomerDetails',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(CustomerDetailsTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(CustomerDetailsTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default CustomerDetailsSlice.reducer; 