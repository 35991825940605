import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { DeleteBannerRes, DeleteBannerTodo } from '../../../../redux/slices/banner/DeleteBanner'
import { BannerStatusRes, BannerStatusTodo } from '../../../../redux/slices/banner/BannerStatus'

export default function BannerListAction(props) {
    const { bool, setBool } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const edit_action = () => {
        navigate('../update-banner', { state: props.data && props.data?.id })
    }
    const del_action = () => {

        Swal.fire({
            title: "Banner Delete",
            text: "Are You Sure Want To Delete This Banner ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteBannerTodo(props.data.id)).then(() => del_res())
            }

        })
    }

    const del_res = () => {
        if (DeleteBannerRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Banner",
                'Banner Deleted Successfully',
                'success'
            )
        } else {
            toast.error(DeleteBannerRes?.message, { position: "bottom-right" })
        }
    }
    const status_inactive = () => {

        Swal.fire({
            title: "Banner Inactive",
            text: "Are You Sure Want To Inactive This Banner ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Inactive'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(BannerStatusTodo({ id: props.data.id, status: 'inactive' })).then(() => ina_res())
            }

        })
    }

    const ina_res = () => {
        if (BannerStatusRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Banner",
                'Banner Inactivate Successfully',
                'success'
            )
        } else {
            toast.error(BannerStatusRes?.message, { position: "bottom-right" })
        }
    }
    const status_active = () => {

        Swal.fire({
            title: "Banner Active",
            text: "Are You Sure Want To Active This Banner ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Active'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(BannerStatusTodo({ id: props.data.id, status: 'active' })).then(() => act_res())
            }

        })
    }

    const act_res = () => {
        if (BannerStatusRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Banner",
                'Banner Activate Successfully',
                'success'
            )
        } else {
            toast.error(BannerStatusRes?.message, { position: "bottom-right" })
        }
    }
    return (
        <>
            <div>
                {props.data && props.data.banner_status === 'active' ?
                    <LuEyeOff size={20} className='eye text-secondary'
                        onClick={() => status_inactive()}
                        data-tooltip-id='Inactive'
                        data-tooltip-content="Inactive!"
                        data-tooltip-place="bottom"
                    />
                    :
                    <LuEye size={20} className='eye text-secondary'
                        onClick={() => status_active()}
                        data-tooltip-id='active'
                        data-tooltip-content="Activate!"
                        data-tooltip-place="bottom"
                    />
                }

                <LuFileEdit size={20} className='edit text-success ms-3'
                    onClick={() => edit_action()}
                    data-tooltip-id='edit'
                    data-tooltip-content="Edit Banner!"
                    data-tooltip-place="bottom"
                />
                {/* <LuBadgeInfo size={20} className='details text-primary ms-3'
        // onClick={() => customer_details()}
        data-tooltip-id='details'
        data-tooltip-content="Customer Details!"
        data-tooltip-place="bottom"
    />*/}
                <LuTrash2 size={20} className='delete text-danger ms-3'
                    onClick={() => del_action()}
                    data-tooltip-id='del'
                    data-tooltip-content="Delete Banner!"
                    data-tooltip-place="bottom"
                />

                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
