import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link } from 'react-router-dom'
import PublicNavbar from './PublicNavbar'
import { useDispatch, useSelector } from 'react-redux'
import { CMSPageDataRes, CMSPageDataTodo } from '../../redux/slices/crm/CMSPageData'
import Loader from '../../component/loader/Loader'

const TermConditionPublic = () => {
  const dispatch = useDispatch()
    const termconditions = useSelector(state => state?.CMSPageData?.data?.data)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        dispatch(CMSPageDataTodo('2')).then(() => data_res())
    }, [])

    const data_res = () => {
        if (CMSPageDataRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
  return (
    <div>

      <PublicNavbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className='privacy_term_view'>
              <PerfectScrollbar>
                {
                  loading ?
                    <div className='d-flex justify-content-between pt-5'>
                      <Loader />
                    </div> :
                    <div className='ps-5 pe-5'>
                    <div dangerouslySetInnerHTML={{ __html: termconditions?.long_description }} />
                   </div>
                }
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TermConditionPublic