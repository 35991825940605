import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var ModelListRes;
export const ModelListTodo = createAsyncThunk('ModelList', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Get",
            url: process.env.REACT_APP_API_URL + 'vehicle_model',
            params:{
                "vehicle_model_status ":data? data?.status: "",
                "fk_vehicle_brand_id":data? data?.id: ""
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return ModelListRes = res.data

    } catch (error) {
        return ModelListRes = error.response.data
    }
})

const ModelListSlice = createSlice({
    name: 'ModelList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(ModelListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(ModelListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});
    
export default ModelListSlice.reducer; 