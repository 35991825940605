import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var BannerStatusRes;
export const BannerStatusTodo = createAsyncThunk('BannerStatus', async (data) => {
    console.log("data", data)
    try {
        const res = await axios({
            method: "PUT",
            url: process.env.REACT_APP_API_URL + `update_banner_status/${data.id}`,
            params:{
                "banner_status" : data.status
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return BannerStatusRes = res.data

    } catch (error) {
        return BannerStatusRes = error.response.data
    }
})