import React, { useEffect, useRef, useState } from 'react'
import DataTable from '../../../component/data-table/DataTable'
import Loader from '../../../component/loader/Loader'
import { BookingListRes, BookingListTodo } from '../../../redux/slices/booking/BookingList'
import { useDispatch, useSelector } from 'react-redux'
import BookingListAction from '../../../component/data-table/data-table-action/booking/BookingListAction'
import { useFormik } from 'formik'
import { VenderListRes, VenderListTodo } from '../../../redux/slices/user managment/venders/VenderList'
import SingleSelect from '../../../component/input_filed/SingleSelect'
import DatePicker from '../../../component/input_filed/DatePicker'
import SaveButton from '../../../component/buttons/SaveButton'
import debounce from "lodash/debounce"
import { IoRefreshOutline } from 'react-icons/io5'
import moment from 'moment'

export default function CustomerBookingList() {
    const [loading, setLoading] = useState(false)
    const [vendorLoading, setVendorLoading] = useState(false)
    const [bool, setBool] = useState(false)
    const dispatch = useDispatch()
    const customerDetail = useSelector(state => state?.CustomerDetails?.data?.data?.[0])
    const BookingList = useSelector(state => state?.BookingList?.data?.data?.bookingList)
    const BookingCount = useSelector(state => state?.BookingList?.data?.data)
    // const customerList = useSelector(state => state?.CustomerList?.data?.data)
    const vendorList = useSelector(state => state?.VendorList?.data?.data)
    const [filterLoad, setFilterLoad] = useState(false)
    

    const vendor_opt = vendorList && vendorList.map(val => (
        { "value": val.id, "label": val.vendor_name }
    ))
    const status_option = [
        { "value": 'completed', "label": "Completed" },
        { "value": 'accepted', "label": "Accepted" },
        { "value": 'rejected', "label": "Rejected" },
        { "value": 'pending', "label": "Pending" },
        { "value": 'ongoing', "label": "Ongoing" },
        { "value": 'cancelled', "label": "Cancelled" },
    ]

    const initialValues = {
        vendor: '',
        customer: customerDetail?.id,
        from_date: "",
        to_date: "",
        status: '',
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(BookingListTodo(values)).then(() => filter_res())
        }
    })
    const pending_booking = () => {
        setLoading(true)
        dispatch(BookingListTodo({ 'status': 'pending', customer: customerDetail?.id })).then(() => filter_res())
    }
    const completed_booking = () => {
        setLoading(true)
        dispatch(BookingListTodo({ 'status': 'completed', customer: customerDetail?.id })).then(() => filter_res())
    }
    // const cancelled_booking = () => {
    //     setLoading(true)
    //     dispatch(BookingListTodo({'status':'cancelled'})).then(() => filter_res())
    // }

    const todays_booking = () => {
        setLoading(true)
        dispatch(BookingListTodo({ 'today_booking': 'true', customer: customerDetail?.id })).then(() => filter_res())
    }

    const filter_res = () => {
        if (BookingListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const selectVendRef = useRef();
    const selectStatusRef = useRef();
    const handleReset = () => {
        values.from_date = ""
        values.to_date = ""
        selectVendRef && selectVendRef.current && selectVendRef.current.clearValue();
        selectStatusRef && selectStatusRef.current && selectStatusRef.current.clearValue();
        setBool(!bool)
    };

    useEffect(() => {
        setLoading(true)
        dispatch(BookingListTodo({ customer: customerDetail?.id })).then(() => list_res())
    }, [bool])

    const list_res = () => {
        if (BookingListRes?.success) {
         setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const load_vendor = debounce((e) => {
        if (e) {
            // console.log("city",e)
            setVendorLoading(true)
            dispatch(VenderListTodo(e)).then(() => vendor_res())
        }
    }, 500)
    const vendor_res = () => {
        if (VenderListRes?.success) {
            setVendorLoading(false)
        } else {
            setVendorLoading(false)
        }
    }
    const columnDefs = [
        {
            headerName: 'S.NO',
            // valueGetter: 'node.rowIndex + 1',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        // {
        //     headerName: 'CUSTOMER',
        //     field: 'CustomerDetailsSchema.customer_name',
        //     filter: 'agTextColumnFilter',
        //     suppressMenu: true,
        //     filter: true,
        //     sortable: true,
        //     floatingFilter: false,
        //     // wrapText: true,
        //     // autoHeight: true,
        // },
        {
            headerName: 'VENDOR',
            field: 'VendorVehicleSchema.VendorDetailsSchema.vendor_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'VEHICLE',
            field: 'VendorVehicleSchema.VehicleMasterSchema.vehicle_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'PICKUP DATE',
            field: 'booking_picup_date',
            cellRenderer: params => new Date(params?.data?.booking_picup_date).toLocaleDateString('en-GB'),
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'RETURN DATE',
            field: 'booking_return_date',
            cellRenderer: params => new Date(params?.data?.booking_return_date).toLocaleDateString('en-GB'),
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'PAYMENT',
            field: 'total_price',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 120,

        },
        {
            headerName: 'STATUS',
            field: 'booking_status',
            filter: 'agTextColumnFilter',
            cellRenderer: params =>
                params?.data?.booking_status === 'completed' ? <span className='badge text-bg-success'>{params?.data?.booking_status}</span> :
                    params?.data?.booking_status === 'accepted' ? <span className='badge text-bg-info'>{params?.data?.booking_status}</span> :
                        params?.data?.booking_status === 'rejected' ? <span className='badge text-bg-danger'>{params?.data?.booking_status}</span> :
                            params?.data?.booking_status === 'cancelled' ? <span className='badge text-bg-danger'>{params?.data?.booking_status}</span> :
                                params?.data?.booking_status === 'ongoing' ? <span className='badge text-bg-primary'>{params?.data?.booking_status}</span> :
                                    params?.data?.booking_status === 'pending' ? <span className='badge text-bg-warning'>{params?.data?.booking_status}</span> : '',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 130,
        },
        {
            field: 'ACTIONS',
            cellRenderer: BookingListAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
            maxWidth: 120
        }
    ]
    return (
        <>
            <div className='form_layout p-3'>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className='booking_count' type='button' onClick={() => todays_booking()}>
                                <p style={{ color: 'blue' }}>Today's Booking</p>
                                <span>{BookingCount?.today_booking?.bookingcount}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='booking_count' type='button' onClick={() => pending_booking()}>
                                <p style={{ color: 'orange' }}>Pending Booking</p>
                                <span>{BookingCount?.pending_booking?.bookingcount}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='booking_count' type='button' onClick={() => completed_booking()}>
                                <p style={{ color: 'green' }}>Completed Booking</p>
                                <span>{BookingCount?.complete_booking?.bookingcount}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        {/* <div className="col-md-3">
                                       <SingleSelect
                                           closeMenu={true}
                                           placeholder={'Customer'}
                                           reference={selectcustoRef}
                                           label_name=''
                                           option={customer_opt ? customer_opt : []}
                                           disabled={false}
                                           name='customer'
                                           defaultValue={''}
                                           onChange={(e) =>
                                               setFieldValue('customer', e?.value)
                                           }
                                           onBlur={handleBlur}
                                           error={''}
                                       />
                                   </div> */}
                        <div className="col-md-3">
                            <SingleSelect
                                closeMenu={true}
                                placeholder={'Vendor'}
                                reference={selectVendRef}
                                label_name=''
                                option={vendor_opt ? vendor_opt : []}
                                disabled={false}
                                name='vendor'
                                defaultValue={''}
                                onChange={(e) =>
                                    setFieldValue('vendor', e?.value)
                                }
                                loading={vendorLoading}
                                onInputChange={(e) => load_vendor(e)}
                                onBlur={handleBlur}
                                error={''}
                            />
                        </div>
                        <div className='col-md-3'>
                            <DatePicker
                                label_name=''
                                placeholder={'From Date'}
                                option={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: 'Y-m-d',
                                    minDate: '',
                                    maxDate: '',
                                    disable: [],
                                    mode: "single",
                                    defaultDate: [],
                                    conjunction: "",
                                }}
                                name='from_date'
                                value={values.from_date}
                                onChange={(e) =>
                                    setFieldValue('from_date', moment(e[0]).format("YYYY-MM-DD"))

                                }
                                onBlur={handleBlur}
                                error={''}
                            />
                        </div>
                        <div className='col-md-3'>
                            <DatePicker
                                label_name=''
                                placeholder={'To Date'}
                                option={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: 'Y-m-d',
                                    minDate: '',
                                    maxDate: '',
                                    disable: [],
                                    mode: "single",
                                    defaultDate: [],
                                    conjunction: "",
                                }}
                                name='to_date'
                                value={values.to_date}
                                onChange={(e) =>
                                    setFieldValue('to_date', moment(e[0]).format("YYYY-MM-DD"))

                                }
                                onBlur={handleBlur}
                                error={''}
                            />
                        </div>
                        <div className='col-md-3'>
                            <SingleSelect
                                closeMenu={true}
                                placeholder={'Status'}
                                reference={selectStatusRef}
                                label_name=''
                                option={status_option ? status_option : []}
                                disabled={false}
                                name='status'
                                defaultValue={''}
                                onChange={(e) => { setFieldValue('status', e && e.value); }}
                                onBlur={handleBlur}
                            />
                        </div>
                        <div className='col-md-12'>
                            <div className='d-flex justify-content-end'>
                                <SaveButton name={'search'} />
                                <span className='ms-2 refresh_btn' onClick={handleReset} ><IoRefreshOutline style={{ color: 'white' }} size={25} /></span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {loading ?
                <diV className='dd-flex justify-content-center mt-5 pt-5'>
                    <Loader />
                </diV> :
                BookingList && BookingList.length ?

                    <div className='mt-1'>
                        <DataTable
                            rowData={BookingList}
                            columnDefs={columnDefs}
                            height={'55vh'}
                        />
                    </div> : <div className='empty_section mt-3'>
                        <span>DATA NOT FOUND</span>
                    </div>}
        </>
    )
}
