import React, { useState } from 'react'
import AddBrandForm from '../../../component/form/vehicle-managment/brand/AddBrandForm'
import BrandList from './BrandList'

export default function AddBrand() {
    const [bool, setBool] = useState(false)
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Add Brand</h5>
                            <AddBrandForm  bool={bool} setBool={setBool}/>
                        </div>
                        <div className='layout_head mt-3'>
                            <h5> Brand List</h5>
                            <BrandList  bool={bool} setBool={setBool}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
