import React, { useEffect, useState } from 'react'
import { CustomerReqListRes, CustomerReqListTodo } from '../../redux/slices/help-desk/CustomerHelpReq'
import CustomerReqAction from '../../component/data-table/data-table-action/help-desk/CustomerReqAct'
import Loader from '../../component/loader/Loader'
import DataTable from '../../component/data-table/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import CustmerReqDetails from './CustmerReqDetails'

export default function CustomerHelpReq() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const CustomerReqList = useSelector(state => state?.CustomerHelpReq?.data?.data)
    const [vData, setVData] = useState('')
    const [view, setView] = useState(false)

    useEffect(() => {
        setLoading(true)
        dispatch(CustomerReqListTodo()).then(() => list_res())
    }, [])

    const list_res = () => {
        if (CustomerReqListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    const columnDefs = [
        {
            headerName: 'S.NO',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
   
        {
            headerName: 'CUSTOMER',
            field: 'CustomerDetailsSchemas[0].customer_name',
            cellRenderer: params => params?.data?.CustomerDetailsSchemas?.[0]?.customer_name,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'MOBILE',
            field: 'CustomerDetailsSchemas[0].customer_mobile_num',
            cellRenderer: params => params?.data?.CustomerDetailsSchemas?.[0]?.customer_mobile_num,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'CITY',
            field: 'city',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'REQ. TITLE',
            field: 'request_title',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: CustomerReqAction,
            cellRendererParams: {
                view: view,
                setView:setView,
                vData:vData,
                setVData : setVData,
            },
            wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>
         <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head d-flex justify-content-between align-items-center'>
                            <h5> Customer Help Request List</h5>
                            {/* <Link to='../add-vendor'> */}
                            {/* <AddButton name={'Add Customer'} /> */}
                            {/* </Link>  */}
                        </div>
                        {loading ?
                            <diV className='dd-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </diV> :
                            CustomerReqList && CustomerReqList.length ?

                        <div className='mt-3'>
                            <DataTable
                                rowData={CustomerReqList}
                                columnDefs={columnDefs}
                                height={'70vh'}
                            />
                        </div>
                        :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        } 
                    </div>
                </div>
            </div>
            <CustmerReqDetails view={view} setView={setView} vData={vData} setVData={setVData}/>
        </>
    )
}
