import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var UpdateModelRes;
export const UpdateModelTodo = createAsyncThunk('UpdateModel', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Put",
            url: process.env.REACT_APP_API_URL + `vehicle_model/${data.id}`,
            data: {
                "fk_vehicle_brand_id": data?.values?.brand,
                "vehicle_model_name": data?.values?.model,
                "vehicle_model_status":data?.values?.status,
                "user_id": localStorage.getItem('user_id')
            },
            headers: { "Content-Type": "application/json" , "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return UpdateModelRes = res.data
        
    } catch (error) {
        return UpdateModelRes = error.response.data
    }
})
