import React from 'react'
import UpdateVehicleForm from '../../../component/form/vehicle-managment/vehicle/UpdateVehicle'

export default function UpdateVehicle() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Update Vehicle </h5>
                            <UpdateVehicleForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
