import * as Yup from "yup";

export const VenderSchema = Yup.object({

    vendor_name: Yup.string().required("Enter Vendor Name"),
    mobile_no: Yup.string().min(10, 'Must be exactly 10 digits').required("Enter Mobile Number").matches(/^(((0|((\+)?91(\-)?))|((\((\+)?91\)(\-)?)))?[6-9]\d{9})?$/, "Invalid Number"),
    email: Yup.string().required("Enter Email"),
    // shop_name: Yup.string().required("Enter Shop Name"),
    city: Yup.string().required("Select City"),
    address: Yup.string().required("Enter Shop Address"),
    aadhar_nu:Yup.string().required('Enter Aadhar Number').matches(/^[2-9][0-9]{3}[0-9]{4}[0-9]{4}$/ , "Invalid Aadhar Number"),
    aadhar_img:Yup.mixed().required("Required Aadhar Card Image"),
    pan_nu:Yup.string().required('Enter Pan Number').matches( /[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Invalid Pan Number"),
    pan_img: Yup.mixed().required("Required Pan Card Image"),
    shop_imgs: Yup.array()
    .required('Please upload at least one image')
    .min(1, 'Please upload at least one image')
    .max(5, 'You can upload up to 5 images'),
    latitude: Yup.string().required("Enter Latitude"),
    shipping_charges: Yup.string().required("Enter Shipping Charges"),
    longitude: Yup.string().required("Enter Longitude "),
    cod: Yup.string().required("Select Cash On Delivery"),
    // delivery_type: Yup.string().required("Select Delivery Type"),
    status: Yup.string().required("Select Status"),
    // account_name:Yup.string().required("Enter Account Name"),
    // account_nu:  Yup.string()
    // .matches(/^\d{10}$/, "Account number must be exactly 10 digits"),
    // bank_name: Yup.string().required("Enter Bank Name"),
    // ifsc_code: Yup.string()
    // .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code format"),
});

export const UpdateVenderSchema = Yup.object({

    vendor_name: Yup.string().required("Enter Vendor Name"),
    mobile_no: Yup.string().min(10, 'Must be exactly 10 digits').required("Enter Mobile Number").matches(/^(((0|((\+)?91(\-)?))|((\((\+)?91\)(\-)?)))?[6-9]\d{9})?$/, "Invalid Number"),
    email: Yup.string().required("Enter Email"),
    // shop_name: Yup.string().required("Enter Shop Name"),
    city: Yup.string().required("Select City"),
    address: Yup.string().required("Enter Shop Address"), 
    aadhar_nu:Yup.string().required('Enter Aadhar Number').matches(/^[2-9][0-9]{3}[0-9]{4}[0-9]{4}$/, "Invalid Aadhar Number"),
    // aadhar_img:Yup.mixed().required("Required Aadhar Card Image"),
    pan_nu:Yup.string().required('Enter Pan Number').matches( /[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Invalid Pan Number"),
    // pan_img: Yup.mixed().required("Required Pan Card Image"),
    shop_imgs: Yup.array()
    .required('Please upload at least one image')
    .min(1, 'Please upload at least one image')
    .max(5, 'You can upload up to 5 images'),
    latitude: Yup.string().required("Enter Latitude"),
    longitude: Yup.string().required("Enter Longitude "),
    shipping_charges: Yup.string().required("Enter Shipping Charges"),
    cod: Yup.string().required("Select Cash On Delivery"),
    // delivery_type: Yup.string().required("Select Delivery Type"),
    status: Yup.string().required("Select Status"),
    // account_name:Yup.string().required("Enter Account Name"),
    // account_nu:  Yup.string()
    // .matches(/^\d{10}$/, "Account number must be exactly 10 digits"),
    // bank_name: Yup.string().required("Enter Bank Name"),
    // ifsc_code: Yup.string().matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code format"),
});