import React, { useState } from 'react'
import AddModelForm from '../../../component/form/vehicle-managment/model/AddModel'
import ModelList from './ModelList'


export default function AddModel() {
    const [bool, setBool] = useState(false)
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Add Model</h5>
                            <AddModelForm bool={bool} setBool={setBool}/>
                        </div>
                        <div className='layout_head mt-3'>
                            <h5> Model List</h5>
                            <ModelList bool={bool} setBool={setBool}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
