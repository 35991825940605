import React from 'react'
import { Link } from 'react-router-dom'
import PublicNavbar from './PublicNavbar'
import PerfectScrollbar from 'react-perfect-scrollbar'

export default function HelpAndSupport() {
    return (
        <>
            < div>

                <PublicNavbar />
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className='privacy_term_view text-start '>
                                <PerfectScrollbar>
                                    <div className='ps-3 pe-3'>
                                        <h3>Help & Support</h3>
                                        <p>At Randers, we are committed to providing a smooth and hassle-free experience for both our users and partners. If you encounter any issues, need assistance, or have questions, our Help and Support team is here to assist you.</p>
                                        <div className='ps-4 pe-4'>
                                            <h5>1. Getting Started</h5>
                                            <p>How do I create an account? Download the Randers app from the Apple App Store or Google Play Store, open the app, and follow the simple registration steps to create your account.</p>
                                            <p className='mt-3'>How do I book a vehicle? After logging into your Randers account, enter your location, select your rental dates, browse available vehicles, and complete your booking in just a few taps.</p>
                                            <h5>2. Managing Bookings</h5>
                                            <p>Can I modify or cancel my booking? Yes, you can modify or cancel your booking directly through the app. Navigate to your booking history, select the relevant booking, and follow the steps to make changes or cancel. Please note that cancellation policies may apply.</p>
                                            <p className='mt-3'>What happens if I return the vehicle late? Late returns may incur additional charges depending on the terms of the rental agreement. If you foresee a delay, please contact us through the app or customer support to discuss your options.</p>
                                            <h5>3. Payments and Charges</h5>
                                            <p>What payment methods do you accept? Randers accepts all major credit/debit cards and digital payment methods. Payment is processed securely through the app.</p>
                                            <p className='mt-3'>Are there any hidden fees? No, Randers is committed to transparent pricing. All fees and charges, including taxes, are clearly displayed before you confirm your booking.</p>
                                            <p className='mt-3'>How do I apply a discount or promo code? You can enter your promo code during the checkout process, and any applicable discounts will be automatically applied to your total.</p>
                                            <h5>4. Vehicle Pickup and Return</h5>
                                            <p>How do I pick up my vehicle? Once your booking is confirmed, you will receive details about the vehicle's location and the pickup process. Some vehicles may have keyless entry, while others may require you to meet the owner</p>
                                            <p className='mt-3'>What if I encounter an issue with the vehicle? If you experience any issues with the vehicle (mechanical problems, damage, etc.), please contact our 24/7 customer support team immediately for assistance.</p>
                                            <h5>5. For Rendors Partners</h5>
                                            <p>How do I list my vehicle? Become a Rendors Partner by signing up through the app or website. Once registered, you can list your vehicles, manage bookings, and track your earnings through the partner dashboard.</p>
                                            <p className='mt-3'>How do I manage my fleet? Partners can manage their entire fleet using the Randers dashboard, where you can monitor vehicle availability, accept bookings, and view analytics on rentals and revenue</p>
                                            <h5>6. Account and Profile Management</h5>

                                            <p>How do I update my profile information? Go to your account settings within the app to update your profile, including your contact details, payment methods, and preferences.</p>

                                            <p className='mt-3'>How do I reset my password? If you forget your password, click on the "Forgot Password" link on the login screen, and follow the instructions to reset your password.</p>

                                            <h5>7. Customer Support</h5>
                                            <p>How do I contact customer support? We’re here for you 24/7! If you need help, you can reach our support team through any of the following ways:</p>
                                            <p className='mt-3'>
                                                In-App Support: Access our help center directly in the app for instant answers or to submit a request. <br />
                                                Email: Reach us at [support@randersapp.com]. <br />
                                                Phone: Call us at [Insert Support Number]. <br />
                                                Live Chat: Available in the app and on our website for real-time support. <br />
                                                FAQs: For quick answers to common questions, visit our [FAQ page](link to FAQ page).

                                            </p>
                                            <h5>8. Safety and Security</h5>
                                            <p>Is my personal information secure? Yes, Randers prioritizes the security of your personal and payment information. All transactions are encrypted and processed through secure servers.</p>
                                            <p className='mt-3'> What should I do if I suspect fraud? If you suspect unauthorized activity on your account or payment method, contact us immediately through our 24/7 support channels.</p>
                                            <p className='mt-3'> Randers is dedicated to providing you with a smooth, enjoyable experience, whether you're a renter or a Rendors Partner. For any issues or questions, feel free to contact us anytime—we're here to help!</p>
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
