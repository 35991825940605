import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AddModelRes;
export const AddModelTodo = createAsyncThunk('AddModel', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Post",
            url: process.env.REACT_APP_API_URL + 'vehicle_model',
            data: {
                "fk_vehicle_brand_id": data.brand,
                "vehicle_model_name": data.model,
                "vehicle_model_status": data.status,
                "user_id": localStorage.getItem('user_id')
            },
            headers: { "Content-Type": "application/json" , "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return AddModelRes = res.data
        
    } catch (error) {
        return AddModelRes = error.response.data
    }
})
