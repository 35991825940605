import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var CategoryListRes;
export const CategoryListTodo = createAsyncThunk('CategoryList', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Get",
            url: process.env.REACT_APP_API_URL + 'vehicle_category',
            params:{
                "vehicle_category_status": ""
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return CategoryListRes = res.data

    } catch (error) {
        return CategoryListRes = error.response.data
    }
})

const CategoryListSlice = createSlice({
    name: 'CategoryList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(CategoryListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(CategoryListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default CategoryListSlice.reducer; 