import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var VenderDetailsRes;
export const VenderDetailsTodo = createAsyncThunk('VenderDetails', async (id) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Get",
            url: process.env.REACT_APP_API_URL + `vendor_details/${id}`,
            params: {
                "search":  ''
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return VenderDetailsRes = res.data

    } catch (error) {
        return VenderDetailsRes = error.response.data
    }
})

const VenderDetailsSlice = createSlice({
    name: 'VenderDetails',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(VenderDetailsTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(VenderDetailsTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default VenderDetailsSlice.reducer; 