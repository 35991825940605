import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { CouponStatusRes, CouponStatusTodo } from '../../../../redux/slices/coupon/CouponStatus'

export default function CouponListAction(props) {
    const { bool, setBool } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const edit_action = () => {
        navigate('../update-coupon', { state: props.data && props.data })
    }
    const active_status = () => {
        // console.log(props)
        Swal.fire({
            title: "Coupon Active",
            text: "Are You Sure Want To Active This Coupon ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Active'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(CouponStatusTodo({ id: props.data.id, status: 'active' })).then(() => act_res())
            }
        })
    }

    const act_res = () => {
        if (CouponStatusRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Coupon",
                'Coupon Activate  Successfully',
                'success'
            )
        } else {
            toast.error(CouponStatusRes?.message, { position: "bottom-right" })
        }
    }


    const inactive_status = () => {
        Swal.fire({
            title: "Coupon Inactive",
            text: "Are You Sure Want To Inactive This Coupon ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Inactive'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(CouponStatusTodo({ id: props.data.id, status: 'inactive' })).then(() => incact_res())
            }

        })
    }

    const incact_res = () => {
        if (CouponStatusRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Coupon",
                'Coupon Inactive  Successfully',
                'success'
            )
        } else {
            toast.error(CouponStatusRes?.message, { position: "bottom-right" })
        }
    }

    return (
        <>
            <div>
                {props.data && props.data.coupon_status === 'active' ?
                    <LuEyeOff size={20} className='eye text-secondary'
                        onClick={() => inactive_status()}
                        data-tooltip-id='deactivate'
                        data-tooltip-content="Inactive!"
                        data-tooltip-place="bottom"
                    />
                    :
                    <LuEye size={20} className='eye text-secondary'
                        onClick={() => active_status()}
                        data-tooltip-id='active'
                        data-tooltip-content="Active!"
                        data-tooltip-place="bottom"
                    />
                }

                <LuFileEdit size={20} className='edit text-success ms-3'
                    onClick={() => edit_action()}
                    data-tooltip-id='edit'
                    data-tooltip-content="Edit Security Deposit!"
                    data-tooltip-place="bottom"
                />
                {/* <LuBadgeInfo size={20} className='details text-primary ms-3'
        // onClick={() => customer_details()}
        data-tooltip-id='details'
        data-tooltip-content="Customer Details!"
        data-tooltip-place="bottom"
    />*/}
                {/* <LuTrash2 size={20} className='delete text-danger ms-3'
                    onClick={() => del_action()}
                    data-tooltip-id='del'
                    data-tooltip-content="Delete Security Deposit!"
                    data-tooltip-place="bottom"
                /> */}

                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
