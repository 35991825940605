import React, { useEffect, useState } from 'react'
import '../home-page/css/style.css'
import { BiSolidUserDetail, BiPlanet, BiSelectMultiple, BiHeart, BiRupee, BiCalendarCheck, BiCheckShield, BiLogoPlayStore, BiLogoApple, BiPhone, BiEnvelope } from "react-icons/bi";
import { BsGooglePlay } from "react-icons/bs";
import { IoSendSharp } from "react-icons/io5";
import { FaApple } from "react-icons/fa";
import * as Yup from "yup";
import '../home-page/css/custom-animation.css'
import Swal from 'sweetalert2'
import '../home-page/css/responsive.css'
import WOW from 'wowjs';
import { useFormik } from 'formik';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { AddEnquiryRes, AddEnquiryTodo } from '../../redux/slices/vendor-enquiry/AddEnquiry';
import { toast, ToastContainer } from 'react-toastify';
import { AddContactEnquiryRes, AddContactEnquiryTodo } from '../../redux/slices/contact-enquiry/AddContactEnquiry';
import { Button, Collapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function HomePage() {

    const dispatch = useDispatch()
    const [spiner, setSpiner] = useState(false)
    const [open, setOpen] = useState(false);

    const onScroll = () => {

        if (window.scrollY > 250) {
            document.getElementById("nv").className = "navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0 sticky-top shadow-sm";
        }
        else {
            document.getElementById("nv").className = "navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0";
        }
    }

    const FormSchema = Yup.object({

        email: Yup.string().required("Enter Email"),
        subject: Yup.string().required("Enter Subject"),
        // link: Yup.string().url('Invalid URL format').required('Link is Required'),
        message: Yup.string().required("Enter Message"),
    });
    const ContactSchema = Yup.object({

        contact_nu: Yup.string().min(10, 'Must be exactly 10 digits').required("Field is Required").matches(/^(((0|((\+)?91(\-)?))|((\((\+)?91\)(\-)?)))?[6-9]\d{9})?$/, "Invalid Number"),

    });

    const initialValues = {
        email: '',
        subject: "",
        message: "",
        contact_nu: ''
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: FormSchema,

        onSubmit: (values, action) => {
            setSpiner(true)
            dispatch(AddContactEnquiryTodo(values)).then(() => add_res(action))
        }
    })

    const add_res = (action) => {
        if (AddContactEnquiryRes?.success) {

            Swal.fire(
                "Contact Enquiry",
                'Thank you for your information we will contact you shortly',
                'success'
            )
            setSpiner(false)
            action.resetForm()
        } else {
            toast.error(AddContactEnquiryRes?.message, { position: "bottom-right" });
        }
    }

    const add_res1 = () => {
        if (AddEnquiryRes?.success) {
            Swal.fire(
                "Vendor Enquiry",
                'Thank you for your information we will contact you shortly',
                'success'
            )

            setSpiner(false)
        } else {
            toast.error(AddEnquiryRes?.message, { position: "bottom-right" });
        }
    }


    useEffect(() => {
        new WOW.WOW().init();

        window.addEventListener('scroll', onScroll, { passive: true });

        // const track=document.querySelector('.navbar-nav a')//To access the div with class slide track
        // console.log(track);


        // document.querySelector("a").addEventListener('click', function (event) {
        //     console.log("AA", this.hash);
        //     alert("JJJ")
        //     if (this.hash !== "") {
        //         event.preventDefault();

        //         console.log(document.querySelector(this).parents)

        //         document.querySelector('html, body').animate({
        //             scrollTop: document.querySelector(this.hash).offset().top - 45
        //         }, 1500, 'easeInOutExpo');

        //         if (document.querySelector(this).parents('.navbar-nav').length) {
        //             document.querySelector('.navbar-nav .active').classList.remove('active');
        //             document.querySelector(this).closest('a').classList.add('active');
        //         }
        //     }
        // });
    }, [])

    return (
        <>
            <div class="main-page-wrapper">

                <div class="theme-main-menu theme-menu-one main-orange-color sticky-menu">
                    <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0" id="nv">
                        <a href="" className="navbar-brand p-0">
                            <img src="img/renders_logo_edit.png" alt="Logo" />
                        </a>
                        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                          
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <div className="navbar-nav mx-auto py-0">
                                <a href="#home" className="nav-item nav-link active">Home.</a>
                                <a className="nav-item nav-link " href="#randors">Randors.</a>
                                <a href="#how" className="nav-item nav-link">How It Works.</a>
                                <a className="nav-item nav-link " href="#vendor" >Vendor.</a>
                                <a href="#join" className="nav-item nav-link">Join Community.</a>
                                <a href="#contact" className="nav-item nav-link">Contact Us.</a>
                            </div>
                            <a href="/admin-login" target='#' className="theme-btn btn-sm solid-button-one button-orange">Login</a>
                        </div> */}
                        <button
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                            className="navbar-toggler"
                        >
                            <span className="fa fa-bars"></span>
                        </button>
                        <Collapse in={open}>
                            <div id="example-collapse-text" className='navbar-collapse'>
                                <div className="navbar-nav mx-auto py-0">
                                    <a href="#home" className="nav-item nav-link active">Home.</a>
                                    <a className="nav-item nav-link " href="#randors">Randers.</a>
                                    <a href="#how" className="nav-item nav-link">How It Works.</a>
                                    <a className="nav-item nav-link " href="#vendor" >Vendor.</a>
                                    <a href="#join" className="nav-item nav-link">Join Community.</a>
                                    <a href="#contact" className="nav-item nav-link">Contact Us.</a>
                                    {/* <Link to='/aboutus'>  <a href="" className="nav-item nav-link">About Us.</a></Link> */}
                                </div>
                                <a href="/admin-login" target='#' className="theme-btn btn-sm solid-button-one button-orange">Login</a>
                            </div>
                        </Collapse>
                    </nav>


                </div>

                <div class="rogan-hero-section rogan-hero-four pt-150 pb-250 md-pt-200 md-pb-130 pos-r">
                    <div class="shape-wrapper">
                        <img src="images/shape/50.svg" alt="" class="img-shape bg-shape" />
                        <svg class="img-shape shape-two">
                            <path fill-rule="evenodd" fill="rgb(255, 223, 204)" d="M6.000,12.000 C9.314,12.000 12.000,9.313 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.313 2.686,12.000 6.000,12.000 Z"></path>
                        </svg>
                        <svg class="img-shape shape-three">
                            <path fill-rule="evenodd" fill="rgb(198, 255, 224)"
                                d="M12.500,24.999 C19.403,24.999 25.000,19.403 25.000,12.500 C25.000,5.596 19.403,-0.001 12.500,-0.001 C5.596,-0.001 -0.000,5.596 -0.000,12.500 C-0.000,19.403 5.596,24.999 12.500,24.999 Z" />
                        </svg>
                        <svg class="img-shape shape-four">
                            <path fill-rule="evenodd" opacity="0.451" fill="rgb(255, 126, 190)" d="M10.000,20.000 C15.523,20.000 20.000,15.522 20.000,10.000 C20.000,4.477 15.523,-0.000 10.000,-0.000 C4.477,-0.000 -0.000,4.477 -0.000,10.000 C-0.000,15.522 4.477,20.000 10.000,20.000 Z"></path>
                        </svg>
                        <svg class="img-shape shape-five">
                            <path fill-rule="evenodd" fill="rgb(181, 198, 255)" d="M6.000,12.000 C9.314,12.000 12.000,9.313 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.313 2.686,12.000 6.000,12.000 Z"></path>
                        </svg>
                        <svg class="img-shape shape-six">
                            <path fill-rule="evenodd" fill="rgb(171, 247, 242)" d="M12.500,25.000 C19.404,25.000 25.000,19.403 25.000,12.500 C25.000,5.596 19.404,-0.000 12.500,-0.000 C5.596,-0.000 -0.000,5.596 -0.000,12.500 C-0.000,19.403 5.596,25.000 12.500,25.000 Z"></path>
                        </svg>
                    </div>
                    <div class="container">
                        <div class="main-wrapper pos-r">
                            <h1 class="banner-main-title underline pt-15 pb-45 md-pt-10 md-pb-30 wow fadeInUp animated" data-wow-delay="0.4s">Randers  <br />Your Vehicle, <br /> Your Journey.</h1>
                            <p class="banner-sub-title pb-45 md-pb-30 wow fadeInUp animated" data-wow-delay="0.9s">Discover the Freedom of  <br /> the Open Road.</p>
                            <a class="theme-btn  theme-btn-new solid-button-one button-orange wow fadeInLeft animated" data-wow-delay="1.5s">
                                <div className='d-flex align-items-center'>
                                    <BsGooglePlay size={25} />
                                    <p className='ms-2 buttonPlayStore' style={{ marginBottom: '0px' }} >
                                        <span style={{ fontSize: "14px" }}>Get it on</span><br /> Google Play
                                    </p>
                                </div>
                            </a>
                            <a style={{ paddingTop: '50px' }} class="theme-btn  theme-btn-app theme-btn-new solid-button-one1 button-orange1 wow fadeInLeft animated" data-wow-delay="1.5s">
                                <div className='d-flex align-items-center'>
                                    <FaApple size={25} />
                                    <p className='ms-2 buttonPlayStore'>
                                        <span style={{ fontSize: "14px" }}>Available on the</span><br />App Store
                                    </p>
                                </div>

                            </a>
                        </div>
                    </div>

                    <div class="shape-wrapper screen-wrapper">
                        <div class="screen-one img-shape wow fadeInRight animated" data-wow-delay="0.4s" data-wow-duration="2s">
                            <img src="images/home/screen3.png" alt="" />
                            <img src="images/home/shoot4.png" alt="" class="screen img-shape" />
                        </div>
                        <div class="screen-two img-shape wow fadeInLeft animated" data-wow-delay="0.4s" data-wow-duration="2s">
                            <img src="images/home/screen3.png" alt="" />
                            <img src="images/home/shoot1.png" alt="" class="screen img-shape" />
                        </div>
                        {/* <div class="screen-three img-shape wow fadeInUp animated" data-wow-delay="0.6s" data-wow-duration="2s">
                            <img src="images/home/screen4.png" alt="" />
                        </div> */}
                        <div class="screen-four img-shape wow zoomIn animated" data-wow-delay="0.89s" data-wow-duration="2s">
                            <img src="images/home/icon.svg" alt="" />
                            <img src="img/rander-icon-small.png" alt="" class="screen img-shape" />
                        </div>
                    </div>
                </div>

                <div class=" pos-r pt-50 " id="how">
                        <div class="shape-wrapper">
                            <span class="big-round-one wow fadeInLeft animated" data-wow-duration="3s"></span>
                            <span class="big-round-two wow fadeInLeft animated" data-wow-duration="3s"></span>
                            <span class="big-round-three wow fadeInLeft animated" data-wow-duration="3s"></span>
                        </div>
                        <div class="container">
                            <div class="row align-items-center">
                                <div class=" col-md-6">
                                    <img src="images/home/rb_669.png" alt="" />
                                </div>
                                <div class=" col-md-6 text-center theme-title-one">
                                <h2 class="main-title test-start"><i>About Randers:</i> Your Vehicle, Your Journey </h2>
                                    <p className='text-start'>Randers is your ultimate companion for vehicle rentals, designed to provide seamless access to a wide range of vehicles tailored to your needs. Whether you're embarking on a road trip, need a reliable ride for a business meeting, or simply want the convenience of having a car at your disposal, Randers offers a comprehensive, easy-to-use solution.</p>

                                     <p className='text-start'>   At Randers, we believe in the freedom of choice, allowing you to explore the open road at your own pace, with a vehicle that fits your lifestyle. Our app is built with a user-centric approach, ensuring a smooth and flexible rental experience, whether you're looking for a short-term rental or an extended trip.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                <div class="our-service-one pos-r pt-75 mb-250 md-mb-150 md-pt-140" id='randors'>
                    <div class="shape-wrapper">
                        <img src="images/shape/26.svg" alt="" class="shape-one img-shape" />
                        <img src="images/shape/28.svg" alt="" class="shape-two img-shape" />
                        <img src="images/shape/13.svg" alt="" class="shape-three img-shape" />
                        <img src="images/shape/29.svg" alt="" class="shape-four img-shape" />
                        <img src="images/shape/9.svg" alt="" class="shape-five img-shape" />
                        <img src="images/shape/30.svg" alt="" class="shape-six img-shape" />
                        <img src="images/shape/27.svg" alt="" class="shape-seven img-shape" />
                        <img src="images/shape/31.svg" alt="" class="shape-eight img-shape" />
                        <img src="images/shape/32.svg" alt="" class="shape-nine img-shape" />
                    </div>

                    <div class="container">
                        <div class="theme-title-one text-center pt-200 pb-50 md-pb-30">
                            <h2 class="main-title"><i>Why Choose</i> Randers? </h2>
                            <div class="mark-text pt-25 fs-3" style={{ lineHeight: '35px' }}>Welcome to Randers, the premier app for vehicle rentals that cater to your every need. Whether you're planning a weekend getaway, a business trip, or just need a reliable ride, Randers is your go-to solution.</div>
                        </div>

                        <div class="inner-wrapper pos-r">

                            {/* <div class="theme-title-one">
                            <h2 class="main-title underline"><i>Why Choose</i> Randers? </h2>
                        </div> */}
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="feature-block-one mt-300 md-mt-50">
                                        <img src="images/home/select.png" alt="" class="img-icon" />
                                        <h5 class="pt-30 pb-25 tran3s title">Wide Selection</h5>
                                        <p class="tran3s">From compact cars to SUVs and luxury vehicles, we have the perfect ride for any occasion.</p>
                                        <a href="#" class="read-more tran3s"><i class="flaticon-next-1"></i></a>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="feature-block-one mb-45 mt-85 md-mt-40">
                                        <img src="images/home/booking.png" alt="" class="img-icon" />
                                        <h5 class="pt-30 pb-25 tran3s title">Easy Booking</h5>
                                        <p class="tran3s">Our intuitive app lets you browse, compare, and book vehicles with just a few taps.</p>
                                        <a href="#" class="read-more tran3s"><i class="flaticon-next-1"></i></a>
                                    </div>
                                    <div class="feature-block-one">
                                        <img src="images/home/flexibility.png" alt="" class="img-icon" />
                                        <h5 class="pt-30 pb-25 tran3s title">Flexible Options</h5>
                                        <p class="tran3s">Choose from hourly, daily, or weekly rentals to fit your schedule.</p>
                                        <a href="#" class="read-more tran3s"><i class="flaticon-next-1"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="feature-block-one mb-45 md-mt-40">
                                        <img src="images/home/affordable.png" alt="" class="img-icon" />
                                        <h5 class="pt-30 pb-25 tran3s title">Affordable Rates</h5>
                                        <p class="tran3s">Enjoy competitive pricing with no hidden fees, ensuring you get the best value.</p>
                                        <a href="#" class="read-more tran3s"><i class="flaticon-next-1"></i></a>
                                    </div>
                                    <div class="feature-block-one">
                                        <img src="images/home/24-hours.png" alt="" class="img-icon" />
                                        <h5 class="pt-30 pb-25 tran3s title">24/7 Support</h5>
                                        <p class="tran3s">Our dedicated customer service team is always here to help.</p>
                                        <a href="#" class="read-more tran3s"><i class="flaticon-next-1"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div class="why-choose-us pos-r pt-50 " id="how">
                    <div class="shape-wrapper">
                        <span class="big-round-one wow fadeInLeft animated" data-wow-duration="3s"></span>
                        <span class="big-round-two wow fadeInLeft animated" data-wow-duration="3s"></span>
                        <span class="big-round-three wow fadeInLeft animated" data-wow-duration="3s"></span>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class=" col-md-6">
                                <img src="images/home/how_it_works.png" alt="" />
                            </div>
                            <div class=" col-md-6 text-wrapper">
                                <p class="bottom-title">How It Works?</p>
                                <ul>
                                    <li>Download the App on Android or iOS</li>
                                    <li>Enter your location and dates, browse our fleet, and choose your vehicle</li>
                                    <li>Complete your booking in minutes and start your journey!</li>
                                </ul>
                                {/* <div class="director-speech clearfix">
                                    <img src="images/home/7.jpg" alt="" class="d-img" />
                                    <div class="bio-block">
                                        <h6 class="name">Zubayer hasan</h6>
                                        <span>Managing Director inc.</span>
                                    </div>
                                    <img src="images/home/sign.png" alt="" class="sign" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>





                <div class="our-core-feature style-three pt-75 pb-30 md-pt-80 pos-r" id='vendor'>
                    <div class="shape-wrapper">
                        <img src="images/shape/dot-shape.svg" alt="" class="shape-one img-shape" />
                        <svg class="img-shape shape-two">
                            <path fill-rule="evenodd" fill="rgb(255, 234, 208)" d="M10.000,20.000 C15.523,20.000 20.000,15.523 20.000,10.000 C20.000,4.477 15.523,-0.001 10.000,-0.001 C4.477,-0.001 -0.000,4.477 -0.000,10.000 C-0.000,15.523 4.477,20.000 10.000,20.000 Z"></path>
                        </svg>
                        <svg class="img-shape shape-three">
                            <path fill-rule="evenodd" fill="rgb(164, 206, 255)" d="M4.000,8.000 C6.209,8.000 8.000,6.208 8.000,3.999 C8.000,1.790 6.209,-0.001 4.000,-0.001 C1.791,-0.001 -0.000,1.790 -0.000,3.999 C-0.000,6.208 1.791,8.000 4.000,8.000 Z"></path>
                        </svg>
                        <svg class="img-shape shape-four">
                            <path fill-rule="evenodd" fill="rgb(251, 245, 162)"
                                d="M5.000,10.000 C7.761,10.000 10.000,7.761 10.000,5.000 C10.000,2.238 7.761,-0.000 5.000,-0.000 C2.239,-0.000 -0.000,2.238 -0.000,5.000 C-0.000,7.761 2.239,10.000 5.000,10.000 Z" />
                        </svg>
                        <svg class="img-shape shape-five">
                            <path fill-rule="evenodd" fill="rgb(255, 141, 189)"
                                d="M10.000,20.000 C15.523,20.000 20.000,15.523 20.000,10.000 C20.000,4.477 15.523,-0.000 10.000,-0.000 C4.477,-0.000 -0.000,4.477 -0.000,10.000 C-0.000,15.523 4.477,20.000 10.000,20.000 Z" />
                        </svg>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-5 order-lg-last mt-225 md-m0">
                                <div class="theme-title-one">
                                    <div class="upper-title color-orange mb-5">For Vendors</div>
                                    <h2 class="main-title"> <i>Become a </i>Randers  <i>Partner </i></h2>
                                </div>
                                <p class="pt-40 pb-50">Are you a vehicle owner or business looking to expand your reach? Join Randers as a Rendors Partner and connect with thousands of customers.</p>
                                <a href="#contact" class="theme-btn line-button-one button-orange">More Details <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                            </div>
                            <div class="col-lg-7 order-lg-first">
                                <div class="row feature-block-wrapper">
                                    <div class="col-md-6">
                                        <div class="feature-block-one mt-200 sm-mt-80 js-tilt">
                                            <img src="images/home/registration.png" alt="" />
                                            <h5 class="pt-30 pb-25 tran3s title">Easy Registration</h5>
                                            <p class="tran3s">Sign up and list your vehicles quickly.</p>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="feature-block-one mb-40 mt-40 js-tilt">
                                            <img src="images/home/task-board.png" alt="" />
                                            <h5 class="pt-30 pb-25 tran3s title">Manage Effortlessly</h5>
                                            <p class="tran3s">Use our comprehensive dashboard to handle your fleet and bookings.</p>
                                        </div>

                                        <div class="feature-block-one js-tilt">
                                            <img src="images/home/growth.png" alt="" />
                                            <h5 class="pt-30 pb-25 tran3s title">Grow Your Business.</h5>
                                            <p class="tran3s">Access dedicated support to help you every step of the way.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <div class="about-us-block-two pt-250  md-pt-150 pos-r" id='join'>
                    <div class="inner-wrapper pos-r">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6 order-lg-last">
                                    <div class="theme-title-one">
                                        <div class="upper-title mb-5 color-orange"> Ready to RIDE ?</div>
                                        <h2 class="main-title">Join Our Community</h2>
                                    </div>
                                    <div class="mark-text pt-25 pb-35">Experience the freedom of renting with Randers. Sign up today and enjoy exclusive discounts and promotions!</div>
                                    <p class="pb-60">Download the Randers app now and unlock the road ahead. Adventure awaits!</p>
                                    {/* <a href="about-agency.html" class="theme-btn line-button-one button-orange">Explore More <i class="fa fa-angle-right" aria-hidden="true"></i></a> */}
                                </div>
                                <div class="col-xl-5  col-lg-6 ml-auto">
                                    <img src="images/home/join_community.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <div class="contact-home-one color-orange pt-110 pb-60 pos-r md-pt-30" id='contact'>
                    <div class="shape-wrapper">
                        <img src="images/shape/52.svg" alt="" class="img-shape shape-six" />
                    </div>
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-xl-5 col-lg-6 contactUsMobile order-lg-last">
                                <div class="contact-text">
                                    <div class="theme-title-one">
                                        <div class="upper-title mb-5 color-orange">Contact us</div>
                                        <h2 class="main-title">Don’t Hesitate to Contact us for any <br /><i>Information.</i></h2>
                                    </div>
                                    <p>Call us for immidiate support to this number</p>
                                    <a href="#" class="call-to-dial">1234567890</a>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 mr-auto order-lg-first">
                                <div class="theme-form-style-two">
                                    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                        <div className="row">
                                            <div className="col-md-12 mb-5" >
                                                <input
                                                    type="email"
                                                    placeholder="Your Email*"
                                                    name='email'
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.email && touched.email ? (<span className='text-danger form_label' >{errors.email}</span>) : null}

                                            </div>
                                            <div className="col-md-12 mt-5 pt-5 mb-5" >
                                                <input
                                                    type="text"
                                                    placeholder="Subject*"
                                                    name='subject'
                                                    value={values.subject}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.subject && touched.subject ? (<span className='text-danger form_label' >{errors.subject}</span>) : null}

                                            </div>
                                            <div className="col-md-12 mt-5 pt-5 mb-5" >
                                                <textarea
                                                    placeholder="Message"
                                                    name='message'
                                                    value={values.message}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.message && touched.message ? (<span className='text-danger form_label' >{errors.message}</span>) : null}

                                            </div>
                                            <div className="col-md-12 mt-5 pt-5 text-center">
                                                <button type='submit' class="theme-btn button-rose solid-button-one ">{spiner ? <p style={{ marginBottom: '0px' }}> Loading.... <span style={{ color: 'white' }} className=" ms-2 spinner-border spinner-border-sm" aria-hidden="true"></span> </p> : 'Send Request'}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="newsletter-section newsletter-two pos-r mt-100 md-mt-110">
                    <div class="shape-wrapper">
                        <svg class="img-shape shape-one">
                            <path fill-rule="evenodd" fill="rgb(255, 205, 111)"
                                d="M8.000,15.999 C12.418,15.999 16.000,12.417 16.000,7.999 C16.000,3.581 12.418,-0.001 8.000,-0.001 C3.582,-0.001 -0.000,3.581 -0.000,7.999 C-0.000,12.417 3.582,15.999 8.000,15.999 Z" />
                        </svg>
                        <svg class="img-shape shape-two">
                            <path fill-rule="evenodd" fill="rgb(206, 124, 255)"
                                d="M5.500,10.999 C8.537,10.999 11.000,8.537 11.000,5.499 C11.000,2.462 8.537,-0.001 5.500,-0.001 C2.462,-0.001 -0.000,2.462 -0.000,5.499 C-0.000,8.537 2.462,10.999 5.500,10.999 Z" />
                        </svg>
                    </div>
                    <div class="container">
                        <div class="main-wrapper">
                            <div class="theme-title-one text-center pb-55">
                                <h2 class="main-title">Interested Vendors can join us<br /> <i>Increase your sales</i></h2>
                            </div>
                            <Formik
                                initialValues={{ ...initialValues }}
                                validationSchema={ContactSchema}
                                onSubmit={(values, action) => {
                                    setSpiner(true)
                                    dispatch(AddEnquiryTodo(values)).then(() => add_res1())
                                    action.resetForm()
                                }}
                            >
                                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                                    <form class="ajax-sub-form" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                        <input type="text" placeholder="Enter your phone"
                                            name='contact_nu'
                                            value={values.contact_nu}
                                            onChange={handleChange}
                                            onBlur={handleBlur}

                                        />

                                        <button type='submit' class="button-orange" >{spiner ? <p style={{ marginBottom: '0px' }}> <span className=" ms-2 spinner-border spinner-border-sm" aria-hidden="true"></span> </p> : <IoSendSharp />}</button>
                                        <label class="subscription-label" ></label>
                                        {errors.contact_nu && touched.contact_nu ? (<span className='text-danger form_label ms-5' >{errors.contact_nu}</span>) : null}
                                    </form>)}
                            </Formik>
                        </div>
                    </div>
                </div>

                <footer class="theme-footer-one color-orange pt-130">
                    <div class="container">
                        <div class="bottom-footer-content d-flex justify-content-between">
                            <p>&copy; 2024 copyright all right reserved</p>
                            <div>
                                <div className="footer-menu">
                                    <a href="/about-us" target='#'><span>About us</span></a>
                                    <a href="/help-and-support" target='#'>  <span>Help & Support</span></a>
                                    <a href="/privacy-policy" target='#'>  <span>Privacy policy</span></a>
                                    <a href="/terms-and-conditions" target='#'><span>Terms & conditions</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <button class="scroll-top tran3s ">
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                </button>
            </div>
            <ToastContainer />
        </>

    )
}
