import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AddContactEnquiryRes;
export const AddContactEnquiryTodo = createAsyncThunk('AddContactEnquiry', async (data) => {
    try {
        const res = await axios({
            method: "Post",
            url: process.env.REACT_APP_API_URL + 'admin_add_vendor_enquiry',
            data: {
                // "email": data.email,
                "email": data.email,
                "subject": data.subject,
                "message": data.message,
            },
            headers: { "Content-Type": "application/json" }
        })
        return AddContactEnquiryRes = res.data
        
    } catch (error) {
        return AddContactEnquiryRes = error.response.data
    }
})
