import React, { useState } from 'react'
import { AddBannerRes, AddBannerTodo } from '../../../redux/slices/banner/AddBanner'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { Form } from 'react-bootstrap'
import Loader from '../../loader/Loader'
import Text from '../../input_filed/Text'
import SaveButton from '../../buttons/SaveButton'
import SingleSelect from '../../input_filed/SingleSelect'
import File from '../../input_filed/File'
import { BannerSchema } from './Validation'

export default function AddBannerForm(props) {
    const { bool, setBool } = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const initialValues = {
        name: '',
        banner: "",
        link: "",
        type: "",
    }

    const type_option = [
        { "value": 'vendor ', "label": "Vendor" },
        { "value": 'customer', "label": "Customer" }
    ]

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: BannerSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(AddBannerTodo(bodyFormData)).then(() => add_res(action))
        }
    })

    let bodyFormData = new FormData();
    bodyFormData.append("user_id", localStorage.getItem('user_id'))
    bodyFormData.append("banner_name", values.name)
    bodyFormData.append("banner_image", values.banner)
    bodyFormData.append("banner_type", values.type)
    bodyFormData.append("banner_link", values.link)

    const add_res = (action) => {
        if (AddBannerRes?.success) {
            toast.success(AddBannerRes?.message, { position: "bottom-right" });
            setBool(!bool)
            setLoading(false)
            action.resetForm()
        } else {
            toast.error(AddBannerRes?.message, { position: "bottom-right" });
            setLoading(false)
        }
    }
    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4 '>
                                <Text
                                    label_name='Name'
                                    placeholder=''
                                    name='name'
                                    value={values.name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name ? (<span className='text-danger form_label' >{errors.name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4  mb-2 '>
                                <File
                                    name='banner'
                                    label_name={'Banner Image (Only jpg , png , jpeg)'}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue("banner", e.currentTarget.files[0])}
                                    error={errors.banner && touched.banner ? (<span className='text-danger form_label' >{errors.banner}</span>) : null}
                                />
                                {/* <p></p> */}

                            </div>
                            <div className='col-md-4 mb-2'>
                                <Text
                                    label_name='Link'
                                    placeholder=''
                                    name='link'
                                    value={values.link || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.link && touched.link ? (<span className='text-danger form_label' >{errors.link}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4  mb-2'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Type'
                                    disabled={false}
                                    option={type_option ? type_option : []}
                                    name='type'
                                    defaultValue={''}
                                    onChange={(e) =>
                                        setFieldValue('type', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.type && touched.type ? (<span className='text-danger form_label' >{errors.type}</span>) : null}
                                />
                            </div>

                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Save'} />
                            </div>
                        </div>}
                </div>
            </Form>
        </>
    )
}
