import React, { useState } from 'react'
import AddTypeForm from '../../../component/form/vehicle-managment/type/AddTypeForm'
import TypeList from './TypeList'

export default function AddType() {
    const [bool, setBool] = useState(false)
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Add Vehicle Type</h5>
                            <AddTypeForm bool={bool} setBool={setBool}/>
                        </div>
                        <div className='layout_head mt-3'>
                            <h5> Vehicle Type List</h5>
                            <TypeList bool={bool} setBool={setBool}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
