import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var VendorReqDetailsRes;
export const VendorReqDetailsTodo = createAsyncThunk('VendorReqDetails', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Get",
            url: process.env.REACT_APP_API_URL + `user_help_request/${data}`,
            params:{
                'request_type':'vendor'
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return VendorReqDetailsRes = res.data

    } catch (error) {
        return VendorReqDetailsRes = error.response.data
    }
})

const VendorReqDetailsSlice = createSlice({
    name: 'VendorReqDetails',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(VendorReqDetailsTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(VendorReqDetailsTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default VendorReqDetailsSlice.reducer; 