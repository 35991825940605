import * as Yup from "yup";

export const VehicleSchema = Yup.object({

    category: Yup.string().required("Select Vehicle Category"),
    type: Yup.string().required("Select Vehicle Type"),
    cc: Yup.string().required("Select Vehicle CC"),
    brand: Yup.string().required("Select Brand"),
    model: Yup.string().required("Select Model Name"),
    vehicle_name: Yup.string().required("Enter Vehicle Name"),
    vehicle_img: Yup.mixed().required("Required Vehicle Image"),
    // vehicle_icon: Yup.mixed().required("Required Vehicle Icon"),
    vehicle_description:Yup.string().required("Enter Description"),
    status: Yup.string().required("Select Status"),
});

export const UpdatVehicleSchema = Yup.object({

    category: Yup.string().required("Select Vehicle Category"),
    type: Yup.string().required("Select Vehicle Type"),
    cc: Yup.string().required("Select Vehicle CC"),
    brand: Yup.string().required("Select Brand"),
    model: Yup.string().required("Select Model Name"),
    vehicle_name: Yup.string().required("Enter Vehicle Name"),
    // vehicle_img: Yup.mixed().required("Required Vehicle Image"),
    vehicle_description:Yup.string().required("Enter Description"),
    // vehicle_icon: Yup.mixed().required("Required Vehicle Icon"),
    status: Yup.string().required("Select Status"),
});