import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BannerSchema, UpdateBannerSchema } from './Validation'
import { useFormik } from 'formik'
import { UpdateBannerRes, UpdateBannerTodo } from '../../../redux/slices/banner/UpdateBanner'
import { Form } from 'react-bootstrap'
import Loader from '../../loader/Loader'
import Text from '../../input_filed/Text'
import File from '../../input_filed/File'
import SingleSelect from '../../input_filed/SingleSelect'
import SaveButton from '../../buttons/SaveButton'
import { ToastContainer, toast } from 'react-toastify'
import { BannerDetailsRes, BannerDetailsTodo } from '../../../redux/slices/banner/BannerDetails'
import { useLocation, useNavigate } from 'react-router-dom'

export default function UpdateBannerForm() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { state } = useLocation()
    const BannerData = useSelector(state => state?.BannerDetails?.data?.data?.[0])

    const initialValues = {
        id: BannerData?.id,
        name: BannerData?.banner_name,
        banner: BannerData?.banner_image,
        link: BannerData?.banner_link,
        type: BannerData?.banner_type,
    }

    const type_option = [
        { "value": 'vendor', "label": "Vendor" },
        { "value": 'customer', "label": "Customer" }
    ]

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: UpdateBannerSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(UpdateBannerTodo(bodyFormData)).then(() => add_res(action))
        }
    })

    let bodyFormData = new FormData();
    bodyFormData.append("user_id", localStorage.getItem('user_id'))
    bodyFormData.append("banner_id", values.id)
    bodyFormData.append("banner_name", values.name)
    bodyFormData.append("banner_image", values.banner)
    bodyFormData.append("banner_type", values.type)
    bodyFormData.append("banner_link", values.link)

    const add_res = (action) => {
        if (UpdateBannerRes?.success) {
            toast.success(UpdateBannerRes?.message, { position: "bottom-right" });
            setLoading(false)
            setTimeout(() => {
                navigate('/banner')
            }, 1500);
        } else {
            toast.error(UpdateBannerRes?.message, { position: "bottom-right" });
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(BannerDetailsTodo(state)).then(() => details_res())
    }, [])
    const details_res = () => {
        if (BannerDetailsRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    return (
        <>
            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className='form_layout mt-3'>
                    {loading ?
                        <div className='d-flex justify-content-center p-5'>
                            <Loader />
                        </div> :
                        <div className='row'>
                            <div className='col-md-4 '>
                                <Text
                                    label_name='Name'
                                    placeholder=''
                                    name='name'
                                    value={values.name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name ? (<span className='text-danger form_label' >{errors.name}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4  mb-2 '>
                                <File
                                    name='banner'
                                    label_name={'Banner Image (Only jpg , png , jpeg)'}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue("banner", e.currentTarget.files)}
                                    // error={errors.banner && touched.banner ? (<span className='text-danger form_label' >{errors.banner}</span>) : null}
                                />

                            </div>
                            <div className='col-md-4 mb-2'>
                                <Text
                                    label_name='Link'
                                    placeholder=''
                                    name='link'
                                    value={values.link || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.link && touched.link ? (<span className='text-danger form_label' >{errors.link}</span>) : null}
                                />
                            </div>
                            <div className='col-md-4  mb-2'>
                                <SingleSelect
                                    closeMenu={true}
                                    label_name='Type'
                                    disabled={false}
                                    option={type_option ? type_option : []}
                                    name='type'
                                    defaultValue={type_option && type_option.find((option) => option.value === values.type)}
                                    onChange={(e) =>
                                        setFieldValue('type', e.value)
                                    }
                                    onBlur={handleBlur}
                                    error={errors.type && touched.type ? (<span className='text-danger form_label' >{errors.type}</span>) : null}
                                />
                            </div>

                            <div className='col-md-12 text-end mt-2'>
                                <SaveButton name={'Update'} />
                            </div>
                        </div>}
                </div>
            </Form>
            <ToastContainer />
        </>
    )
}
