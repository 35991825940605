import React from 'react'
import AddVendorForm from '../../../component/form/user-managment/vendors/AddVendorForm'

export default function AddVendor() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Add Vendor </h5>
                            <AddVendorForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
