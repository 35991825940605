import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var UpdateCategoryRes;
export const UpdateCategoryTodo = createAsyncThunk('UpdateCategory', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "Post",
            url: process.env.REACT_APP_API_URL + 'vehicle_category',
            data: data,
            headers: { "Content-Type":"multipart/form-data" , "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return UpdateCategoryRes = res.data
        
    } catch (error) {
        return UpdateCategoryRes = error.response.data
    }
})
