import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var CityStatusRes;
export const CityStatusTodo = createAsyncThunk('CityStatus', async (data) => {
    try {
        const res = await axios({
            method: "Put",
            url: process.env.REACT_APP_API_URL + `city/${data.id}`,
            data:{
                "city_name": data.city,
                "status" : data.status
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return CityStatusRes = res.data

    } catch (error) {
        return CityStatusRes = error.response.data
    }
})