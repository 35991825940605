import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { DeleteCityServicRes, DeleteCityServicTodo } from '../../../redux/slices/city-service/DeletCityService'

export default function CityServiceAction(props) {
    const { bool, setBool } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()

 
    const del_action = () => {

        Swal.fire({
            title: "City Delete",
            text: "Are You Sure Want To Delete This City ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteCityServicTodo(props.data.id)).then(() => del_res())
            }
          
        })
    }

    const del_res = () => {
        if (DeleteCityServicRes?.success) {
            setBool(!bool)
            Swal.fire(
                "City",
                'City Deleted Successfully',
                'success'
            )
        } else {
            toast.error(DeleteCityServicRes?.message, { position: "bottom-right" })
        }
    }
    return (
        <>
            <div>
          
     
   
                <LuTrash2 size={20} className='delete text-danger ms-3'
                    onClick={() => del_action()}
                    data-tooltip-id='del'
                    data-tooltip-content="Delete City!"
                    data-tooltip-place="bottom"
                />

                <Tooltip id='del' className='bg-danger tooltip_msg' />
            

            </div>
        </>
    )
}
