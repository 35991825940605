import React, { useEffect, useState } from 'react'
import Loader from '../../../component/loader/Loader'
import DataTable from '../../../component/data-table/DataTable'
import { CMSListRes, CMSListTodo } from '../../../redux/slices/crm/CMSList'
import { useDispatch, useSelector } from 'react-redux'
import TermConditionAction from '../../../component/data-table/data-table-action/crm/TermConditionAction'

export default function TermConditionList(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const TermConditionList = useSelector(state => state?.CMSList?.data?.data)
    console.log(TermConditionList)

    useEffect(() => {
        setLoading(true)
        dispatch(CMSListTodo('2')).then(() => list_res())
    }, [bool])

    const list_res = () => {
        if (CMSListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            // valueGetter: 'node.rowIndex + 1',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'TERM & CONDITION DESCRIPTION',
            field: 'modified_date',
            cellRenderer: params => <span>Term & Condition Updated Date  <b>({params.data.modified_date === null ? '-' : new Date(params.data.modified_date).toLocaleDateString('en-GB')})</b></span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },

        {
            headerName: 'STATUS',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.data.status === 'active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // maxWidth:200
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: TermConditionAction,
            cellRendererParams: {
               
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
            maxWidth:250
        }
    ]
    return (
        <>
            {loading ?
                <diV className='dd-flex justify-content-center mt-5 pt-5'>
                    <Loader />
                </diV>
                :
                TermConditionList && TermConditionList.length ?
                    <DataTable
                        rowData={TermConditionList}
                        columnDefs={columnDefs}
                        height={'60vh'}
                    />
                    :
                    <div className='empty_section mt-2'>
                        <span>DATA NOT FOUND</span>
                    </div>
            }
        </>
    )
}
