import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../component/loader/Loader'
import DataTable from '../../component/data-table/DataTable'
import { ToastContainer } from 'react-toastify'
import CouponListAction from '../../component/data-table/data-table-action/coupon/CouponListAction'
import { CouponListRes, CouponListTodo } from '../../redux/slices/coupon/CouponList'

export default function CoupenList(props) {

    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const CouponList = useSelector(state => state?.CouponList?.data?.data)
    // console.log(CouponList)

    useEffect(() => {
        setLoading(true)
        dispatch(CouponListTodo()).then(() => list_res())
    }, [bool])

    const list_res = () => {
        if (CouponListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            // valueGetter: 'node.rowIndex + 1',
            cellRenderer: params => params.node.rowIndex + 1,
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'NAME',
            field: 'coupon_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'CODE',
            field: 'coupon_code',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            autoHeight: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'TYPE',
            field: 'types_of_discount',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'START DATE',
            field: 'start_date',
            cellRenderer: params => new Date(params?.data?.start_date).toLocaleDateString('en-GB'),
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'END DATE',
            field: 'end_date',
            cellRenderer: params => new Date(params?.data?.end_date).toLocaleDateString('en-GB'),
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'DISCOUNT',
            field: 'discount_value',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'STATUS',
            field: 'banner_status',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params?.data?.coupon_status === 'inactive' || params?.data?.coupon_status === 'expired' ? <span className='badge text-bg-danger'>{params?.data?.coupon_status}</span> : new Date(params.data.start_date).toLocaleDateString('en-GB') >= new Date().toLocaleDateString('en-GB') ? <span className='badge text-bg-info'>Scheduled</span> : new Date(params.data.start_date).toLocaleDateString('en-GB') <= new Date().toLocaleDateString('en-GB') ? <span className='badge text-bg-success'>Active</span> : '',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: 'ACTIONS',
            cellRenderer: CouponListAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                // setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
        }
    ]
    return (
        <>
            {loading ?
                <diV className='dd-flex justify-content-center mt-5 pt-5'>
                    <Loader />
                </diV>
                :
                CouponList && CouponList.length ?
                    <DataTable
                        rowData={CouponList}
                        columnDefs={columnDefs}
                        height={'60vh'}
                    />
                    :
                    <div className='empty_section mt-2'>
                        <span>DATA NOT FOUND</span>
                    </div>
            }

            <ToastContainer />
        </>
    )
}
