import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { BookingDetailsRes, BookingDetailsTodo } from '../../redux/slices/booking/BookingDetails'
import Loader from '../../component/loader/Loader'
import bikeimg from '../../../src/assests/images/back-view-man-with-motorbike-outdoors.jpg'
import moment from 'moment'

export default function BookingDetails() {
    const { state } = useLocation()
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const details = useSelector(state => state?.BookingDetails?.data?.data?.bookingList?.[0])
    console.log(details)
    useEffect(() => {
        setLoading(true)
        dispatch(BookingDetailsTodo(state)).then(() => details_res())
    }, [])

    const details_res = () => {
        if (BookingDetailsRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    return (
        <>
            <div className="container-fluid">

                <div className='layout_head mb-2'>
                    <h5> Booking Details</h5>
                </div>
                {loading ?
                    <div className='d-flex justify-content-center mt-5'>
                        <Loader />
                    </div> :
                    <div className="row">
                        <div className="col-md-3">
                            <div className='details_sidebar '>
                                <PerfectScrollbar >
                                    <div className='ps-3 pe-3 pt-2 pb-2'>
                                        <h5 className='text-center'>Vendor Details</h5>
                                        <hr />
                                        <div className=' align-items-center mt-2'>
                                            <p>Vendor Name </p>
                                            <span> {details?.VendorVehicleSchema?.VendorDetailsSchema?.vendor_name}</span>
                                        </div>
                                        {/* <div className=' align-items-center mt-2'>
                                            <p>Email</p>
                                            <span> {details?.VendorVehicleSchema?.VendorDetailsSchema?.vendor_mobile_num}</span>
                                        </div> */}
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Contact No</p>
                                            <span> {details?.VendorVehicleSchema?.VendorDetailsSchema?.vendor_mobile_num}</span>
                                        </div>
                                        <div className=' mt-2'>
                                            <p>Shop Name </p>
                                            {details?.VendorVehicleSchema?.VendorDetailsSchema?.shop_name === '' || details?.VendorVehicleSchema?.VendorDetailsSchema?.shop_name === null ? '-' : <span>{details?.VendorVehicleSchema?.VendorDetailsSchema?.shop_name}</span>}
                                        </div>
                                        {/* <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>City </p>
                                            <span>{details?.VendorVehicleSchema?.VendorDetailsSchema?.CitySchema?.city_name}</span>
                                        </div> */}
                                        <div className=' mt-2'>
                                            <p>Address </p>
                                            {details?.VendorVehicleSchema?.VendorDetailsSchema?.shop_address === "" || details?.VendorVehicleSchema?.VendorDetailsSchema?.shop_address === null ? '-' : <span>{details?.VendorVehicleSchema?.VendorDetailsSchema?.shop_address}</span>}
                                        </div>
                                        <h5 className='text-center mt-3'>Customer Details</h5>
                                        <hr />
                                        <div className=' align-items-center mt-2'>
                                            <p> Name </p>
                                            <span> {details?.CustomerDetailsSchema?.customer_name}</span>
                                        </div>
                                        <div className=' align-items-center mt-2'>
                                            <p>Email</p>
                                            <span> {details?.CustomerDetailsSchema?.customer_email}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Contact No</p>
                                            <span> {details?.CustomerDetailsSchema?.customer_mobile_num}</span>
                                        </div>

                                        {/* <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>City </p>
                                            <span>{details?.CustomerDetailsSchema?.CitySchema?.city_name}</span>
                                        </div> */}
                                        <div className=' mt-2'>
                                            <p>Address </p>
                                            {<span>{details?.CustomerDetailsSchema?.customer_address}</span>}
                                        </div>

                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="details_section">
                                <PerfectScrollbar >
                                    <div className='p-3'>
                                        <div className="row row-flex">
                                            <div className="col-md-6">
                                                <div className='content booking_details'>
                                                    <h5>Booking Details</h5>
                                                    <div className=' mt-2 d-flex justify-content-between align-items-center'>
                                                        <p>Booking Id - {details?.booking_id}</p>
                                                        <span className={details?.booking_status === 'completed' ? ' pt-1 badge text-bg-success' : details?.booking_status === 'accepted' ? 'pt-1 badge text-bg-info' : details?.booking_status === 'rejected' ? ' pt-1 badge text-bg-danger' : details?.booking_status === 'cancelled' ? ' pt-1 badge text-bg-danger' : details?.booking_status === 'pending' ? ' pt-1 badge text-bg-warning' : details?.booking_status === 'ongoing' ? ' pt-1 badge text-bg-primary' : ''}>{details?.booking_status}</span>
                                                    </div>
                                                    <div className='mt-2 d-flex justify-content-between align-items-center'>
                                                        <div>
                                                            <h5 className='vehicle_name mt-1'>{details?.VendorVehicleSchema?.VehicleMasterSchema?.vehicle_name}</h5>
                                                            <p> ({details?.VendorVehicleSchema?.VehicleMasterSchema?.VehicleTypeSchema?.vehicle_type_name} , {details?.VendorVehicleSchema?.VehicleMasterSchema?.VehicleCCSchema?.vehicle_cc_name})</p>
                                                        </div>
                                                        <div className='bike_img_sec'>
                                                        <img src={process.env.REACT_APP_IMAGE_URL + `${details?.VendorVehicleSchema?.VendorVehiclePhotos?.[0]?.vehicle_photos_url}`} className='img-fluid bike_img' alt="img" />
                                                        </div>
                                                    </div>


                                                    <div className='mt-1 d-flex justify-content-between align-items-center'>
                                                        <p>Pick Up Date</p>
                                                        <span>{new Date(details?.booking_picup_date).toLocaleDateString('en-GB')} , {moment(details?.booking_picup_time, "HH:mm:ss").format('hh:mm A')}</span>
                                                    </div>
                                                    <div className='mt-1 d-flex justify-content-between align-items-center'>
                                                        <p>Return Date</p>
                                                        <span>{new Date(details?.booking_return_date).toLocaleDateString('en-GB')} ,   {moment(details?.booking_return_time, "HH:mm:ss").format('hh:mm A')}</span>
                                                    </div>
                                                    <div className='mt-1 d-flex justify-content-between align-items-center'>
                                                        <p>Latitude</p>
                                                        <span>{details?.latitude}</span>
                                                    </div>
                                                    <div className='mt-1 d-flex justify-content-between align-items-center'>
                                                        <p>Longitude</p>
                                                        <span>{details?.longitude}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className='content booking_details'>
                                                    <h5>Payment Details</h5>
                                                    <div className=' mt-2 d-flex justify-content-between align-items-center'>
                                                        <p>Payment Type</p>
                                                        <span >{details?.payment_type}</span>
                                                    </div>


                                                    <div className='mt-1 d-flex justify-content-between align-items-center'>
                                                        <p>Booking Price</p>
                                                        <span >{details?.booking_price}</span>
                                                    </div>
                                                   
                                                    <div className='mt-1 d-flex justify-content-between align-items-center'>
                                                        <p>GST(%)</p>
                                                        <span >{details?.gst_per}</span>
                                                    </div>
                                                    <div className='mt-1 d-flex justify-content-between align-items-center'>
                                                        <p>GST Amount</p>
                                                        <span >{details?.gst_amount}</span>
                                                    </div>
                                                    <div className='mt-1 d-flex justify-content-between align-items-center'>
                                                        <p>Sub Total</p>
                                                        <span >{details?.subtotal}</span>
                                                    </div>
                                                    <div className='mt-1 d-flex justify-content-between align-items-center'>
                                                        <p>Discount</p>
                                                        <span >{details?.discount_price}</span>
                                                    </div>
                                                    <hr />
                                                    <div className='mt-1 d-flex justify-content-between align-items-center'>
                                                        <h5>Total Price</h5>
                                                        <div className='d-flex align-items-center'>
                                                            <h5 className={details?.payment_confirm === 'completed' ? 'me-2 badge text-bg-success mb-2' : details?.payment_confirm === 'cancelled' ? 'me-2 badge text-bg-danger mb-2' : details?.payment_confirm === 'pending' ? 'me-2 badge text-bg-warning mb-2' : ''} >{details?.payment_confirm}</h5>
                                                            <h5>{details?.total_price}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                    </div>}
            </div>
        </>
    )
}
